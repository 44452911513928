import React, { useEffect, useReducer, useState } from "react";
import PageTitle from "../../components/page_title";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import { Button, Table, Pagination, Form, SelectPicker, Toggle } from "rsuite";
import EditForm from "./form";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import ConfirmModal from "../../components/confirm_modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { replaceEmptyValues, textCapitalize } from "../../utils/helpers";
const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;
import { format_date_without_time_zone } from "../../utils/date_formatter";

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const initialState = {
  page: 1,
  limit: 10,
  status: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setPage":
      return { ...state, page: action.payload };
    case "setLimit":
      return { ...state, limit: action.payload, page: 1 };
    case "setStatus":
      return { ...state, status: action.payload, page: 1 };
    default:
      return state;
  }
};

const newReminder = () => ({
  id: null,
});

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { page, limit } = state;
  const navigate = useNavigate();
  const setPage = (page) => dispatch({ type: "setPage", payload: page });
  const setLimit = (limit) => dispatch({ type: "setLimit", payload: limit });
  const [reminderToEdit, setReminderToEdit] = useState(null);
  const [enableReminder, setEnableReminder] = useState(null);
  const {
    data: reminders,
    isLoading,
    mutate,
  } = useSWR(
    {
      url: "/api/v0/reminders",
      params: payload({ ...state }),
    },
    fetcher,
  );
  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, reminders?.total);

  useEffect(() => {
    if (!reminders?.data) return;
    replaceEmptyValues(reminders?.data);
  }, [reminders?.data]);

  return (
    <div>
      {reminderToEdit ? (
        <EditForm
          open={!!reminderToEdit}
          id={reminderToEdit?.id}
          editable={reminderToEdit?.attributes?.editable}
          onClose={() => {
            if (window.location.href.indexOf("content") > -1) {
              navigate(`/reminders/schedule`);
            } else {
              navigate(`/reminders`);
              setReminderToEdit(null);
            }
          }}
        />
      ) : (
        <>
          <div className="container">
            <div className="mb-3">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <PageTitle title={"Reminders"} />
                </div>
                <div className="col-md-8">
                  <div className="d-flex justify-content-end">
                    <Button
                      onClick={() => setReminderToEdit(newReminder())}
                      color="green"
                      appearance="primary"
                    >
                      <i className="fa fa-plus me-2"></i>Create New Reminder
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="shadow-sm bg-white radius-5">
              <div className="top bb px-3 pt-3 pb-2">
                <Filters {...{ state, dispatch }} />
              </div>
              <div className="inner">
                <Table
                  loading={isLoading}
                  height={500}
                  width={"100wv"}
                  data={reminders?.data || []}
                  className="text-capitalize"
                >
                  <Column width={100} flexGrow={3}>
                    <HeaderCell>Title</HeaderCell>
                    <Cell>
                      {(rowData) => (
                        <span
                          title={textCapitalize(rowData?.attributes?.title)}
                        >
                          {rowData?.attributes?.title}
                        </span>
                      )}
                    </Cell>
                  </Column>
                  <Column width={100} flexGrow={3}>
                    <HeaderCell>Message</HeaderCell>
                    <Cell>
                      {(rowData) => (
                        <span
                          title={textCapitalize(rowData?.attributes?.message)}
                        >
                          {rowData?.attributes?.message}
                        </span>
                      )}
                    </Cell>
                  </Column>
                  <Column width={100} flexGrow={2}>
                    <HeaderCell>Created At</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        let date = new Date(rowData?.attributes?.created_at);
                        return (
                          <span>
                            {format_date_without_time_zone(date)}
                          </span>
                        );
                      }}
                    </Cell>
                  </Column>
                  <Column width={100} flexGrow={2}>
                    <HeaderCell>Last Published At</HeaderCell>
                    <Cell dataKey="attributes.last_published_at" />
                  </Column>
                  <Column width={100} flexGrow={2}>
                    <HeaderCell>Occurance</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        return rowData?.attributes?.type == "once"
                          ? "One Time"
                          : "Repeated";
                      }}
                    </Cell>
                  </Column>
                  <Column width={100} flexGrow={2}>
                    <HeaderCell>Ending On</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        return rowData?.attributes?.end_date == '--'
                          ? "Never Ends"
                          : rowData?.attributes?.end_date;
                      }}
                    </Cell>
                  </Column>
                  <Column width={100} flexGrow={2}>
                    <HeaderCell>Status</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        return (
                          <div style={{ marginTop: -6 }}>
                            <span>{rowData?.attributes?.status}</span>
                            <br />
                            {rowData?.attributes?.status == "scheduled" && (
                              <span>
                                {rowData?.attributes?.next_scheduled_on}
                              </span>
                            )}
                          </div>
                        );
                      }}
                    </Cell>
                  </Column>
                  <Column
                    width={150}
                    fixed="right"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <HeaderCell>Actions</HeaderCell>
                    <Cell style={{ padding: "6px" }}>
                      {(rowData) => {
                        return (
                          <>
                            <Toggle
                              size="sm"
                              color={
                                rowData?.attributes?.enabled ? "green" : "red"
                              }
                              className="me-2"
                              checked={rowData?.attributes?.enabled}
                              appearance="ghost"
                              onChange={() => setEnableReminder(rowData)}
                            />
                            <i
                              className="fa-solid fa-pencil me-2"
                              title="Edit"
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => {
                                setReminderToEdit(rowData);
                              }}
                              onClick={() => {
                                setReminderToEdit(rowData),
                                  navigate("/reminders/schedule");
                              }}
                            ></i>
                            <i
                              className="fa-solid fa-user me-2"
                              title="Students"
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => {
                                window.location.href = `/api/v0/reminders/${rowData.id}/students`;
                              }}
                              onClick={() => {
                                window.location.href = `/api/v0/reminders/${rowData.id}/students`;
                              }}
                            ></i>
                          </>
                        );
                      }}
                    </Cell>
                  </Column>
                </Table>
              </div>
            </div>
            {enableReminder && (
              <ConfirmModal
                open={!!enableReminder}
                onClose={() => setEnableReminder(null)}
                onConfirm={() => {
                  axios({
                    method: "patch",
                    url: `/api/v0/reminders/${enableReminder.id}`,
                    headers: {
                      "Content-Type": "application/json",
                      "X-CSRF-Token": getAuthenticityToken(),
                    },
                    data: { enabled: !enableReminder?.attributes?.enabled },
                  })
                    .then(() => {
                      setEnableReminder(null);
                      mutate();
                      return;
                    })
                    .catch((err) => {
                      console.log(err.response.data.errors);
                    });
                }}
              />
            )}
            {((reminders?.data || []).length >= 10 || page > 1) && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="grey-txt mt-2 f-14">
                  {startIndex + 1} to{" "}
                  {endIndex && endIndex != 0 ? endIndex : ""} of{" "}
                  {reminders?.total}
                </div>

                <div className="pagination-outer">
                  <Pagination
                    prev
                    next={!isLoading}
                    ellipsis
                    layout={["limit", "|", "pager"]}
                    size="sm"
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={setLimit}
                    limit={limit}
                    limitOptions={[10, 30, 50]}
                    total={reminders?.total}
                    maxButtons={10}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const Filters = ({ state, dispatch }) => {
  return (
    <Form layout="inline" className="mb-0">
      <Form.Group className="mb-2">
        <Form.Control
          name="language"
          accepter={SelectPicker}
          data={[
            {
              value: "scheduled",
              label: "Scheduled",
            },
            {
              value: "ended",
              label: "Ended",
            },
            {
              value: "disabled",
              label: "Disabled",
            },
          ]}
          labelKey="label"
          valueKey="value"
          onChange={(value) => dispatch({ type: "setStatus", payload: value })}
          value={state.status}
          placeholder="Select Status"
          searchable={false}
        />
      </Form.Group>
    </Form>
  );
};

const payload = ({ status, page, limit }) => ({
  status,
  page,
  limit,
});

export default Index;
