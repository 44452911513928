import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import List from "./list";
import Show from "./show";
import Content from "./show/content";
import Summary from "./show/summary";
import Students from "./show/students";
import { isAdmin } from "../../utils/state";

const Index = () => (
  <Routes>
    <Route path="*" element={<Outlet />}>
      <Route path=":id/*" element={<Show />}>
        {isAdmin() && <Route path="students" element={<Students />} />}
        <Route path="content" element={<Content />} />
        <Route path="*" element={<Summary />} />
      </Route>
      <Route path="*" element={<List />} />
    </Route>
  </Routes>
);

export default Index;
