import React from "react";
import styled from "styled-components";
/*
const  data= {
  header :[
    {
      className: ,
      value
    }
  ],
  body :[
    {
      key,
      values:[
        {
          value
        }
      ]
    }
  ],
  errors: {
    body: {
      empty: "No data found"
    }
  }
]
*/

const BodyRow = styled.tr`
  &:hover {
    background-color: rgb(232, 221, 239);
    cursor: pointer;
  }
`;
const Table = ({ data }) => {
  return (
    <div className="overflow-auto w-100">
      <table
        className="theme-table mb-1 mt-3 table"
        style={{ minHeight: "200px" }}
      >
        <thead>
          <tr>
            {data?.header?.length > 0 &&
              data.header.map((item, index) => (
                <HeaderCell
                  {...{
                    key: index,
                    ...(item?.className && { className: item.className }),
                  }}
                >
                  {item.value}
                </HeaderCell>
              ))}
          </tr>
        </thead>
        <tbody>
          {data?.body?.length > 0 &&
            data.body.map((item) => (
              <BodyRow
                key={item.key}
                style={{
                  cursor: item.onClick ? "pointer" : "default",
                }}
                onClick={item.onClick || (() => {})}
              >
                {item.values.map((item, index) => (
                  <Cell
                    {...{
                      key: index,
                      ...(item?.className && { className: item.className }),
                    }}
                  >
                    {item.value}
                  </Cell>
                ))}
              </BodyRow>
            ))}

          {data?.body?.length === 0 && (
            <NoData message={data?.errors?.body?.empty} />
          )}
        </tbody>
      </table>
    </div>
  );
};

const HeaderCell = ({ children, ...props }) => (
  <th {...{ className: "text-center", ...props }}>{children}</th>
);

const Cell = ({ children, ...props }) => (
  <td
    {...{
      className: "text-center",
      ...props,
    }}
  >
    {children}
  </td>
);

const NoData = ({ colSpan = 12, message = "No Data Found" }) => (
  <tr>
    <td colSpan={colSpan}>
      <div className="col-sm-12 text-center">
        <div className="mt-5">{message}</div>
      </div>
    </td>
  </tr>
);

export default Table;
