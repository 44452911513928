import React, { useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import "./styles.css";
import chatacLogo from "images/Logo.png";
import chatacLogosm from "images/fav-icon.png";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import { flipper } from "../../utils/flipper";

import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { isAdmin, isPhoneNumbersRemovingEnabled } from "../../utils/state";

const style = ``;

const StyledNavLink = styled(NavLink)`
  ${style}
`;
const StyledATag = styled.a`
  ${style}
`;
const coursesItem = (
  <StyledNavLink to="courses">
    <i className="fa-solid fa-solid fa-book"></i>
    <span>Courses</span>
  </StyledNavLink>
);

const adminItems = (features) => (
  [
    <StyledNavLink key="home" to="home">
      <i className="fa fa-dashboard"></i>
      <span className="text">Dashboard</span>
    </StyledNavLink>,
    coursesItem,
    <StyledNavLink key="analytics" to="analytics">
      <i className="fa-solid fa-chart-pie"></i>
      <span>Analytics</span>
    </StyledNavLink>,
    <StyledNavLink key="users" to="users">
      <i className="fa-solid fa-user-group"></i>
      <span>Content Creators</span>
    </StyledNavLink>,
    <StyledNavLink key="students" to="students">
      <i className="fa-solid fa-graduation-cap"></i>
      <span>Students</span>
    </StyledNavLink>,
    ...(flipper(features, 'reminders') ?
      [<StyledNavLink key="reminders" to="reminders">
        <i class="fa-solid fa-bell"></i>
        <span>Reminders</span>
      </StyledNavLink>] : []),
    <StyledNavLink key="service_numbers" to="service_numbers">
      <i className="fa-solid fa-headset"></i>
      <span>Service Numbers</span>
    </StyledNavLink>,
    <StyledNavLink key="certificates" to="certificates">
      <i className="fa-solid fa-file-circle-check"></i>
      <span>Certificates</span>
    </StyledNavLink>,
    ...(isPhoneNumbersRemovingEnabled()
      ? [
        <StyledNavLink key="phone_numbers" to="phone_numbers">
          <i className="fa-solid fa-phone"></i>
          <span>Phone Numbers</span>
        </StyledNavLink>,
      ]
      : []),
    <StyledNavLink to="project_media">
      <i class="fa-solid fa-photo-film"></i>
      <span>Project Media</span>
    </StyledNavLink>,
    <StyledNavLink to="demo_videos">
      <i class="fa-solid fa-video"></i>
      <span>Demo Videos</span>
    </StyledNavLink>,
    <StyledATag href="/flipper">
      <i class="fa-solid fa-list-check"></i>
      <span>Features Manager</span>
    </StyledATag>,
  ]
)
const Sidebar = () => {
  const { data: featuresData = { features: [] } } = useSWR(`/flipper/api/features`, fetcher);

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Menu
      bodyClassName={"menu-toggled"}
      pageWrapId={"page-wrap"}
      outerContainerId={"outer-container"}
      width={280}
      isOpen={viewportWidth > 1200 ? true : false}
    >
      <div className="pt-2 position-relative">
        <img src={chatacLogo} alt="SOLAx Logo" className="logo big" />
        <img src={chatacLogosm} alt="SOLAx Logo" className="logo sm" />
      </div>
      <div className="menu-links">{isAdmin() ? adminItems(featuresData?.features) : coursesItem}</div>
    </Menu>
  );
};
export default Sidebar;
