import React from "react";
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const NoDataPieChart = () => {
  const pieData = [
    { name: "1 or more Enrollments", students: 150 },
    { name: "No Enrollment", students: 170 },
  ];
  return (
    <>
      <ResponsiveContainer width="100%" height={358}>
        <PieChart>
          <Pie
            data={pieData}
            isAnimationActive={true}
            label={false}
            nameKey="name"
            dataKey="students"
            labelLine
            cx="50%"
            cy="50%"
            innerRadius={95}
            outerRadius={150}
            fill="#8884d8"
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill="#EDEDED " />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <p className="head-md text-center mt-3">Sorry, No data available!</p>
    </>
  );
};

export default NoDataPieChart;
