import { getAuthenticityToken } from "./authenticity_token";
import axios from "axios";

export const whatsappMediaChecker = (file_url) => {
  return axios({
    method: "GET",
    url: `/api/v0/whatsapp_media/verify_media`,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getAuthenticityToken(),
    },
    params: {
      url: file_url,
    },
  }).then((response) => {
    return response;
  });
};

const uploader = (file) => {
  return axios({
    method: "POST",
    url: `/api/v0/s3_presigned_urls/`,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getAuthenticityToken(),
    },
    data: {
      filename: file.name,
      filetype: file.type,
    },
  })
    .then((response) => {
      const { data } = response;
      const { url } = data;
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });
    })
    .then((response) => {
      return response.url.split("?")[0];
    });
};

export default uploader;
