import React, { useState } from "react";
import { Button, Loader, Panel } from "rsuite";
import uploader from "../../../utils/uploader";

const StepTwo = ({ file, onUploaded }) => {
  const [isUploading, setIsUploading] = useState(false);
  if (isUploading) {
    return (
      <Panel className="d-flex justify-content-center">
        <Loader content="uploading" size="lg" />
      </Panel>
    );
  }
  return (
    <Panel header="Launch Upload" bordered>
      <Button
        appearance="primary"
        onClick={() => {
          setIsUploading(true);
          uploader(file).then((url) => {
            onUploaded(url);
            setIsUploading(false);
          });
        }}
      >
        Upload
      </Button>
    </Panel>
  );
};

export default StepTwo;
