export const COUNTRYNAMES = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire",
  "Bosnia",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Macedonia",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena",
  "Saint Kitts",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre",
  "Saint Vincent",
  "Samoa",
  "San Marino",
  "Sao Tome",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad",
  "Tunisia",
  "Turkmenistan",
  "Turks",
  "Tuvalu",
  "Türkiye",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain",
  "United States Minor Outlying Islands",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands",
  "Wallis",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const AFGSTATENAMES = [
  "Badakhshan",
  "Badghis",
  "Baghlan",
  "Balkh",
  "Bamyan",
  "Daykundi",
  "Farah",
  "Faryab",
  "Ghazni",
  "Ghōr",
  "Helmand",
  "Herat",
  "Jowzjan",
  "Kabul",
  "Kandahar",
  "Kapisa",
  "Khost",
  "Kunar",
  "Kunduz",
  "Laghman",
  "Logar",
  "Maidan Wardak",
  "Nangarhar",
  "Nimruz",
  "Nuristan",
  "Paktia",
  "Paktika",
  "Panjshir",
  "Parwan",
  "Samangan",
  "Sar-e Pol",
  "Takhar",
  "Urozgan",
  "Zabul",
];

export const COMPLETED = "completed";
export const NON_COMPLETED = "non_completed";

export const GROUP_BY_LIST = [
  { value: "month", label: "By Month" },
  { value: "day", label: "By Day" },
];

export const ATTEMPT_NUMBER = [
  { value: "", label: "Attempt Number" },
  { value: 1, label: "1st time" },
  { value: 2, label: "2nd time" },
  { value: 3, label: "3rd time" },
  { value: 4, label: "4th time" },
  { value: 5, label: "5th time" },
  { value: 6, label: "6th time" },
  { value: 7, label: "7th time" },
  { value: 8, label: "8th time" },
  { value: 9, label: "9th time" },
  { value: 10, label: "10th time" },
];

export const ALL_COURSES = [{ value: "", label: "All Courses" }];
export const ALL_SERVICE_NUMBERS = [
  { value: "", label: "All Service Numbers" },
];
export const ALL_GRADES = [{ value: "", label: "All Course Grades" }];
export const ALL_SCHOOL_LEVELS = [{ value: "", label: "All Other Types" }];
export const CERTIFICATE_GENERATED_OPTIONS = [
  { value: "", label: "Certificate Generated" },
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
export const ALL_TEACHERS = [{ value: "", label: "All Teachers" }];

export const completionStatusOptions = [
  { value: "", label: "Completion Status" },
  { value: COMPLETED, label: "Completed" },
  { value: NON_COMPLETED, label: "Not Completed" },
];

export const COUNTRIES = COUNTRYNAMES.map((c) => ({ label: c, value: c }));
export const AFGSTATES = AFGSTATENAMES.map((c) => ({ label: c, value: c }));

export const initalState = {
  groupBy: "month",
  color: "#198754",
  chartName: "chart name",
  courseOptions: ALL_COURSES,
  gradeOptions: ALL_GRADES,
  schoolLevelOptions: ALL_SCHOOL_LEVELS,
  certificateGeneratedOptions: CERTIFICATE_GENERATED_OPTIONS,
  startDate: null,
  endDate: null,
  teacherId: "",
  courseId: "",
  serviceNumberId: "",
  gradeId: "",
  schoolLevelId: "",
  formattedData: [],
  country: "",
  countryState: [],
  certificateGenerated: "",
  serviceNumberOptions: ALL_SERVICE_NUMBERS,
  teacherOptions: ALL_TEACHERS,
  completionStatus: "",
  completionStartDate: null,
  completionEndDate: null,
  attemptNumber: null,
  validCertificateGeneratedOptions: CERTIFICATE_GENERATED_OPTIONS,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "setTeacherId":
      return { ...state, teacherId: action.payload };
    case "setTeacherOptions":
      return { ...state, teacherOptions: action.payload };
    case "setColor":
      return { ...state, color: action.payload };
    case "setChartName":
      return { ...state, chartName: action.payload };
    case "setGroupBy":
      return { ...state, groupBy: action.payload };
    case "setCourseOptions":
      return { ...state, courseOptions: action.payload };
    case "setServiceNumberOptions":
      return { ...state, serviceNumberOptions: action.payload };
    case "setGradeOptions":
      return { ...state, gradeOptions: action.payload };
    case "setSchoolLevelOptions":
      return { ...state, schoolLevelOptions: action.payload };
    case "setStartDate":
      return { ...state, startDate: action.payload?.setHours(0, 0, 0) };
    case "setEndDate":
      return { ...state, endDate: action.payload?.setHours(23, 59, 59) };
    case "setCountry":
      return { ...state, country: action.payload };
    case "setCountryState":
      return { ...state, countryState: action.payload };
    case "setCourseId":
      return { ...state, courseId: action.payload };
    case "setServiceNumberId":
      return { ...state, serviceNumberId: action.payload };
    case "setGradeId":
      return { ...state, gradeId: action.payload, schoolLevelId: "" };
    case "setSchoolLevelId":
      return { ...state, schoolLevelId: action.payload, gradeId: "" };
    case "setCertificateGenerated":
      return { ...state, certificateGenerated: action.payload };
    case "setCompletionStatus":
      return { ...state, completionStatus: action.payload };
    case "setCompletionStartDate":
      return {
        ...state,
        completionStartDate: action.payload?.setHours(0, 0, 0),
      };
    case "setCompletionEndDate":
      return {
        ...state,
        completionEndDate: action.payload?.setHours(23, 59, 59),
      };
    case "setFormattedData":
      return { ...state, formattedData: action.payload };
    case "setAttemptNumber":
      return { ...state, attemptNumber: action.payload };
    case "setValidCertificateGeneratedOptions":
      return { ...state, validCertificateGeneratedOptions: action.payload };
    default:
      return state;
  }
};
