export const ALL_TEACHERS = [{ value: "", label: "All Teachers" }];
export const ALL_GRADES = [{ value: "", label: "All Course Grades" }];
export const ALL_SCHOOL_LEVELS = [{ value: "", label: "All Other Types" }];
export const ALL_LANGUAGES = [{ value: "", label: "All Languages" }];

const advanceFilterInitialState = {
  languageId: null,
  published: null,
  archived: null,
};

export const initalState = {
  groupBy: "month",
  startDate: null,
  endDate: null,
  teacherId: null,
  teacherOptions: ALL_TEACHERS,
  gradeId: null,
  gradeOptions: ALL_GRADES,
  schoolLevelId: null,
  schoolLevelOptions: ALL_SCHOOL_LEVELS,
  languageOptions: ALL_LANGUAGES,
  formattedData: [],
  showAdvanced: false,
  ...advanceFilterInitialState,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "setGroupBy":
      return { ...state, groupBy: action.payload };
    case "setAxisContent":
      return { ...state, axisContent: action.payload };
    case "setTeacherId":
      return { ...state, teacherId: action.payload };
    case "setTeacherOptions":
      return { ...state, teacherOptions: action.payload };
    case "setGradeId":
      return { ...state, gradeId: action.payload, schoolLevelId: "" };
    case "setGradeOptions":
      return { ...state, gradeOptions: action.payload };
    case "setSchoolLevelId":
      return { ...state, schoolLevelId: action.payload, gradeId: "" };
    case "setSchoolLevelOptions":
      return { ...state, schoolLevelOptions: action.payload };
    case "setLanguageId":
      return { ...state, languageId: action.payload };
    case "setLanguageOptions":
      return { ...state, languageOptions: action.payload };
    case "setStartDate":
      return { ...state, startDate: action.payload };
    case "setEndDate":
      return { ...state, endDate: action.payload };
    case "setFormattedData":
      return { ...state, formattedData: action.payload };
    case "setPublished":
      return { ...state, published: action.payload };
    case "setArchived":
      return { ...state, archived: action.payload };
    case "toggleAdvanced":
      return {
        ...state,
        showAdvanced: !state.showAdvanced,
        ...advanceFilterInitialState,
      };
    default:
      return state;
  }
};
