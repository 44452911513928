//core
import React, { Fragment, useEffect, useState } from "react";
import useSWR from "swr";
import {
  Grid,
  Form,
  Row,
  Col,
  Button,
  CheckPicker,
  SelectPicker,
  Checkbox,
  RadioGroup,
  Radio,
  Tooltip,
  Whisper,
} from "rsuite";

//helpers
import { fetcher } from "../../../utils/fetcher";
import { ordinalize } from "../../../utils/ordinalize";
import { GENDER_OPTIONS, STUDENT_AGE_GROUPS } from "../../../utils/constants";

const Filter = ({ state, dispatch, handleFilters }) => {
  const picker = React.useRef();
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  const { data: grades = { data: [] } } = useSWR(
    "/api/v0/grades?type=numbers",
    fetcher,
  );
  const { data: allSchoolLevels } = useSWR(
    `/api/v0/grades?type=school_levels`,
    fetcher,
  );
  const { data: allCourses } = useSWR(
    {
      url: `/api/v0/courses`,
      params: {
        type: state?.courseType,
        grade_id: state?.gradeIds,
      },
    },
    fetcher,
  );
  useEffect(() => {
    if (!allCourses) return;
    const courseOptions = allCourses?.data?.map((course) => ({
      value: course.id,
      label: course.attributes.title,
    }));

    dispatch({
      type: "setCourseOptions",
      payload: [...courseOptions],
    });
  }, [allCourses]);

  useEffect(() => {
    const filteredValues =
      state?.courseIds?.filter((value) =>
        state?.courseOptions?.some((item) => item?.value === value),
      ) ?? [];
    dispatch({
      type: "setCourseIds",
      payload: [...filteredValues],
    });
  }, [state?.courseOptions]);

  const { data: locations } = useSWR(`/api/v0/locations`, fetcher);

  useEffect(() => {
    if (locations) {
      Object.keys(locations?.countries)?.length &&
        setLocationOptions(
          Object.entries(locations?.countries)?.map(([key, val]) => ({
            label: `${key} (${val})`,
            value: key,
          })),
        );
      Object.keys(locations?.afg_states)?.length &&
        setProvinceOptions(
          Object.entries(locations?.afg_states)?.map(([key, val]) => ({
            label: `${key} (${val})`,
            value: key,
          })),
        );
    }
  }, [locations]);

  const filters = [
    {
      name: "grades",
      label: "Course Grades",
      data:
        state.courseType != "OtherType"
          ? grades.data
              .filter((grade) => {
                return (
                  state.courseType != "ESL" ||
                  parseInt(grade.attributes.name) > 6
                );
              })
              .map((grade) => ({
                value: grade.id,
                label: ordinalize(grade.attributes.name),
              }))
          : allSchoolLevels?.data?.map((grade) => ({
              value: grade.id,
              label: grade.attributes.name,
            })),
      value: state.gradeIds,
      searchable: false,
      placeholder: "Select",
    },
    {
      name: "courses",
      label: "Courses",
      data: state.courseOptions,
      value: state.courseIds,
      searchable: false,
      placeholder: "Select",
    },
    {
      name: "locations",
      label: "Location",
      data: locationOptions,
      value: state.locationId,
      searchable: false,
      placeholder: "Select",
    },
    {
      name: "age",
      label: "Age Group",
      data: STUDENT_AGE_GROUPS?.slice(1),
      value: state.age,
      searchable: false,
      placeholder: "Select",
    },
    {
      name: "gender",
      label: "Gender",
      data: GENDER_OPTIONS,
      value: state.gender,
      searchable: false,
      placeholder: "Select",
    },
  ];

  const handleCheckAll = (name, value, checked) => {
    switch (name) {
      case "grades": {
        let grades = [];
        if (checked) {
          const gradeOptions = [...state.gradeOptions];
          grades = gradeOptions?.map((gradeOption) => gradeOption.value);
        } else {
          grades = [];
        }
        dispatch({
          type: "setGradeIds",
          payload: grades,
        });
        break;
      }
      case "provinces": {
        let provinces = [];
        if (checked) {
          provinces = provinceOptions?.map(
            (provinceOption) => provinceOption?.value,
          );
        } else {
          provinces = [];
        }
        dispatch({
          type: "setProvinceIds",
          payload: provinces,
        });
        break;
      }
      case "courses": {
        let courses = [];
        if (checked) {
          const courseOptions = [...state.courseOptions];
          courses = courseOptions.map((courseOption) => courseOption.value);
        } else {
          courses = [];
        }
        dispatch({
          type: "setCourseIds",
          payload: courses,
        });
        break;
      }
      default:
      // others
    }
  };

  const handleChange = (newValue, previousValue, name) => {
    switch (name) {
      case "grades": {
        let grades = [];
        if (newValue.includes("all") && previousValue === "all") {
          const gradeOptions = [...state.gradeOptions];
          grades = gradeOptions.map((gradeOption) => gradeOption.value);
        } else if (!newValue.includes("all") && previousValue === "all") {
          grades = [];
        } else {
          grades = Array.from(
            new Set([...newValue.filter((grade) => grade !== "all")]),
          );
        }
        dispatch({
          type: "setGradeIds",
          payload: grades,
        });
        break;
      }
      case "provinces": {
        dispatch({
          type: "setProvinceIds",
          payload: Array.from(new Set([...newValue])),
        });
        break;
      }
      case "courses": {
        let courses = [];
        if (newValue.includes("all") && previousValue === "all") {
          const courseOptions = [...state.courseOptions];
          courses = courseOptions.map((courseOption) => courseOption.value);
        } else if (!newValue.includes("all") && previousValue === "all") {
          courses = [];
        } else {
          courses = Array.from(
            new Set([...newValue.filter((course) => course !== "all")]),
          );
        }
        dispatch({
          type: "setCourseIds",
          payload: courses,
        });
        break;
      }
      default:
      // others
    }
  };

  const handleSingleChange = (newValue, name) => {
    switch (name) {
      case "locations":
        dispatch({
          type: "setLocationId",
          payload: newValue,
        });
        break;
      case "age":
        dispatch({
          type: "setAge",
          payload: newValue,
        });
        break;
      case "gender":
        dispatch({
          type: "setGender",
          payload: newValue,
        });
        break;
      case "courseType":
        dispatch({
          type: "setCourseType",
          payload: newValue,
        });
        dispatch({
          type: "setGradeIds",
          payload: [],
        });
        dispatch({
          type: "setCourseIds",
          payload: [],
        });
        break;
      default:
      // others
    }
  };

  const handleClean = (name) => {
    switch (name) {
      case "grades":
        dispatch({
          type: "setGradeIds",
          payload: [],
        });
        dispatch({
          type: "setAppliedData",
          payload: {
            ...state?.appliedData,
            gradeIds: [],
          },
        });
        break;
      case "locations":
        dispatch({
          type: "setLocationId",
          payload: "",
        });
        dispatch({
          type: "setAppliedData",
          payload: {
            ...state?.appliedData,
            locationId: "",
          },
        });
        break;
      case "provinces":
        dispatch({
          type: "setProvinceIds",
          payload: [],
        });
        dispatch({
          type: "setAppliedData",
          payload: {
            ...state?.appliedData,
            provinceIds: [],
          },
        });
        break;
      case "courses":
        dispatch({
          type: "setCourseIds",
          payload: [],
        });
        dispatch({
          type: "setAppliedData",
          payload: {
            ...state?.appliedData,
            courseIds: [],
          },
        });
        break;
      case "age":
        dispatch({
          type: "setAge",
          payload: "",
        });
        dispatch({
          type: "setAppliedData",
          payload: {
            ...state?.appliedData,
            age: "",
          },
        });
        break;
      case "gender":
        dispatch({
          type: "setGender",
          payload: "",
        });
        dispatch({
          type: "setAppliedData",
          payload: {
            ...state?.appliedData,
            gender: "",
          },
        });
        break;
      case "courseType":
        dispatch({
          type: "setCourseType",
          payload: "Graded",
        });
        dispatch({
          type: "setAppliedData",
          payload: {
            ...state?.appliedData,
            courseType: "Graded",
          },
        });
        break;
      default:
      // others
    }
  };

  const clearFilters = (event) => {
    event.preventDefault();
    handleFilters(false);
    dispatch({
      type: "setClearFilters",
    });
  };

  const applyFilters = (event) => {
    event.preventDefault();
    handleFilters(true);
  };

  const isDisabled = !(
    state?.courseIds?.length ||
    state?.gradeIds?.length ||
    state?.locationId?.length ||
    state?.provinceIds?.length ||
    state?.age?.length ||
    state?.gender?.length
  )
    ? true
    : false;

  useEffect(() => {
    !state?.locationId?.length &&
      dispatch({
        type: "setProvinceIds",
        payload: [],
      });
  }, [state.locationId]);

  const customTooltip = (item, items) => {
    const selectedLabels = items?.map((i) => i?.tooltip ?? i?.label).join(", ");
    return items.map((i, index) => (
      <Fragment key={index}>
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip arrow={false}>{selectedLabels}</Tooltip>}
        >
          <span>{i.label}</span>
        </Whisper>
        {index !== items.length - 1 && ", "}
      </Fragment>
    ));
  };

  const SingleSelectionFilters = ["locations", "age", "gender"];

  return (
    <div className="row mb-3">
      <Form>
        <div className="form-group">
          <label className="f-med mb-1" htmlFor="courseType">
            Course Type
          </label>
          <RadioGroup
            name="course-type"
            inline
            defaultValue={state?.courseType}
            value={state?.courseType}
            onChange={(value) => handleSingleChange(value, "courseType")}
          >
            <Radio value="Graded">Graded</Radio>
            <Radio value="OtherType">OtherType</Radio>
            <Radio value="ESL">ESL</Radio>
          </RadioGroup>
        </div>
        {filters?.map((item, index) => {
          return (
            <div key={index}>
              <div className="form-group">
                <label className="f-med mb-1">{item.label}</label>
                {item.name === "locations" && (
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        The no. displayed inside the bracket is the no. of
                        registered users for that country.
                      </Tooltip>
                    }
                  >
                    <i className="fa-solid fa-circle-info light-txt ms-2"></i>
                  </Whisper>
                )}
                {SingleSelectionFilters?.includes(item?.name) ? (
                  <SelectPicker
                    className="w-100"
                    ref={picker}
                    block
                    value={item.value}
                    data={item.data}
                    placement="autoVerticalStart"
                    menuMaxHeight={200}
                    style={{ width: 224 }}
                    searchable={item.searchable}
                    placeholder="Select"
                    countable={false}
                    onChange={(newValue) =>
                      handleSingleChange(newValue, item.name)
                    }
                    onClean={() => handleClean(item.name)}
                  />
                ) : (
                  <CheckPicker
                    className="w-100"
                    placement="autoVerticalStart"
                    menuMaxHeight={200}
                    ref={picker}
                    block
                    value={item.value}
                    data={item.data}
                    style={{ width: 224 }}
                    searchable={item.searchable}
                    placeholder="Select"
                    countable={false}
                    onChange={(newValue, event) =>
                      handleChange(newValue, event?.target.value, item.name)
                    }
                    onClean={() => handleClean(item.name)}
                    renderExtraFooter={() => (
                      <Checkbox
                        checked={item?.value?.length === item?.data?.length}
                        onChange={(value, checked) =>
                          handleCheckAll(item?.name, value, checked)
                        }
                      >
                        All {item?.name}
                      </Checkbox>
                    )}
                    renderValue={(value, items) => customTooltip(item, items)}
                  />
                )}
              </div>
              {item.name === "locations" &&
              state.locationId.includes("Afghanistan") ? (
                <div className="form-group">
                  <label className="f-med mb-1" htmlFor={item.name}>
                    Province
                  </label>
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        The no. displayed inside the bracket is the no. of
                        registered users for that province.
                      </Tooltip>
                    }
                  >
                    <i className="fa-solid fa-circle-info light-txt ms-2"></i>
                  </Whisper>
                  <CheckPicker
                    className="w-100"
                    placement="autoVerticalStart"
                    menuMaxHeight={200}
                    ref={picker}
                    block
                    value={state.provinceIds}
                    data={provinceOptions}
                    countable={false}
                    style={{ width: 224 }}
                    searchable={false}
                    placeholder="Select"
                    onChange={(newValue, event) =>
                      handleChange(newValue, event?.target.value, "provinces")
                    }
                    onClean={() => handleClean("provinces")}
                    renderExtraFooter={() => (
                      <Checkbox
                        checked={
                          state.provinceIds.length === provinceOptions?.length
                        }
                        onChange={(value, checked) =>
                          handleCheckAll("provinces", value, checked)
                        }
                      >
                        All provinces
                      </Checkbox>
                    )}
                    renderValue={(value, items) => customTooltip(item, items)}
                  />
                </div>
              ) : null}
            </div>
          );
        })}

        <Grid fluid={"100%"} className="p-0 pt-3">
          <Row gutter={20}>
            <Col xs={12}>
              <Button block appearance="subtle" onClick={clearFilters}>
                Clear
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                appearance="primary"
                disabled={isDisabled}
                onClick={applyFilters}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Grid>
      </Form>
    </div>
  );
};

export default Filter;
