import React, { useEffect, useReducer } from "react";
import { initalState, reducer } from "./reducer";

const ChartSettings = ({
  updateColor,
  updateChartName,
  updateAxisContentData,
}) => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const { color, chartName, groupBy } = state;

  useEffect(() => {
    dispatch({
      type: "setChartName",
      payload: `chart ${Math.ceil(Math.random() * 1000)}`,
    });
  }, []);

  useEffect(() => {
    updateColor(color);
  }, [color]);

  useEffect(() => {
    if (chartName === "chart name") return;
    updateChartName(chartName);
  }, [chartName]);

  useEffect(() => {
    if (!groupBy) return;
    updateAxisContentData(groupBy);
  }, [groupBy]);
  return (
    <div className="row mt-3">
      <div className="col-md-3">
        <div className="form-group">
          <label className="f-med mb-1" htmlFor="chartName">
            Chart Name
          </label>
          <input
            name="chartName"
            className="form-control theme-ip"
            type="text"
            value={chartName}
            maxLength={20}
            onChange={(e) =>
              dispatch({ type: "setChartName", payload: e.target.value })
            }
          />
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label className="f-med mb-1" htmlFor="color">
            Color
          </label>
          <div className="relative">
            <input
              name="color"
              className="form-control theme-ip color-pick"
              type="color"
              value={color}
              onChange={(e) =>
                dispatch({ type: "setColor", payload: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartSettings;
