import React from "react";

import Selected from "react-spinners/SyncLoader";

const Spinner = () => {
  return (
    <Selected
      color={"#36d7b7"}
      loading={true}
      size={20}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default Spinner;
