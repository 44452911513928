import React from "react";
import { Form, Panel } from "rsuite";

const StepOne = ({ onChange }) => (
  <Panel header="Select File" bordered>
    <Form>
      <Form.Group>
        <input
          name="file"
          type="file"
          onChange={(event) => onChange(event.target.files[0])}
        />
      </Form.Group>
    </Form>
  </Panel>
);

export default StepOne;
