import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import Content from "./content";
import useSWR from "swr";
import { fetcher } from "../../../utils/fetcher";
import PreviewForm from "../preview";
import { getAuthenticityToken } from "../../../utils/authenticity_token";
import {
  format_date_without_time_zone,
  format_date_and_time_into_one,
} from "../../../utils/date_formatter";
import { validTemplateText } from "../../../utils/helpers";
import axios from "axios";

const Index = ({ state, dispatch, onClose, id, reminders, editable }) => {
  const { data: languagesData } = useSWR({ url: "/api/v0/languages" }, fetcher);

  const formattedLanguages = languagesData
    ? languagesData["data"].map((language) => ({
        value: language.id,
        label: language.attributes.name,
        key: language.attributes.code,
      }))
    : [];

  let isEditable = id && reminders && !editable;
  let languageIds = (isEditable ? reminders : state)?.filters?.language_id;
  const filteredLanguages = formattedLanguages?.filter((item) =>
    languageIds?.includes(item.value),
  );

  const [openPreview, setOpenPreview] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (!state?.content) return;

    setErrors([]);
    let err = [];
    filteredLanguages?.forEach((item) => {
      let { key, label } = item;
      let { title, message } = state.content[key];
      if (!title.length || !message.length) {
        err.push(`${label} -> title and message cannot be empty`);
      }
      if (!validTemplateText(title) || !validTemplateText(message)) {
        err.push(
          `${label} -> title and message cannot have newline or more then 4 consecutive spaces`,
        );
      }
    });
    setErrors(err);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.content]);

  useEffect(() => {
    dispatch({
      type: "setCopied",
      payload: true,
    });
  }, []);

  return (
    <div className="content">
      <div className="sm-container">
        <Content
          reminders={reminders}
          errors={errors}
          showErrors={showErrors}
          id={id}
          filteredLanguages={filteredLanguages}
          {...{ state, dispatch }}
          readOnly={false}
        />

        <div className="row">
          <div className="col-sm-6">
            <Button onClick={onClose} appearance="subtle" block>
              Cancel
            </Button>
          </div>

          <div className="col-sm-6">
            <Button
              onClick={() => {
                setShowErrors(true);
                if (errors.length) return;
                setOpenPreview(true);
              }}
              type="submit"
              color="green"
              appearance="primary"
              block
            >
              {id && reminders ? "Save" : "Schedule"}
            </Button>
          </div>
        </div>
      </div>
      {!!openPreview && (
        <PreviewForm
          {...{ state, dispatch }}
          open={!!openPreview}
          filteredLanguages={filteredLanguages}
          onConfirm={() => {
            setOpenPreview(false);
            const data = {
              filters: {
                ...state?.filters,
                language_id: state?.filters?.language_id,
                grade_id: [
                  ...(state?.filters?.gradeId ?? [])
                ],
                inactive_since_date: state?.filters?.inactive_since_date
                  ? format_date_without_time_zone(
                      new Date(state?.filters?.inactive_since_date),
                    )
                  : "",
                active_since_date: state?.filters?.active_since_date
                  ? format_date_without_time_zone(
                      new Date(state?.filters?.active_since_date),
                    )
                  : "",
              },
              schedule: {
                ...state?.schedule,
                start_date: state?.schedule?.start_date
                  ? format_date_and_time_into_one(
                      new Date(state?.schedule?.start_date),
                      new Date(state?.schedule?.start_time),
                    )
                  : "",
                end_date: state?.schedule?.end_date
                  ? new Date(state?.schedule?.end_date).toString()
                  : "",
              },
              content: state?.content,
              media: state?.media,
            };
            if (isEditable) {
              delete data.filters;
              delete data.schedule;
            }

            const url =
              id && reminders ? `/api/v0/reminders/${id}` : "/api/v0/reminders";
            const method = id && reminders ? "PATCH" : "POST";
            axios({
              method,
              url,
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
              data: data,
            })
              .then(() => {
                window.location.reload();
                return;
              })
              .catch((err) => {
                alert("Oops! something went wrong.");
                console.log(err.response.data.errors);
              });
          }}
          onClose={() => {
            setOpenPreview(false);
          }}
        />
      )}
    </div>
  );
};

export default Index;
