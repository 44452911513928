import moment from "moment";

export const format_date = (date) => {
  if (!date) return null;
  return moment(date).format("YYYY-MM-DD");
};

export const format_date_in_time_zone = (date) => {
  if (!date) return null;
  return moment(date).format("YYYY-MM-DD hh:mm A Z");
};

export const format_date_without_time_zone = (date) => {
  if (!date) return null;
  return moment(date).format("YYYY-MM-DD HH:mm");
};

export const format_date_without_time_zone_a = (date) => {
  if (!date) return null;
  return moment(date).format("YYYY-MM-DD hh:mma");
};

export const format_start_of_day_in_time_zone = (date) => {
  if (!date) return null;
  return format_date_in_time_zone(moment(date).hours(0).minutes(0).toDate());
};

export const format_end_of_day_in_time_zone = (date) => {
  if (!date) return null;
  return format_date_in_time_zone(moment(date).hours(23).minutes(59).toDate());
};

// "YYYY-MM-DD HH:mm"
export const format_date_and_time_into_one = (date, time) => {
  if (!date) return null;
  if (!time) return null;
  return `${moment(date).format("YYYY-MM-DD")} ${moment(time).format("HH:mm")}`
}

export const date_after_days = (date, days) => {
  if (!date) return null;
  return moment(date).add(days, 'days');
}

export const date_before_days = (date, days) => {
  if (!date) return null;
  return moment(date).subtract(days, 'days');
}