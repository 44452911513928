import React, { useState } from "react";
import LoaderSpinner from "./loader_spinner";
import PageTitle from "../../components/page_title";
import { Form, Stack, Button, SelectPicker } from "rsuite";
import axios from "axios";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import "./style.css";

const CertificateAuthenticater = () => {
  const [language, setLangage] = useState("en");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [certificate_code, setCertificateCode] = useState("");
  const [certificate_code_verified, setCertificateCodeVerified] =
    useState(false);
  const [fullname, setFullname] = useState("");
  const [fullname_verified, setFullnameVerified] = useState(false);

  const { data: translations } = useSWR(
    {
      url: `/api/v0/translations/certificate_authenticator.json?language_code=${language}`,
    },
    fetcher,
    {
      onSuccess: (data) => {
        setLoading(false);
        document.title = data.page_title + " — SOLAx";
      },
    },
  );

  const handleCertificateCodeChange = (value) => {
    setCertificateCode(value.trim());
  };

  const onSubmitCertificateCode = () => {
    setError(null);
    setLoading(true);
    axios
      .get(
        `/api/v0/certificates/authenticate?code=${certificate_code}&locate=true`,
      )
      .then((response) => {
        setLoading(false);
        setSuccess("certificate_located");
        setCertificateCodeVerified(true);
      })
      .catch(() => {
        setLoading(false);
        setError("unable_to_locate");
      });
  };

  const backToCertificateCodeForm = () => {
    setCertificateCodeVerified(false);
    setFullname("");
    setSuccess(null);
    setError(null);
  };

  const handleFullnameChange = (value) => {
    setFullname(value);
  };

  const onSubmitFullname = () => {
    setError(null);
    setLoading(true);
    axios
      .get(
        `/api/v0/certificates/authenticate?code=${certificate_code}&fullname=${fullname}`,
      )
      .then((response) => {
        setLoading(false);
        setSuccess("certificate_authenticated");
        setFullnameVerified(true);
      })
      .catch(() => {
        setLoading(false);
        setError("name_did_not_match");
      });
  };

  const reset = () => {
    setCertificateCode("");
    setCertificateCodeVerified(false);
    setFullname("");
    setFullnameVerified(false);
    setSuccess(null);
    setError(null);
  };

  return (
    <div className="certificate-auth text-center pt-5">
      <div className="container-md">
        <Form>
          <Form.Group className="d-flex justify-content-between">
            <Form.Control
              name="language"
              accepter={SelectPicker}
              data={[
                { value: "en", label: "English" },
                { value: "ps", label: "Pashto" },
                { value: "fa", label: "Dari" },
              ]}
              labelKey="label"
              valueKey="value"
              onChange={(value) => {
                setLangage(value);
                setLoading(true);
              }}
              value={language}
              cleanable={false}
            />
          </Form.Group>
        </Form>
        <PageTitle title={translations?.page_title} />
        <div className="">{translations?.page_description}</div>

        <div className="site-card mt-5">
          {success && (
            <div className="alert alert-success">{translations?.[success]}</div>
          )}
          {error && (
            <div className="alert alert-danger">
              <i className="fa fa-check"></i>
              {translations?.[error]}
            </div>
          )}
          {/* Form for verify certificate code */}
          {!certificate_code_verified && (
            <Form fluid>
              <h2 className="h5 head-sm text-center mb-4 pb-2">
                {translations?.verify_certificate}
              </h2>
              <Form.Group>
                <Form.ControlLabel>
                  {translations?.certificate_number}
                  <span className="text-danger">*</span>
                </Form.ControlLabel>
                <Form.Control
                  name="certificate_code"
                  type="text"
                  value={certificate_code}
                  placeholder={translations?.certificate_number}
                  className="theme-ip"
                  onChange={handleCertificateCodeChange}
                  disabled={loading}
                  required
                  autoFocus
                />
                <Form.HelpText className="font-italic">
                  <i>*{translations?.certificate_number_helptext}</i>
                </Form.HelpText>
              </Form.Group>
              <Button
                className="theme-btn w-100"
                onClick={onSubmitCertificateCode}
                disabled={loading}
              >
                {translations?.next}
              </Button>
            </Form>
          )}
          {/* Form for verify full name */}
          {certificate_code_verified && !fullname_verified && (
            <Form fluid>
              <h2 className="h5 head-sm text-center mb-4 pb-2">
                {translations?.verify_certificate}
              </h2>
              <Form.Group>
                <Form.ControlLabel>
                  {translations?.fullname}
                  <span className="text-danger">*</span>
                </Form.ControlLabel>
                <Form.Control
                  name="fullname"
                  type="text"
                  value={fullname}
                  placeholder={translations?.fullname}
                  className="theme-ip"
                  onChange={handleFullnameChange}
                  disabled={loading || fullname_verified}
                  required
                  autoFocus
                />
                <Form.HelpText className="font-italic">
                  <i>{translations?.fullname_helptext}</i>
                </Form.HelpText>
              </Form.Group>
              <Stack direction="row" spacing={20}>
                <Stack.Item basis={"50%"}>
                  <Button
                    className="theme-btn w-100 light"
                    onClick={backToCertificateCodeForm}
                    disabled={loading}
                  >
                    {translations?.go_back}
                  </Button>
                </Stack.Item>
                <Stack.Item basis={"50%"}>
                  <Button
                    className="theme-btn w-100"
                    onClick={onSubmitFullname}
                    disabled={loading}
                  >
                    {loading
                      ? translations?.verifying
                      : translations?.verify_certificate}
                  </Button>
                </Stack.Item>
              </Stack>
            </Form>
          )}
          {fullname_verified && (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="check-icon"
              >
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
              </svg>
              <h2 className="h5 head-sm text-center mb-4">
                {translations?.verified}
              </h2>
              <Button
                className="theme-btn w-100"
                onClick={reset}
                disabled={loading}
              >
                {translations?.verify_another_certificate}
              </Button>
            </div>
          )}
          {loading && <LoaderSpinner />}
        </div>
      </div>
    </div>
  );
};

export default CertificateAuthenticater;
