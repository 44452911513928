import React from "react";
import classNames from "classnames";

const SelectFilter = ({
  id,
  value,
  onChange = () => {},
  options = [],
  classnames = [],
  wrapperClassnames = ["select-outer"],
}) => (
  <div className={classNames(wrapperClassnames)}>
    <select
      id={id}
      className={classNames("form-control theme-ip", ...classnames)}
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <option value={option.value} disabled={option.disabled}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default SelectFilter;
