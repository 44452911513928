import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";

/*
const items = [
  to: string
  label: string
]
*/
const SubpageNavbar = ({ items = [] }) => {
  return (
    <div className="d-flex">
      {items.map((item) => (
        <NavLink
          to={item.to}
          className={({ isActive }) =>
            classNames("tab", { active: isActive }, { incative: !isActive })
          }
        >
          {item.label}
        </NavLink>
      ))}
    </div>
  );
};

export default SubpageNavbar;
