import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form } from "rsuite";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import { Link } from "react-router-dom";
import { validateEmail } from "../../utils/validators";

export default function Registration() {
  const [errors, setErrors] = useState({
    email: "",
    fullname: "",
    password: "",
    passwordConfirmation: "",
  });
  const [state, setState] = useState({
    email: "",
    fullname: "",
    password: "",
    passwordConfirmation: "",
    successMessage: "",
  });

  useEffect(() => {
    const passwordEyeIcon = document.querySelector("#passwordIcon");
    const passwordInput = document.querySelector('input[name="password"]');
    passwordEyeIcon.addEventListener("click", () => {
      if (Array.from(passwordEyeIcon.classList).includes("fa-eye-slash")) {
        passwordEyeIcon.classList.add("fa-eye");
        passwordEyeIcon.classList.remove("fa-eye-slash");
        passwordInput.setAttribute("type", "text");
      } else {
        passwordEyeIcon.classList.add("fa-eye-slash");
        passwordEyeIcon.classList.remove("fa-eye");
        passwordInput.setAttribute("type", "password");
      }
    });
    const confirmPasswordIcon = document.querySelector("#confirmPasswordIcon");
    const confirmPasswordInput = document.querySelector(
      'input[name="password confirmation"]',
    );
    confirmPasswordIcon.addEventListener("click", () => {
      if (Array.from(confirmPasswordIcon.classList).includes("fa-eye-slash")) {
        confirmPasswordIcon.classList.add("fa-eye");
        confirmPasswordIcon.classList.remove("fa-eye-slash");
        confirmPasswordInput.setAttribute("type", "text");
      } else {
        confirmPasswordIcon.classList.add("fa-eye-slash");
        confirmPasswordIcon.classList.remove("fa-eye");
        confirmPasswordInput.setAttribute("type", "password");
      }
    });
  }, []);

  const resetErrors = () =>
    setErrors({
      email: "",
      fullname: "",
      password: "",
      passwordConfirmation: "",
    });

  const onSubmit = () => {
    resetErrors();
    const { email, fullname, password, passwordConfirmation } = state;
    if (password !== passwordConfirmation) {
      setErrors({
        ...errors,
        passwordConfirmation: "Password confirmation does not match",
      });
      return;
    }
    if (!validateEmail(state.email)) {
      setErrors({
        ...errors,
        email: "Please enter a valid email address",
      });
      return;
    }
    axios({
      method: "post",
      url: "/api/v0/guests/registration",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getAuthenticityToken(),
      },
      data: {
        user: { email, fullname, password },
      },
    })
      .then((response) => {
        const { success_message } = response?.data;
        setState({
          email: "",
          fullname: "",
          password: "",
          passwordConfirmation: "",
          successMessage: success_message,
        });
      })
      .catch((e) => {
        const { errors } = e.response?.data;
        if (errors) {
          setErrors(errors);
        }
      });
  };

  const handleEmailChange = (value) => {
    setState({ ...state, email: value });
    setErrors({ ...errors, email: "" });
    if (value === "") {
      setState({ ...state, email: "", password: "", passwordConfirmation: "" });
    }
  };

  const handleFullNameChange = (value) => {
    const fullNameRegex = /^[a-zA-Z\s]+$/;
    if (value.match(fullNameRegex) || value === "") {
      setState({ ...state, fullname: value });
      setErrors({ ...errors, fullname: "" });
    } else {
      setErrors({
        ...errors,
        fullname: "Fullname can only contain alphabets and spaces.",
      });
    }
  };

  const handlePasswordChange = (value) => {
    setState({ ...state, password: value });
    setErrors({ ...errors, password: "" });
  };

  const handlePasswordConfirmationChange = (value) => {
    setState({ ...state, passwordConfirmation: value });
    setErrors({ ...errors, passwordConfirmation: "" });
  };

  return (
    <div className="">
      <h2 className="head-xl">Sign Up</h2>
      <p className="grey-txt mb-4">
        Remote learning for every Afghan girl, everywhere.
      </p>
      <Form fluid onSubmit={onSubmit}>
        {state.successMessage && (
          <div className="alert alert-success">{state.successMessage}</div>
        )}
        <Form.Group>
          <Form.ControlLabel>
            Full Name<span className="text-danger">*</span>
          </Form.ControlLabel>
          <Form.Control
            name="full name"
            type="text"
            value={state.fullname}
            onChange={handleFullNameChange}
            maxLength={50}
            required
            errorMessage={errors.fullname}
            className="theme-ip"
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Email<span className="text-danger">*</span>
          </Form.ControlLabel>
          <Form.Control
            name="email"
            type="email"
            value={state.email}
            onChange={(value) => handleEmailChange(value)}
            required
            errorMessage={errors.email}
            className="theme-ip"
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Password<span className="text-danger">*</span>
          </Form.ControlLabel>
          <div className="position-relative">
            <Form.Control
              name="password"
              type="password"
              value={state.password}
              onChange={handlePasswordChange}
              required
              errorMessage={errors.password}
              className="theme-ip pe-5"
            />
            <i
              id="passwordIcon"
              className="fa-solid fa-eye-slash icon-after cursor-pointer"
            ></i>
          </div>
        </Form.Group>

        <Form.Group>
          <Form.ControlLabel>
            Confirm Password<span className="text-danger">*</span>
          </Form.ControlLabel>
          <div className="position-relative">
            <Form.Control
              name="password confirmation"
              type="password"
              value={state.passwordConfirmation}
              onChange={handlePasswordConfirmationChange}
              required
              errorMessage={errors.passwordConfirmation}
              className="theme-ip pe-5"
            />
            <i
              id="confirmPasswordIcon"
              className="fa-solid fa-eye-slash icon-after cursor-pointer"
            ></i>
          </div>
        </Form.Group>

        <Button type="submit" color="green" appearance="primary" block>
          Sign Up
        </Button>

        <p className="grey-txt mt-4">
          Already have an account?{" "}
          <Link to={"/guests/login"} className="f-med">
            Login
          </Link>
        </p>
      </Form>
    </div>
  );
}
