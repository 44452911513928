import React from "react";
import { Button, Modal } from "rsuite";

const Alert = ({ message, onClose }) => (
  <Modal open={!!message} onClose={onClose} size="xs" backdrop="static">
    <Modal.Body>
      <p>{message}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onClose} color="red" appearance="subtle">
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default Alert;
