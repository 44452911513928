import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import CourseItemForm from "./course_item_form";
import Spinner from "../../../components/spinner";
import { COURSE_CONTENT_TYPES_MAPPER } from "../../../utils/constants";
import { fetcher } from "../../../utils/fetcher";
import groupBy from "lodash/groupBy";
import { Table, Button, Pagination } from "rsuite";
import axios from "axios";
import { getAuthenticityToken } from "../../../utils/authenticity_token";
import DeleteConfirmationModal from "../../../components/delete_confirmation_modal";
import Alert from "../../../components/alert";
import { isTeacher } from "../../../utils/state";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const groupByDay = (data) =>
  groupBy(data["data"] || [], (item) => item.attributes.lesson_position);

export const newCourseItem = () => ({
  attributes: {
    title: "",
    content: "",
    type: "",
    delivery_day: 1,
    position: 1,
    data: null,
  },
});

const Content = () => {
  const { id: courseId } = useParams();
  const { data: courseDetails } = useSWR(
    `/api/v0/courses/${courseId}`,
    fetcher,
  );
  const {
    data = [],
    isLoading,
    mutate,
  } = useSWR(
    {
      url: "/api/v0/course_items",
      params: {
        course_id: courseId,
      },
    },
    fetcher,
  );
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const startIndex = (page - 1) * limit;
  const [total, setTotal] = useState(0);
  const endIndex = Math.min(startIndex + limit, total);

  useEffect(() => {
    if (!courseDetails) return;
    const course = courseDetails["data"];
    setDisableSaveDelete(isTeacher() && course?.attributes?.published);
  }, [courseDetails]);

  useEffect(() => {
    if (!(data?.data?.length || data?.length)) return;
    const groupedData = groupByDay(data);
    const allRecords = Object.keys(groupedData)?.map((key) => ({
      id: key,
      attributes: { day: `Lesson ${key}` },
      children: groupedData[key],
    }));
    setTotal(allRecords?.length);
    setShowNextButton(page * limit < allRecords.length);
    const pageRecords = allRecords.slice((page - 1) * limit, page * limit);
    setFormattedData(pageRecords);
  }, [page, data, limit]);

  const [courseItem, setCourseItem] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [alert, setAlert] = useState(null);
  const [errors, setErrors] = useState(null);
  const [disableSaveDelete, setDisableSaveDelete] = useState(false);
  const [formattedData, setFormattedData] = useState([]);
  const [showNextButton, setShowNextButton] = useState(true);

  if (isLoading) return <Spinner />;

  if (alert) return <Alert message={alert} onClose={() => setAlert(null)} />;

  return (
    <div>
      {courseItem ? (
        <CourseItemForm
          courseId={courseId}
          open={!!courseItem}
          onClose={() => {
            setCourseItem(null);
            setErrors(null);
          }}
          onConfirm={(courseItem) => {
            const { id } = courseItem;
            const url = id
              ? `/api/v0/course_items/${id}`
              : "/api/v0/course_items";
            const method = id ? "PUT" : "POST";

            axios({
              method,
              url,
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
              data: {
                course_item: {
                  ...courseItem,
                  course_id: courseId,
                },
              },
            })
              .then(() => {
                setCourseItem(null);
                setErrors(null);
                mutate();
                return null;
              })
              .catch((e) => {
                setErrors(e?.response?.data?.errors);
              });
          }}
          courseItem={courseItem}
          disableSave={disableSaveDelete}
          errors={errors}
        />
      ) : (
        <>
          <div>
            <Button
              appearance="ghost"
              className="px-3 me-2"
              color="green"
              onClick={() => setCourseItem(newCourseItem())}
            >
              <i className="fa fa-plus me-2"></i>
              Add Course Item
            </Button>
          </div>
          <div className="mx--3">
            <Table
              data={formattedData}
              height={450}
              width={"100wv"}
              isTree
              rowKey={"id"}
              onRowClick={(data) => {
                if (data.children) return;
              }}
            >
              <Column width={200} align="center" flexGrow={2}>
                <HeaderCell>Lesson</HeaderCell>
                <Cell dataKey="attributes.day" />
              </Column>
              <Column width={200} align="center" flexGrow={2}>
                <HeaderCell>Order</HeaderCell>
                <Cell dataKey="attributes.position" />
              </Column>
              <Column width={200} align="center" flexGrow={2}>
                <HeaderCell>Content Type</HeaderCell>
                <Cell>
                  {(rowData) => {
                    return COURSE_CONTENT_TYPES_MAPPER[
                      rowData["attributes"]["type"]
                    ];
                  }}
                </Cell>
              </Column>
              <Column width={200} align="center" flexGrow={2}>
                <HeaderCell>Assessment</HeaderCell>
                <Cell dataKey="attributes.assessment" />
              </Column>
              <Column width={200} align="center" flexGrow={3}>
                <HeaderCell>Actions</HeaderCell>
                <Cell>
                  {(rowData) => {
                    if (rowData.children) {
                      return null;
                    }
                    return (
                      <div className="light-txt px-2 py-2">
                        <i
                          className="fa-solid fa-pen me-2"
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => setCourseItem(rowData)}
                          onClick={() => setCourseItem(rowData)}
                        ></i>
                        <i
                          className="fa-solid fa-trash me-2"
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => setItemToDelete(rowData)}
                          onClick={() => setItemToDelete(rowData)}
                        ></i>
                      </div>
                    );
                  }}
                </Cell>
              </Column>
            </Table>
            {((formattedData || []).length >= 10 || page > 1) && (
              <div className="d-flex px-3 justify-content-between align-items-center">
                <div className="grey-txt mt-2 f-14">
                  {startIndex + 1} to{" "}
                  {endIndex && endIndex != 0 ? endIndex : ""} of {total}
                </div>
                <div className="pagination-outer">
                  <Pagination
                    prev
                    next={!isLoading && showNextButton}
                    ellipsis
                    layout={["limit", "|", "pager"]}
                    size="sm"
                    activePage={page}
                    onChangePage={(page) => setPage(page)}
                    onChangeLimit={(limit) => {
                      setLimit(limit), setPage(1);
                    }}
                    limit={limit}
                    limitOptions={[10, 30, 50]}
                    total={total}
                    maxButtons={10}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {!!itemToDelete && (
        <DeleteConfirmationModal
          open={!!itemToDelete}
          onClose={() => setItemToDelete(null)}
          onConfirm={() =>
            axios({
              method: "DELETE",
              url: `/api/v0/course_items/${itemToDelete.id}`,
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
            })
              .then(() => {
                setItemToDelete(null);
                mutate();
                return null;
              })
              .catch((e) => console.error(e))
          }
        />
      )}
    </div>
  );
};

export default Content;
