// post login we redirect to this page if the 2FA is enabled on the whole platform
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "rsuite";
import Verify2FAModal from "./verify_2fa_modal";
import { getAuthenticityToken } from "../../../utils/authenticity_token";
import axios from "axios";

const Verify2FA = () => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const { state } = useLocation();
  const {
    email,
    password,
    two_factor_enabled,
    two_factor_qr_code_as_svg,
    two_factor_otp_secret,
  } = state;

  const toggleShowModal = () => {
    setShowModal((showModal) => !showModal);
  };

  const onSubmit = (otp_attempt) => {
    axios({
      method: "post",
      url: "/api/v0/guests/login",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getAuthenticityToken(),
      },
      data: {
        user: { email, password, otp_attempt },
      },
    })
      .then(() => {
        window.location.reload(true);
      })
      .catch((response) => {
        setError(
          "The provided code is invalid or expired. \n Please try again",
        );
      });
  };

  return (
    <div>
      <div className="alert alert-warning">
        {two_factor_enabled
          ? "Use your authenticator app to verify before accessing the dashboard"
          : "You must enable 2FA before accessing the dashboard"}
      </div>
      <Button
        onClick={toggleShowModal}
        color="green"
        appearance="primary"
        className="mb-3"
      >
        {two_factor_enabled ? "Verify OTP" : "Enable 2FA"}
      </Button>
      <Verify2FAModal
        open={showModal}
        onClose={toggleShowModal}
        two_factor_enabled={two_factor_enabled}
        qr_code_as_svg={two_factor_qr_code_as_svg}
        otp_secret={two_factor_otp_secret}
        onSubmit={onSubmit}
        error={error}
      />
    </div>
  );
};

export default Verify2FA;
