import React from "react";
import { Button, Modal } from "rsuite";

const DeleteConfirmationModal = ({ open, onClose, onConfirm, 
  message = "Do you really want delete this record? This process can't be undone" }) => (
  <Modal open={open} onClose={onClose} size="xs" backdrop="static">
    <Modal.Header>
      <Modal.Title>        
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="text-center">
        <i className="fa fa-warning text-danger f-40 mb-3"></i>
      </div>
      <h2 className="head-lg mb-3 text-center">Are you sure?</h2>
      <p className="grey-txt text-center">{message}</p>
    </Modal.Body>
    <Modal.Footer className="d-flex">
      <Button onClick={onClose} appearance="subtle" block className="me-2">
        Cancel
      </Button>
      <Button onClick={onConfirm} color="green" appearance="primary" className="mt-0 ms-2" block>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

export default DeleteConfirmationModal;
