import axios from "axios";

export const fetcher = (...args) => {
  if (args.length == 1) {
    const arg = args[0];
    if (typeof arg === "string") {
      return axios.get(arg).then((res) => res.data);
    }
    if (typeof arg === "object" && arg.url) {
      return axios({ method: "GET", ...arg }).then((res) => res.data);
    }
  }

  return fetch(...args).then((res) => res.json());
};
