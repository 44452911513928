import React, { useEffect, useState } from "react";
import { List, Loader, Panel, Button } from "rsuite";

const SUPPORT_FILES_DOC_URL =
  "https://developers.facebook.com/docs/whatsapp/cloud-api/reference/media#supported-media-types";

const StepFour = ({ file_meta_data, onConfirm, onCancel }) => {
  return (
    <Panel header="Details" bordered>
      <div>
        <p>
          Compare your file info against the list of the{" "}
          <a
            href={SUPPORT_FILES_DOC_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            supported files (WhatsApp Doc)
          </a>
        </p>
        <p>
          If your find doesn't seem supported, you can return to the previous
          step and select the other option.
        </p>
      </div>
      <List>
        <List.Item>
          <span className="fw-bold">File extension:</span> {file_meta_data.ext}
        </List.Item>
        <List.Item>
          <span className="fw-bold">Content size: </span>{" "}
          {file_meta_data?.content_length}
        </List.Item>
      </List>
      <Panel className="d-flex justify-content-end">
        <Button appearance="primary" onClick={onConfirm}>
          Attach
        </Button>
        <Button appearance="subtle" onClick={onCancel}>
          Return
        </Button>
      </Panel>
    </Panel>
  );
};

export default StepFour;
