import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../components/page_title";
import { Button } from "rsuite";
import Courses from "./courses";
import useSWR from "swr";
import { fetcher } from "../../../utils/fetcher";

const items = [
  {
    to: "details",
    label: "Details",
  },
  {
    to: "courses",
    label: "Courses",
  },
];
const Show = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useSWR(`/api/v0/students/${id}`, fetcher);
  return (
    <div className="container">
      <div className='mb-3'>
        <div className="row align-items-center">
          <div className="col-md-4">
            <PageTitle title={"Student Profile"} />
          </div>
          <div className="col-md-8">
            <div className="d-flex justify-content-end">
              <Button
                appearance="ghost"
                className="px-3 me-2"
                color="green"
                onClick={() => navigate(-1)}
              >
                <i className="fa fa-angle-left"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="shadow-sm bg-white radius-5 student-table">
        <div className="top bb px-4 pt-2 pb-2">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="head-md text-dark">Courses</h3>
            <div className="f-med">
              <span className="grey-txt">Full Name: </span>
              <span className="text-capitalize">{data?.data?.attributes?.fullname}</span>
            </div>
          </div>
        </div>
        <div className="inner">
          <Courses />
        </div>
      </div>
    </div>
  );
};

export default Show;
