import React, { Fragment, useState } from "react";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import { Table, Button, Pagination } from "rsuite";
import Form from "./form";
import axios from "axios";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import PageTitle from "../../components/page_title";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const newServiceNumber = () => ({
  id: null,
  attributes: {
    identifier: "",
    number: "",
  },
});

const Index = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const {
    data = [],
    isLoading,
    mutate,
  } = useSWR(
    {
      url: "/api/v0/service_numbers",
      params: {
        page: page,
        limit: limit,
      },
    },
    fetcher,
  );

  const [serviceNumberToEdit, setServiceNumberToEdit] = useState(null);
  const [editErrors, setEditErrors] = useState({});
  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, data?.total);
  return (
    <div className="container">
      <div className='mb-3 d-flex justify-content-between align-items-center'>
        <PageTitle title={"Service Numbers"} />
        <Button
          color="green"
          appearance="primary"
          onClick={() => setServiceNumberToEdit(newServiceNumber())}
        >
          <i className="fa fa-plus me-2"></i>
          Add Service Number
        </Button>
      </div>

      <div className="shadow-sm bg-white radius-5 overflow-hidden">
        <div className="inner">
          <Fragment>
            <Table
              loading={isLoading}
              height={500}
              width={"100wv"}
              data={data?.data || []}
            >
              <Column width={100}>
                <HeaderCell>No</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => (
                    <div>{rowIndex + 1 + (page - 1) * limit}</div>
                  )}
                </Cell>
              </Column>
              <Column width={100} flexGrow={3}>
                <HeaderCell>Identifier</HeaderCell>
                <Cell dataKey="attributes.identifier" />
              </Column>
              <Column width={100} flexGrow={3}>
                <HeaderCell>Number</HeaderCell>
                <Cell dataKey="attributes.number" />
              </Column>
              <Column width={100} flexGrow={1}>
                <HeaderCell>Actions</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => (
                    <div>
                      <i className="light-txt py-2 fa-solid fa-pen" onClick={() => setServiceNumberToEdit(rowData)}></i>
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
            
            {!!serviceNumberToEdit && (
              <Form
                open={!!serviceNumberToEdit}
                serviceNumber={serviceNumberToEdit}
                errors={editErrors}
                onConfirm={({ identifier, number }) => {
                  const { id } = serviceNumberToEdit;
                  const isNew = !id;
                  const url = isNew
                    ? "/api/v0/service_numbers"
                    : `/api/v0/service_numbers/${id}`;
                  const method = isNew ? "POST" : "PUT";
                  const data = {
                    identifier,
                    number,
                  };
                  axios({
                    method,
                    url,
                    headers: {
                      "Content-Type": "application/json",
                      "X-CSRF-Token": getAuthenticityToken(),
                    },
                    data,
                  })
                    .then((response) => {
                      mutate();
                      setServiceNumberToEdit(null);
                      setEditErrors({});
                    })
                    .catch((error) => {
                      setEditErrors(error.response.data.errors);
                    });
                }}
                onClose={() => {
                  setServiceNumberToEdit(null);
                  setEditErrors({});
                }}
              />
            )}
          </Fragment>      
      </div>
      </div>
      {((data?.data || []).length >= 10 || page > 1) && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="grey-txt mt-2 f-14">
                  {startIndex + 1} to {(endIndex && endIndex != 0) ? endIndex : ''} of {data?.total}
                </div>
                <div className="pagination-outer">
                  <Pagination
                    prev
                    next={!isLoading}
                    ellipsis
                    layout={["limit", "|", "pager"]}
                    size="sm"
                    activePage={page}
                    onChangePage={(page) => setPage(page)}
                    onChangeLimit={(limit) => setLimit(limit)}
                    limit={limit}
                    limitOptions={[10, 30, 50]}
                    total={data?.total}
                    maxButtons={10}
                    />
                  </div>
            </div>
          )}
    </div>
  );
};

export default Index;
