import React from "react";
import { Button } from "rsuite";

const LinkCopied = ({ onCancel }) => (
  <div className="text-center">
    <p>Link copied to clipboard. You can now paste it into your course item.</p>
    <p>you can close this now.</p>

    <p>if you want to use the other option, click on the "return" button</p>
    <div className="d-flex justify-content-end">
      <Button appearance="primary" onClick={onCancel}>
        Return
      </Button>
    </div>
  </div>
);

export default LinkCopied;
