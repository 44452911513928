import React, { useEffect, useState } from "react";
import { Panel, Button, Loader } from "rsuite";
import copyToClipboard from "../../../utils/text_copy";
import { whatsappMediaChecker } from "../../../utils/uploader";

const StepThree = ({
  file_url,
  file_meta_data,
  disableAttach,
  disableAttachMessage,
  setMetadata,
  onLinkCopied,
  onAttachFileSelected,
}) => {
  const [isFetching, setIsFetching] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    fetch(`/api/v0/url_metadata?url=${file_url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsFetching(false);
        setMetadata(data);
        return true;
      }).catch(e=>console.error(e));
  }, []);

  if (isFetching) {
    return (
      <Panel className="d-flex justify-content-center">
        <Loader content="Analyzing the content encoding" size="lg" />
      </Panel>
    );
  }
  const { potentially_supported_as_interactive_message_header = true } =
    file_meta_data;
  return (
    <Panel>
      <div className="row">
        You have two options to share this file with your students:
        <ul>
          <li>
            Copy the link and past it to the content section of the item.
            the student will be able to access the file by clicking on the link.
          </li>
          <li>
            Attach the file to the item. The file will be sent as an
            attachment along with the content. The student can view the file&apos;s
            content directly with being redirected to a link. You can use this
            option only one time per course item. The file size and content must
            be supported by the WhatsApp.
          </li>
        </ul>
      </div>
      {disableAttach && (
        <div className="alert alert-warning">
          {disableAttachMessage || "File attach is not allowed. But you can still copy the link."}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger">
          This file is not attachable. But you can still copy the link.
        </div>
      )}
      <div className="row">
        <div className="col-md-6 d-flex justify-content-center">
          <Button
            onClick={() => {
              copyToClipboard(file_url);
              onLinkCopied();
            }}
          >
            Copy link
          </Button>
        </div>
        <div className="col-md-6 d-flex justify-content-center">
          <Button
            disabled={disableAttach || !potentially_supported_as_interactive_message_header}
            onClick={() => {
              setIsFetching(true);
              whatsappMediaChecker(file_url).then((response) => {
                setErrorMessage(!response.data.authenticated);
                onAttachFileSelected(response.data.authenticated);
                setIsFetching(false);
                return true;
              }).catch(e=>console.error(e));
            }}
          >
            Attach file
          </Button>
        </div>
      </div>
    </Panel>
  );
};
export default StepThree;
