import React, { useEffect } from "react";
import { Button, Modal } from "rsuite";
import Content from "../content/content";

const PreviewForm = ({
  open,
  onClose,
  onConfirm,
  state,
  dispatch,
  filteredLanguages,
}) => {
  useEffect(() => {
    if (state?.schedule?.ends == "never") {
      dispatch({ type: "setEndDate", payload: null });
    }
  }, [state?.schedule?.ends]);
  return (
    <Modal open={open} onClose={onClose} size="xs" backdrop="static">
      <Modal.Header>
        <Modal.Title>Preview</Modal.Title>
        <span>Please review and confirm the submission.</span>
      </Modal.Header>
      <Modal.Body>
        <Content
          readOnly={true}
          {...{ state, dispatch }}
          filteredLanguages={filteredLanguages}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="subtle">
          Cancel
        </Button>
        <Button onClick={() => onConfirm()} color="green" appearance="primary">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewForm;
