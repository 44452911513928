import React, { useState } from "react";
import { Modal, Form, Button, Loader, Panel } from "rsuite";
import uploader from "../../utils/uploader";

const ProjectMediaCreationForm = ({ open, onClose, onConfirm, errors }) => {
  const [state, setState] = useState({
    key: "",
    file: null,
  });

  const [isUploading, setIsUploading] = useState(false);

  const onUploadFinish = (file_url) => {
    let newState = { ...state, url: file_url, filename: state.file?.name };
    onConfirm(newState);
  };

  return (
    <Modal open={open} onClose={onClose} size="xs" backdrop="static">
      <Modal.Header>
        <Modal.Title>
          Add Project Media
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isUploading ? (
          <Panel className="d-flex justify-content-center">
            <Loader content="uploading" size="lg" />
          </Panel>
        ) : (
          <Form fluid>
            <Form.Group>
              <Form.ControlLabel>KEY<span className="text-danger">*</span></Form.ControlLabel>
              <Form.Control
                name="key"
                value={state.key}
                block
                onChange={(value) => setState({ ...state, key: value })}
                errorMessage={errors?.key}
              />
            </Form.Group>
            <Form.Group>
              <input
                name="file"
                type="file"
                className="rs-input p-1"
                onChange={(event) =>
                  setState({ ...state, file: event.target.files[0] })
                }
              />
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      {isUploading || (
        <Modal.Footer>
          <Button onClick={onClose} appearance="subtle">
            Cancel
          </Button>
          {state.file && (
            <Button
              appearance="primary"
              onClick={() => {
                setIsUploading(true);
                uploader(state.file)
                  .then((url) => {
                    onUploadFinish(url);
                  })
                  .catch((error) => {
                    setIsUploading(false);
                  });
              }}
            >
              Upload
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ProjectMediaCreationForm;
