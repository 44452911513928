import React, { Fragment } from "react";
import SelectFilter from "../../../components/select";
import { DatePicker } from "rsuite";

const ARCHIVED_OPTIONS = [
  { value: "", label: "Archived/Unarchived" },
  { value: true, label: "Archived" },
  { value: false, label: "Unarchived" },
];

const PUBLIC_OPTIONS = [
  { value: "", label: "Published/Unpublished" },
  { value: true, label: "Published" },
  { value: false, label: "Unpublished" },
];

const Filter = ({ state, dispatch }) => {
  const {
    startDate,
    endDate,
    teacherId,
    teacherOptions,
    gradeId,
    gradeOptions,
    schoolLevelId,
    schoolLevelOptions,
    published,
    archived,
    languageId,
    languageOptions,
  } = state;
  return (
    <div className="row">
      <div className="col-md-3">
        <div className="form-group">
          <SelectFilter
            value={teacherId}
            onChange={(e) =>
              dispatch({ type: "setTeacherId", payload: e.target.value })
            }
            options={teacherOptions}
          />
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <SelectFilter
            value={gradeId}
            onChange={(e) =>
              dispatch({ type: "setGradeId", payload: e.target.value })
            }
            options={gradeOptions}
          />
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <SelectFilter
            value={schoolLevelId}
            onChange={(e) =>
              dispatch({ type: "setSchoolLevelId", payload: e.target.value })
            }
            options={schoolLevelOptions}
          />
        </div>
      </div>

      <Fragment>
        <div className="col-md-3">
          <div className="form-group">
            <SelectFilter
              value={published || ""}
              onChange={(e) =>
                dispatch({ type: "setPublished", payload: e.target.value })
              }
              options={PUBLIC_OPTIONS}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <SelectFilter
              value={languageId}
              onChange={(e) =>
                dispatch({ type: "setLanguageId", payload: e.target.value })
              }
              options={languageOptions}
            />
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group">
            <SelectFilter
              value={archived || ""}
              onChange={(e) =>
                dispatch({ type: "setArchived", payload: e.target.value })
              }
              options={ARCHIVED_OPTIONS}
            />
          </div>
        </div>
      </Fragment>
      <div className="col-12">
        <h3 className="head-sm">Creation date </h3>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <DatePicker
                size="lg"
                block
                placeholder="Start Date"
                shouldDisableDate={(date) => date > (endDate || new Date())}
                onChange={(date) =>
                  dispatch({ type: "setStartDate", payload: date })
                }
                value={startDate}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <DatePicker
                size="lg"
                block
                placeholder="End Date"
                shouldDisableDate={(date) => date < startDate}
                onChange={(date) =>
                  dispatch({ type: "setEndDate", payload: date })
                }
                value={endDate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
