import React, { useState } from "react";
import { Button, Modal, Form as RSForm } from "rsuite";

const EditForm = ({ open, onClose, onConfirm, student, errors }) => {
  const [fullname, setFullname] = useState(
    student?.attributes?.fullname || "",
  );

  return (
    <Modal open={open} onClose={onClose} size="xs" backdrop="static">
      <Modal.Header>
        <Modal.Title>
          Edit Student Name
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"rs-form-control-wrapper mb-3"}>
          <div className={"rs-form-control-label"}>Full Name<span className="text-danger">*</span></div>
          <div className={"rs-form-control-input-wrapper"}>
            <input
              type="text"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              className={"rs-input"}
            />
          </div>
          <RSForm.ErrorMessage
            show={errors?.fullname?.length > 0}
            style={{ color: "red" }}
          >
            {errors?.fullname?.join(",")}
          </RSForm.ErrorMessage>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="subtle">
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm({ fullname })}
          color="green"
          appearance="primary"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditForm;
