import React, { useState } from "react";
import { Outlet, Routes, Route, Navigate } from "react-router-dom";
import PageTitle from "../../components/page_title";
import SubpageNavbar from "../../components/subpage_navbar";
import { isAdmin } from "../../utils/state";
import Courses from "./courses";
import Attempts from "./attempts";
import NumbersUsage from "./numbers_usage";
import StudentEngagement from "./students_engagement";

const adminItem = [
  {
    to: "courses",
    label: "Courses",
  },
  {
    to: "numbers_usage",
    label: "Numbers Usage",
  },
  {
    to: "students_engagement",
    label: "Students Engagement",
  },
];

const items = [
  {
    to: "attempts",
    label: "Attempts",
  },
  ...(isAdmin() ? adminItem : []),
];
const Wrapper = () => {
  return (
    <div className="container">
      <div className="mb-3">
        <PageTitle title={"Analytics"} />
      </div>
      <div className="shadow-sm bg-white radius-5 tab-sec">
        <div className="top">
          {items.length > 1 && <SubpageNavbar items={items} />}
        </div>
        <div className="inner">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const Index = () => {
  const [chartDetails, setChartDetails] = useState({});
  const updateChartList = (chartData) => {
    setChartDetails(chartData);
  };

  return (
    <Routes>
      <Route path="*" element={<Wrapper />}>
        <Route
          path="attempts"
          element={
            <Attempts
              updateChartList={(chartData) => updateChartList(chartData)}
              chartDetails={chartDetails}
            />
          }
        />
        <Route path="courses" element={<Courses />} />
        <Route path="numbers_usage" element={<NumbersUsage />} />
        <Route path="students_engagement" element={<StudentEngagement />} />
        <Route path="*" element={<Navigate to="attempts" />} />
      </Route>
    </Routes>
  );
};

export default Index;
