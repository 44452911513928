import React from "react";
import profile_icon from "images/profile_icon.svg";
import { getUserEmail, getUserName } from "../../utils/state";
import { getAuthenticityToken } from "../../utils/authenticity_token";

const TopNavbar = () => {
  const email = getUserEmail();
  const name = getUserName();
  const nameFirstLetter = name.charAt(0).toUpperCase();

  return (
    <header className="header bg-white shadow-sm text-end py-2 px-3">
      <div className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle user-drop"
          data-bs-toggle="dropdown"
          href="#"
          role="button"
          aria-expanded="false"
        >
          <div>{nameFirstLetter}</div>
        </a>
        <ul className="dropdown-menu header-drop">
          <li className="grey-txt f-14 text-center px-3 pb-3 pt-2">{email}</li>
          <li className="pt-2 border-top">
            <Logout />
          </li>
        </ul>
      </div>
    </header>
  );
};

const Logout = () => {
  return (
    <div
      className="dropdown-item f-14 py-2 text-danger"
      style={{ cursor: "pointer" }}
      onClick={() => {
        fetch("/api/v0/guests/logout", {
          method: "DELETE",
          headers: {
            "X-CSRF-Token": getAuthenticityToken(),
          },
          redirect: "manual",
        }).then(() => {
          window.location.href = "/";
        });
      }}
    >
      Logout
    </div>
  );
};

export default TopNavbar;
