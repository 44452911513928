import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import List from "./list";

const Users = () => {
  return (
    <Routes>
      <Route path="*" element={<Outlet />}>
        <Route path="*" element={<List />} />
      </Route>
    </Routes>
  );
};
export default Users;
