import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  InputPicker,
  InputNumber,
  RadioGroup,
  Radio,
} from "rsuite";
import FillTheGap from "./questions/fill_the_gaps";
import MCQ from "./questions/mcq";
import TrueOrFalse from "./questions/true_or_false";
import AttachFileModal from "../../../components/attach_file";
import UrlPreview from "../../../components/attach_file/url_preview";
import DeleteConfirmationModal from "../../../components/delete_confirmation_modal";
import useSWR from "swr";
import { fetcher } from "../../../utils/fetcher";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
Textarea.displayName = "Textarea";

const TYPE_OPTIONS = [
  { value: "Article", label: "Article" },
  { value: "TrueOrFalse", label: "True Or False Question" },
  { value: "FillTheGap", label: "Fill The Gap Question" },
  { value: "MCQ", label: "MCQ" },
];

const CourseItemCreationForm = ({
  courseId,
  onClose,
  onConfirm,
  courseItem,
  disableSave,
  errors,
}) => {
  const [state, setState] = useState({
    id: courseItem?.id || null,
    content: courseItem?.attributes?.content || "",
    type: courseItem?.attributes?.type || "Article",
    assessment: courseItem?.attributes?.assessment,
    lesson_id: courseItem?.attributes?.lesson_id?.toString() || null,
    data: courseItem?.attributes?.data || null,
    url: courseItem?.attributes?.url || null,
    media_type: courseItem?.attributes?.media_type || null,
    filename: null,
    disable_save: disableSave,
  });
  const [showAttachFile, setShowAttachFile] = useState(false);
  const [showDeleteAttachmentConfirm, setShowDeleteAttachmentConfirm] =
    useState(false);
  const [showDeleteAttachmentConfirmMCQ, setShowDeleteAttachmentConfirmMCQ] =
    useState(false);

  const { data: lessons = { data: [] } } = useSWR(`/api/v0/lessons?course_id=${courseId}`, fetcher);

  const onSubmit = async () => {
    let newState = { ...state };
    onConfirm(newState);
  };

  return (
    <>
      <Form onSubmit={onSubmit} fluid>
        <div className="sm-container">
          <Form.Group>
            <Form.ControlLabel>Lesson</Form.ControlLabel>
            <Form.Control
              name="lesson_id"
              accepter={InputPicker}
              placeholder="New Lesson"
              value={state.lesson_id}
              onChange={(value) => {
                setState({ ...state, lesson_id: value });
              }}
              errorMessage={errors?.lesson_id}
              labelKey="title"
              valueKey="id"
              data={lessons?.data?.map((lesson) => ({
                id: lesson.id,
                title: lesson.attributes.title
              }))}
            />
          </Form.Group>
          
          <Form.Group>
            <Form.ControlLabel>
              Type<span className="text-danger">*</span>
            </Form.ControlLabel>
            <Form.Control
              name="type"
              value={state.type}
              block
              onChange={(value) => {
                // if change to MCQ & attachment is present, confirm delete attachment
                if (value == "MCQ" && state.url) {
                  setShowDeleteAttachmentConfirmMCQ(true);
                } else {
                  setState({ ...state, type: value, data: null });
                }
              }}
              errorMessage={errors?.type}
              accepter={InputPicker}
              data={TYPE_OPTIONS}
              cleanable={false}
            />
          </Form.Group>
          {state.type != "Article" && (
            <Form.Group>
              <Form.ControlLabel>
                Assessment Criteria<span className="text-danger">*</span>
              </Form.ControlLabel>
              <Form.Control
                name="assessment"
                accepter={RadioGroup}
                inline
                value={state.assessment}
                onChange={(value) => setState({ ...state, assessment: value })}
                errorMessage={errors?.assessment}
              >
                <Radio value="Formative">Formative</Radio>
                <Radio value="Summative">Summative</Radio>
              </Form.Control>
            </Form.Group>
          )}

          <Question
            type={state.type}
            data={state.data}
            onChange={(value) => setState({ ...state, data: value })}
          />

          <Form.Group>
            <Form.ControlLabel className="d-flex">
              Content<span className="text-danger">*</span>{" "}
              <span className="ms-auto light-txt small">
                ( {state?.content?.length || 0} / 1024)
              </span>
            </Form.ControlLabel>
            <Form.Control
              name="content"
              value={state.content}
              onChange={(value) => {
                if (value.length > 1024) return;
                setState({ ...state, content: value });
              }}
              errorMessage={errors?.content}
              accepter={Textarea}
              rows={10}
              required
              dir="auto"
            />
          </Form.Group>
        </div>

        {/* attachment */}
        <Form.Group className="mt-3">
          <Form.ControlLabel>Media file</Form.ControlLabel>
          <Form.HelpText className="pt-0 mb-2">
            You can attach only one file that can be an image, a video or an
            audio.
          </Form.HelpText>
          {!showAttachFile ? (
            <Button onClick={() => setShowAttachFile(true)}>Attach</Button>
          ) : (
            <AttachFileModal
              disableAttach={state.type == "MCQ"}
              disableAttachMessage={
                "Cannot attach file for MCQ type. But you can still copy the link."
              }
              onAttach={(url, media_type, filename) => {
                if (state.type == "MCQ") {
                  setShowDeleteAttachmentConfirmMCQ(true);
                } else {
                  setState({ ...state, url, media_type, filename });
                  setShowAttachFile(null);
                }
              }}
              onClose={() => setShowAttachFile(null)}
            />
          )}
        </Form.Group>
        {/* attachment > preview */}
        {state.url && (
          <UrlPreview
            url={state.url}
            media_type={state.media_type}
            onClear={() => setShowDeleteAttachmentConfirm(true)}
          />
        )}
        <DeleteConfirmationModal
          open={showDeleteAttachmentConfirm}
          onClose={() => setShowDeleteAttachmentConfirm(false)}
          onConfirm={() => {
            setState({ ...state, url: null, media_type: null });
            setShowDeleteAttachmentConfirm(false);
          }}
        />
        <DeleteConfirmationModal
          open={showDeleteAttachmentConfirmMCQ}
          onClose={() => setShowDeleteAttachmentConfirmMCQ(false)}
          onConfirm={() => {
            setState({
              ...state,
              type: "MCQ",
              data: null,
              url: null,
              media_type: null,
            });
            setShowDeleteAttachmentConfirmMCQ(false);
            setShowAttachFile(null);
          }}
          message={
            "MCQ type does not support attachment. Delete attachment and set type to MCQ?"
          }
        />
        {/* ENDS attachment */}

        <div className="sm-container">
          <div className="row">
            <div className="col-6">
              <Button onClick={onClose} appearance="subtle" block>
                Cancel
              </Button>
            </div>
            <div className="col-6">
              <Button
                type="submit"
                color="green"
                appearance="primary"
                block
                disabled={state.disable_save}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

const Question = ({ type, data, onChange }) => {
  const Component = {
    TrueOrFalse: TrueOrFalse,
    FillTheGap: FillTheGap,
    MCQ: MCQ,
  }[type];
  if (!Component) return null;

  return <Component {...{ data, onChange }} />;
};

export default CourseItemCreationForm;
