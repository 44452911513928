import React, { Fragment } from "react";
import { isLoggedIn } from "../../utils/state";
import {
  Navigate,
  NavLink,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import Login from "./login";
import classNames from "classnames";
import styled from "styled-components";
import Registration from "./registration";
import chatacLogo from "images/Logo.png";
import formBg from "images/login-bg.png";
import Fav from "images/fav-white.png";
import leafImg from "images/leaf.png";
import ForgotPassword from "./forgot_password";
import ResetPassword from "./reset_password";
import Verify2FA from "./verify_2fa";
import { Col, Grid, Row, Button } from "rsuite";
import { Link } from "react-router-dom";

const navStyle = ({ isActive }) =>
  classNames("w-50 d-block py-2", "mb-4 ", "text-center border-bottom", {
    "border-primary text-primary": isActive,
    "border-dark text-dark": !isActive,
  });

const style = ({ isActive }) =>
  classNames("w-100 d-d-block py-2", "mb-4 ", "text-center border-bottom", {
    "border-primary text-primary": isActive,
  });

const NavLinkContainer = styled.div`
  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

const Logo = styled.img`
  width: 500px;
  height: 250px;
  margin-top: 100px;
  margin-bottom: 100px;
`;
const Guests = () => {
  const location = useLocation();
  const { token } = useParams();

  if (isLoggedIn()) return <Navigate to="/" />;

  return (
    <div>
      <div className="d-flex flex-column justify-content-center min-vh-100 py-5">
        <div className="md-container">
          <div className="shadow-sm bg-white radius-8 user-form-outer overflow-hidden">
            <div className="row flex-column-reverse flex-lg-row">
              <div className="col-lg-6">
                <div className="left">
                  <img src={formBg} alt="Background" className="bg" />
                  <img src={leafImg} alt="Leaf" className="leaf" />
                  <div className="inner">
                    <div className="mb-4">
                      <img src={Fav} alt="Fav Icon" />
                    </div>
                    <div className="my-md-5 py-lg-5">
                      <h1 className="head-xl">
                        Educating Afghan Girls Worldwide
                      </h1>
                      <p className="mb-4">
                        Innovative WhatsApp-based learning. We pair leading-edge
                        tech with an Afghan heart, creating opportunity and
                        inspiring hope.
                      </p>
                      <div className="mb-4 pb-3">
                        <Button
                          block
                          className="white-btn"
                          appearence="primary"
                          title="See How it Works"
                          target="_blank"
                          href="https://www.solax.org/en/howitworks"
                        >
                          See How it Works
                        </Button>
                      </div>
                      <div className="d-flex align-items-center social mb-3">
                        <Link
                          to={
                            "https://www.facebook.com/profile.php?id=61555898271658"
                          }
                          target="_blank"
                          title="Facebook"
                        >
                          <i className="fa-brands fa-facebook-f"></i>
                        </Link>
                        <Link
                          to={"https://twitter.com/SOLAxOnline"}
                          target="_blank"
                          title="Twitter"
                        >
                          <i className="fa-brands fa-x-twitter"></i>
                        </Link>
                        <Link
                          to={
                            "https://www.linkedin.com/company/101504966/admin/feed/posts/"
                          }
                          target="_blank"
                          title="LinkedIn"
                        >
                          <i className="fa-brands fa-linkedin-in"></i>
                        </Link>
                        <Link
                          to={
                            "https://whatsapp.com/channel/0029VaBlLNCIt5s04KCD661Y"
                          }
                          target="_blank"
                          title="Whatsapp"
                        >
                          <i className="fa-brands fa-whatsapp"></i>
                        </Link>
                        <Link
                          to={"https://www.youtube.com/@SOLAxOnline"}
                          target="_blank"
                          title="Youtube"
                        >
                          <i className="fa-brands fa-youtube"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="right">
                  <div className="inner d-flex flex-column justify-content-center">
                    <NavLinkContainer className="d-none justify-content-center">
                      {(location.pathname === "/guests/login" ||
                        location.pathname === "/guests/register") && (
                        <Fragment>
                          <NavLink to="login" className={navStyle}>
                            Login
                          </NavLink>
                          <NavLink to="register" className={navStyle}>
                            Register
                          </NavLink>
                        </Fragment>
                      )}

                      {location.pathname === "/guests/forgot-password" && (
                        <NavLink to="forgot-password" className={style}>
                          Forgot Password
                        </NavLink>
                      )}

                      {location.pathname === "/guests/reset-password" &&
                        location.search && (
                          <NavLink
                            to={`/guests/reset-password${location.search}`}
                            className={style}
                          >
                            Reset Password
                          </NavLink>
                        )}

                      {location.pathname === "/guests/verify-2fa" && (
                        <NavLink
                          to="verify-2fa"
                          className={style}
                          onClick={(e) => e.preventDefault()}
                        >
                          Verify 2FA
                        </NavLink>
                      )}
                    </NavLinkContainer>
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Index = () => (
  <Routes>
    <Route path="*" element={<Guests />}>
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="verify-2fa" element={<Verify2FA />} />
      <Route path="*" element={<Navigate to="login" />} />
    </Route>
  </Routes>
);
export default Index;
