import React, { useEffect, useState } from "react";
import { Form, RadioGroup, Radio } from "rsuite";

const trueOrFalseInitialState = () => ({
  answers: [
    {
      id: 1,
      value: "True",
      weight: 100,
    },
    {
      id: 2,
      value: "False",
      weight: 0,
    },
  ],
});

const TrueOrFalse = ({ data, onChange }) => {
  const [state, setState] = useState(data || trueOrFalseInitialState());
  const selected = state["answers"].find((answer) => answer.weight === 100);
  useEffect(() => {
    onChange(state);
  }, [state]);
  return (
    <Form.Group>
      <Form.ControlLabel>Answer<span className="text-danger">*</span></Form.ControlLabel>
      <RadioGroup
        inline
        value={selected.value}
        onChange={(value) => {
          const answers = state.answers.map((answer) => ({
            ...answer,
            weight: answer.value === value ? 100 : 0,
          }));
          setState({ ...state, answers });
        }}
      >
        {state.answers.map((answer) => {
          return <Radio value={answer.value}>{answer.value}</Radio>;
        })}
      </RadioGroup>
    </Form.Group>
  );
};

export default TrueOrFalse;
