import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Form, Button, Stack } from "rsuite";
import axios from "axios";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import Spinner from "../../components/spinner/index";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";

const ResetPassword = () => {
  const location = useLocation();
  const token = location.search.split("=")[1];

  const { data, isLoading } = useSWR(
    `/api/v0/guests/validate_token?reset_password_token=${token}`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const [errors, setErrors] = useState({
    password: "",
    password_confirmation: "",
    errorMessage: "",
  });
  const [state, setState] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    successMessage: "",
  });

  const handleClick = (icon, name) => {
    const passwordInput = document.querySelector(`input[name="${name}"]`);
    if (Array.from(icon?.classList).includes("fa-eye-slash")) {
      icon?.classList.add("fa-eye");
      icon?.classList.remove("fa-eye-slash");
      passwordInput.setAttribute("type", "text");
    } else {
      icon?.classList.add("fa-eye-slash");
      icon?.classList.remove("fa-eye");
      passwordInput.setAttribute("type", "password");
    }
  }

  const resetErrors = () =>
    setErrors({
      password: "",
      password_confirmation: "",
    });

  const [isSuccess, setIsSuccess] = useState(null);

  const onSubmit = () => {
    resetErrors();
    const { password, password_confirmation } = state;

    if (password.length < 6 || password !== password_confirmation) {
      setErrors({
        password:
          "Password must be at least six characters and match the confirmation.",
      });
      return;
    }

    axios({
      method: "put",
      url: "/api/v0/guests/reset_password",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "X-CSRF-Token": getAuthenticityToken(),
      },
      data: {
        user: {
          password,
          password_confirmation,
          reset_password_token: token,
        },
      },
    })
      .then((response) => {
        const { message } = response?.data;
        setState({
          successMessage: message,
        });
        setIsSuccess(response?.data);
      })
      .catch((response) => {
        const { error } = response?.data;
        setErrors({
          errorMessage: error,
        });
      });
  };

  const handleLoginPage = () => {
    window.location.href = "/guests/login";
  };

  const sendPasswordResetRequest = () => {
    try {
      axios({
        method: "put",
        url: "/api/v0/guests/forgot_password",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "X-CSRF-Token": getAuthenticityToken(),
        },
        data: { email: data.email },
      })
        .then((response) => {
          const { success } = response?.data;
          setState({
            successMessage: success,
          });
        })
        .catch((e) => setErrors(e.response?.data?.error));
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <div>
      <h2 className="head-xl">Reset Password</h2>
      <p className="grey-txt mb-4">
        Create a new password to access your account.
      </p>
      {data?.time_out === true && (
        <Stack direction="column" alignItems="stretch">
          {state.successMessage ? (
            <div className="alert alert-success">
              An email with instructions was sent successfully
            </div>
          ) : (
            <div className="alert alert-danger">
              Reset password token expired. Please try again
            </div>
          )}
          <Button
            appearance="primary"
            color="green"
            block
            onClick={sendPasswordResetRequest}
          >
            Resend Email
          </Button>
        </Stack>
      )}

      {data?.success === false && (
        <Stack direction="column" alignItems="stretch">
          <div className="alert alert-danger">Invalid request</div>

          <Button
            appearance="primary"
            block
            color="green"
            onClick={handleLoginPage}
          >
            Login
          </Button>
        </Stack>
      )}

      {data?.success === true && isSuccess?.message && (
        <Stack direction="column" alignItems="stretch">
          <div className="alert alert-success">{isSuccess?.message}</div>

          <Button
            appearance="primary"
            block
            color="green"
            onClick={handleLoginPage}
          >
            Login
          </Button>
        </Stack>
      )}

      {!isSuccess?.message && data?.success === true ? (
        <Form fluid onSubmit={onSubmit}>
          {errors.password && (
            <div className="alert alert-danger">{errors.password}</div>
          )}

          {state.successMessage && (
            <div className="alert alert-success">{state.successMessage}</div>
          )}
          <Form.Group>
            <Form.ControlLabel>
              New Password<span className="text-danger">*</span>
            </Form.ControlLabel>
            <div className="position-relative">
              <Form.Control
                name="Password"
                type="password"
                value={state.password}
                onChange={(value) => setState({ ...state, password: value })}
                required
                className="theme-ip pe-5"
              />
              <i
                id="passwordIcon"
                onClick={(e) => handleClick(e?.target, "Password")}
                className="fa-solid fa-eye-slash icon-after cursor-pointer"
              ></i>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>
              Confirm Password<span className="text-danger">*</span>
            </Form.ControlLabel>
            <div className="position-relative">
              <Form.Control
                name="Confirm Password"
                type="password"
                value={state.password_confirmation}
                onChange={(value) =>
                  setState({ ...state, password_confirmation: value })
                }
                required
                className="theme-ip pe-5"
              />
              <i
                id="confirmPasswordIcon"
                onClick={(e) => handleClick(e?.target, "Confirm Password")}
                className="fa-solid fa-eye-slash icon-after cursor-pointer"
              ></i>
            </div>
          </Form.Group>

          <Button appearance="primary" block color="green" type="submit">
            Reset Password
          </Button>
        </Form>
      ) : null}
    </div>
  );
};

export default ResetPassword;
