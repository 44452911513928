export const textLabels = {
  English: {
    key: "En",
    title: "English Title",
    message: "English Message",
  },
  Pashto: {
    key: "Ps",
    title: "Pashto Title",
    message: "Pashto Message",
  },
  Dari: {
    key: "Fa",
    title: "Dari Title",
    message: "Dari Message",
  },
};
