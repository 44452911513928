import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Form, Stack, Checkbox } from "rsuite";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import { Link } from "react-router-dom";

const Login = () => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const eyeIcon = document.querySelector("#eyeIcon");
    const passwordInput = document.querySelector('input[name="password"]');
    eyeIcon.addEventListener("click", () => {
      if (Array.from(eyeIcon.classList).includes("fa-eye-slash")) {
        eyeIcon.classList.add("fa-eye");
        eyeIcon.classList.remove("fa-eye-slash");
        passwordInput.setAttribute("type", "text");
      } else {
        eyeIcon.classList.add("fa-eye-slash");
        eyeIcon.classList.remove("fa-eye");
        passwordInput.setAttribute("type", "password");
      }
    });
  }, []);

  const handleEmailChange = (value) => {
    setEmail(value);
    if (value === "") {
      setPassword("");
    }
  };

  const onSubmit = () => {
    axios({
      method: "post",
      url: "/api/v0/guests/login",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getAuthenticityToken(),
      },
      data: {
        user: { email, password },
      },
    })
      .then((response) => {
        if (response.data["two_factor_enabled"] != null) {
          navigate("/guests/verify-2fa", {
            state: {
              email: email,
              password: password,
              two_factor_enabled: response.data["two_factor_enabled"],
              two_factor_qr_code_as_svg:
                response.data["two_factor_qr_code_as_svg"],
              two_factor_otp_secret: response.data["two_factor_otp_secret"],
            },
          });
        } else {
          window.location.reload(true);
        }
      })
      .catch((e) => setError(e.response?.data?.error));
  };

  return (
    <div className="">
      <h2 className="head-xl">Login</h2>
      <p className="grey-txt mb-4">
        Remote learning for every Afghan girl, everywhere.
      </p>
      <Form onSubmit={onSubmit} fluid>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form.Group>
          <Form.ControlLabel className="f-med ">
            Email<span className="text-danger">*</span>
          </Form.ControlLabel>
          <Form.Control
            name="email"
            type="email"
            className="theme-ip"
            value={email}
            onChange={(value) => handleEmailChange(value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-1">
          <Form.ControlLabel className="f-med ">
            Password<span className="text-danger">*</span>
          </Form.ControlLabel>
          <div className="position-relative">
            <Form.Control
              name="password"
              type="password"
              className="theme-ip pe-5"
              value={password}
              onChange={(value) => setPassword(value)}
              required
            />
            <i
              id="eyeIcon"
              className="fa-solid fa-eye-slash icon-after cursor-pointer"
            ></i>
          </div>
        </Form.Group>

        <Stack direction="row" justifyContent="end">
          <Link to={"/guests/forgot-password"} className="grey-txt">
            Forgot Password?
          </Link>
        </Stack>

        <Button
          appearance="primary"
          block
          color="green"
          type="submit"
          className="mt-3"
        >
          Login
        </Button>

        <p className="grey-txt mt-4">
          Don't have an account?{" "}
          <Link to={"/guests/register"} className="f-med">
            Sign Up
          </Link>
        </p>
      </Form>
    </div>
  );
};

export default Login;
