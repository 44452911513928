import React, { useState } from "react";
import { Button, Modal, Form as RSForm } from "rsuite";

//Form to create a new (or edit a) service number
const Form = ({ open, onClose, onConfirm, serviceNumber, errors }) => {
  const isNew = !serviceNumber?.id;
  const [identifier, setIdentifier] = useState(
    serviceNumber?.attributes?.identifier || "",
  );
  const [number, setNumber] = useState(serviceNumber?.attributes?.number || "");

  return (
    <Modal open={open} onClose={onClose} size="xs" backdrop="static">
      <Modal.Header>
        <Modal.Title>
          {isNew ? 'Add' : ' Edit'} Service Number
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isNew && (
          <div className={"rs-form-control-wrapper mb-3"}>
            <div className={"rs-form-control-label"}>Identifier<span className="text-danger">*</span></div>
            <div className={"rs-form-control-input-wrapper"}>
              <input
                type="text"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                className={"rs-input"}
              />
            </div>
            <RSForm.ErrorMessage
              show={errors?.identifier?.length > 0}
              style={{ color: "red" }}
            >
              {errors?.identifier?.join(",")}
            </RSForm.ErrorMessage>
          </div>
        )}
        <div className={"rs-form-control-wrapper mb-3"}>
          <div className={"rs-form-control-label"}>Number</div>
          <div className={"rs-form-control-input-wrapper"}>
            <input
              type="text"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              className={"rs-input"}
            />
          </div>
          <RSForm.ErrorMessage
            show={errors?.number?.length > 0}
            style={{ color: "red" }}
          >
            {errors?.number?.join(",")}
          </RSForm.ErrorMessage>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="subtle">
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm({ identifier, number })}
          color="green"
          appearance="primary"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Form;
