import React from "react";
import Application from "../src/chatac/application";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "rsuite/dist/rsuite-no-reset.min.css";

document.addEventListener("DOMContentLoaded", () => {
  const root = createRoot(
    document.body.appendChild(document.createElement("div")),
  );
  root.render(<Application />);
  document.body.appendChild(document.createElement("div", { id: "modal" }));
  // Modal.setAppElement('#modal');
});
