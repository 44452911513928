import React, { useEffect, useState } from "react";
import Button from "rsuite/Button";
import Chart from "./chart";
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import classNames from "classnames";
import ChartSettings from "./chartSettings";
import NoDataFound from "../../../components/bar_chart/noDataFound";

const mergeArrays = (arrays) => {
  const result = {};
  arrays.forEach((array, index) => {
    if (array === undefined) return;
    const key = `chart-${index + 1}`;
    array.forEach((element) => {
      const { name, value } = element;
      if (!result[name]) {
        result[name] = {};
      }
      result[name][key] = value;
    });
  });

  return Object.keys(result)
    .sort((a, b) => new Date(a) - new Date(b))
    .map((key) => ({
      ...result[key],
      name: key,
    }));
};

const Index = ({ updateChartList, chartDetails }) => {
  const [dataArray, setDataArray] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [chartColors, setChartColors] = useState([]);
  const [chartsCount, setChartsCount] = useState(1);
  const [chartIndex, setChartIndex] = useState(0);
  const [chartName, setChartName] = useState([]);
  const [axisContent, setAxisContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const updateData = (index, data) => {
    const array = [...dataArray];
    array[index] = data;
    setDataArray(array);
  };

  const updateChartName = (index, name) => {
    if (name === undefined) return;
    const array = [...chartName];
    array[index] = name;
    setChartName(array);
    setIsLoading(false);
    updateChartList({
      chartName: array,
      chartColors: chartColors,
      dataArray: dataArray,
      formattedData: formattedData,
      chartsCount: chartsCount,
      chartIndex: chartIndex,
    });
  };

  const updateAxisContentData = (groupBy) => {
    const axisContentData = [
      { value: groupBy === "month" ? "Months" : "Days" },
      { value: "Number of student(s)" },
    ];
    setAxisContent(axisContentData);
  };

  useEffect(() => {
    setFormattedData(mergeArrays(dataArray));
  }, [dataArray]);

  useEffect(() => {
    if (Object.keys(chartDetails).length !== 0 && chartName.length === 0) {
      setChartsCount(chartDetails["chartsCount"]);
      setChartName(chartDetails["chartName"]);
      setChartColors(chartDetails["chartColors"]);
      setDataArray(chartDetails["dataArray"]);
      setFormattedData(chartDetails["formattedData"]);
      setChartIndex(chartDetails["chartIndex"]);
    }
  });

  const updateColor = (index, color) => {
    const array = [...chartColors];
    array[index] = color;
    setChartColors(array);
  };

  const deleteChart = (index) => {
    const array = [...dataArray];
    const chartColorsArray = [...chartColors];
    const chartNameArray = [...chartName];
    array.splice(index, 1);
    chartColorsArray.splice(index, 1);
    chartNameArray.splice(index, 1);

    setDataArray(array);
    setChartColors(chartColorsArray);
    setChartName(chartNameArray);
    setChartsCount(chartsCount - 1);
    setChartIndex(0);
  };

  const addChart = () => {
    setIsLoading(true);
    setChartsCount(chartsCount + 1);
  };

  return (
    <div>
      {Array.from({ length: chartsCount }, (_, index) => (
        <div key={index} className={chartIndex === index ? "" : "d-none"}>
          <Chart
            updateColor={(color) => updateColor(index, color)}
            updateData={(data) => updateData(index, data)}
            updateChartName={(name) => updateChartName(index, name)}
            updateAxisContentData={(groupBy) => updateAxisContentData(groupBy)}
          />
        </div>
      ))}

      <div className="row">
        <div className="col-lg-5">
          <div style={{ position: "relative" }}>
            <div className="d-flex align-items-center flex-wrap">
              <h2 className="head-sm">Chart Settings</h2>
              <div
                style={{
                  position: "relative",
                  float: "left",
                  margin: "3px",
                }}
              >
                <Button
                  disabled={chartName.length >= 10 || isLoading}
                  onClick={() => addChart()}
                  className="ms-2"
                  appearance="link"
                >
                  <i className="fa fa-plus me-2"></i>
                  Add New Label
                </Button>
              </div>
            </div>
            {Array.from(
              { length: chartsCount },
              (_, index) =>
                chartName[index] !== undefined && (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      float: "left",
                    }}
                    className="btn-wrap-rm me-2 mb-2"
                  >
                    <Button
                      onClick={() => {
                        setChartIndex(index);
                      }}
                      style={{
                        color: chartColors[index],
                        textShadow:
                          chartColors[index] === "#ffffff"
                            ? "0 0 25px black"
                            : "none",
                      }}
                      appearance="ghost"
                      className={classNames(
                        {
                          "theme-btn alter active": chartIndex === index,
                        },
                        "faded theme-btn alter",
                      )}
                    >
                      {chartName[index]}
                    </Button>
                    {chartsCount > 1 && (
                      <Button
                        color="red"
                        className="cross-icon"
                        appearance="primary"
                        size="xs"
                        onClick={() => deleteChart(index)}
                      >
                        x
                      </Button>
                    )}
                  </div>
                ),
            )}
          </div>
        </div>
        {Array.from({ length: chartsCount }, (_, index) => (
          <div key={index} className={chartIndex === index ? "" : "d-none"}>
            <ChartSettings
              updateColor={(color) => updateColor(index, color)}
              updateData={(data) => updateData(index, data)}
              updateChartName={(name) => updateChartName(index, name)}
              updateAxisContentData={(groupBy) =>
                updateAxisContentData(groupBy)
              }
            />
          </div>
        ))}
        <div className="col-lg-12">
          <div
            className="justify-content-center align-items-center p-5 mb-5"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            {formattedData.length > 0 ? (
              <ResponsiveContainer width="100%" height="90%">
                <RechartsBarChart
                  width={500}
                  height={300}
                  data={formattedData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    label={{
                      position: "bottom",
                      offset: "30",
                      dy: 12,
                      ...axisContent[0],
                    }}
                    angle={-45}
                    textAnchor="end"
                    tick={{ fontSize: 12 }}
                    interval={0}
                  />
                  <YAxis
                    allowDecimals={false}
                    padding={{ top: 50 }}
                    label={{
                      angle: -90,
                      position: "left",
                      ...axisContent[1],
                    }}
                  />
                  <Legend verticalAlign="top" height={40} />
                  <Tooltip />
                  {Array.from({ length: chartsCount }, (_, index) => (
                    <Bar
                      key={`chart-${index + 1}`}
                      dataKey={`chart-${index + 1}`}
                      fill={chartColors[index]}
                      name={chartName[index]}
                    />
                  ))}
                </RechartsBarChart>
              </ResponsiveContainer>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
