import React, { Fragment, useEffect, useReducer } from "react";
import useSWR from "swr";
import { fetcher } from "../../../utils/fetcher";
import Spinner from "../../../components/spinner";
import SelectFilter from "../../../components/select";
import { isAdmin } from "../../../utils/state";
import { format_date_in_time_zone } from "../../../utils/date_formatter";
import {
  initalState,
  reducer,
  ALL_COURSES,
  ALL_SERVICE_NUMBERS,
  ALL_GRADES,
  ALL_SCHOOL_LEVELS,
  COMPLETED,
  GROUP_BY_LIST,
  ALL_TEACHERS,
} from "./reducer";
import { generateXLSXUrl } from "../../../utils/xlsx";
import Filter from "./filter";
import { ordinalize } from "../../../utils/ordinalize.js";

const courseOptionFormatter = (course) => ({
  value: course["id"],
  label: course["attributes"]["title"],
});

const serviceNumberOptionFormatter = (serviceNumber) => ({
  value: serviceNumber["id"],
  label: serviceNumber["attributes"]["number"],
});

const gradeOptionFormatter = (grade) => ({
  value: grade["id"],
  label: ordinalize(grade["attributes"]["name"]),
});

const schoolLevelOptionFormatter = (school_level) => ({
  value: school_level["id"],
  label: school_level["attributes"]["name"],
});

const Chart = ({
  updateColor,
  updateData,
  updateChartName,
  updateAxisContentData,
}) => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const { groupBy, completionStatus, certificateGenerated, color, chartName } =
    state;

  const { data: allCourses } = useSWR(`/api/v0/courses`, fetcher);
  const { data: allServiceNumbers } = useSWR(
    `/api/v0/service_numbers`,
    isAdmin() ? fetcher : null,
  );
  const { data: allGrades } = useSWR(`/api/v0/grades?type=numbers`, fetcher);
  const { data: allSchoolLevels } = useSWR(
    `/api/v0/grades?type=school_levels`,
    fetcher,
  );

  const url = `/api/v0/analytics/enrollments`;
  const { data, isLoading } = useSWR(
    { url, params: payload({ ...state }) },
    fetcher,
  );

  const { data: allTeachers } = useSWR(
    `/api/v0/users?role=teacher`,
    isAdmin() ? fetcher : null,
  );

  useEffect(() => {
    if (!data) return;
    const formattedData = Object.keys(data)?.map((key) => {
      const [year, month, day] = JSON.parse(key);
      const name =
        groupBy === "month" ? `${year}-${month}` : `${year}-${month}-${day}`;
      return { name, value: data[key] };
    });

    formattedData.sort((a, b) => {
      return new Date(a.name) - new Date(b.name);
    });
    dispatch({ type: "setFormattedData", payload: formattedData });
    updateData(formattedData);
  }, [data]);

  useEffect(() => {
    if (!allCourses) return;
    const options = allCourses["data"]?.map(courseOptionFormatter);
    dispatch({
      type: "setCourseOptions",
      payload: [...ALL_COURSES, ...options],
    });
  }, [allCourses]);

  useEffect(() => {
    if (!isAdmin()) return;
    if (!allServiceNumbers) return;
    const options = allServiceNumbers["data"]
      ?.filter((item) => item.attributes.number)
      ?.map(serviceNumberOptionFormatter);
    dispatch({
      type: "setServiceNumberOptions",
      payload: [...ALL_SERVICE_NUMBERS, ...options],
    });
  }, [allServiceNumbers]);

  useEffect(() => {
    if (!allGrades) return;
    const options = allGrades["data"]?.map(gradeOptionFormatter);
    dispatch({ type: "setGradeOptions", payload: [...ALL_GRADES, ...options] });
  }, [allGrades]);

  useEffect(() => {
    if (!allSchoolLevels) return;
    const options = allSchoolLevels["data"]?.map(schoolLevelOptionFormatter);
    dispatch({
      type: "setSchoolLevelOptions",
      payload: [...ALL_SCHOOL_LEVELS, ...options],
    });
  }, [allSchoolLevels]);

  useEffect(() => {
    if (!isAdmin()) return;
    if (!allTeachers) return;
    const options = allTeachers["data"]?.map((teacher) => ({
      value: teacher["id"],
      label: teacher["attributes"]["email"],
    }));
    dispatch({
      type: "setTeacherOptions",
      payload: [...ALL_TEACHERS, ...options],
    });
  }, [allTeachers]);

  useEffect(() => {
    if (completionStatus === COMPLETED) return;
    dispatch({ type: "setCompletionStartDate", payload: null });
    dispatch({ type: "setCompletionEndDate", payload: null });
  }, [completionStatus]);

  useEffect(() => {
    dispatch({
      type: "setcertificateGenerated",
      payload: certificateGenerated,
    });
  }, [certificateGenerated]);

  useEffect(() => {
    dispatch({
      type: "setChartName",
      payload: `chart ${Math.ceil(Math.random() * 1000)}`,
    });
  }, []);

  useEffect(() => {
    updateColor(color);
  }, [color]);

  useEffect(() => {
    if (chartName === "chart name") return;
    updateChartName(chartName);
  }, [chartName]);

  useEffect(() => {
    if (!groupBy) return;
    updateAxisContentData(groupBy);
  }, [groupBy]);

  if (isLoading) return <Spinner />;

  return (
    <Fragment>
      <div className="d-flex justify-content-end mb-3">
        <fieldset className="me-3 d-flex align-items-center">
          <label className="grey-txt form-group me-2" htmlFor="groupBy">
            Group By
          </label>
          <SelectFilter
            name="groupBy"
            value={groupBy}
            onChange={(e) =>
              dispatch({ type: "setGroupBy", payload: e.target.value })
            }
            options={GROUP_BY_LIST}
          />
        </fieldset>
        <a
          className="theme-btn"
          href={generateXLSXUrl(url, payload({ ...state }))}
        >
          <i className="fa fa-download me-2"></i>
          Download as XLSX
        </a>
      </div>
      <Filter {...{ state, dispatch }} />
    </Fragment>
  );
};

const payload = ({
  groupBy,
  startDate,
  endDate,
  country,
  countryState,
  courseId,
  serviceNumberId,
  gradeId,
  schoolLevelId,
  certificateGenerated,
  completionStatus,
  completionStartDate,
  completionEndDate,
  teacherId,
  attemptNumber,
}) => ({
  group_by: groupBy,
  course_id: courseId,
  teacher_id: teacherId,
  service_number_id: serviceNumberId,
  start_date: startDate ? format_date_in_time_zone(startDate) : null,
  end_date: endDate ? format_date_in_time_zone(endDate) : null,
  country: country,
  country_state: countryState,
  grade_id: gradeId || schoolLevelId,
  certificate_generated: certificateGenerated,
  completion_status: completionStatus,
  completion_start_date: completionStartDate
    ? format_date_in_time_zone(completionStartDate)
    : null,
  completion_end_date: completionEndDate
    ? format_date_in_time_zone(completionEndDate)
    : null,
  number: attemptNumber,
});

export default Chart;
