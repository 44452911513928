import React, { useReducer, Fragment, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, InputPicker, Table, Pagination, DateRangePicker, CheckPicker } from "rsuite";
import useSWR from "swr";
import {
  format_start_of_day_in_time_zone,
  format_end_of_day_in_time_zone,
} from "../../../utils/date_formatter";
import { fetcher } from "../../../utils/fetcher";
import { AFGSTATES, CERTIFICATE_ISSUED_OPTIONS, COUNTRIES, GENDER_OPTIONS, STUDENT_AGE_GROUPS } from "../../../utils/constants";
import { isToday, isLastWeek, isYesterday } from "../../../utils/helpers";

const { Column, HeaderCell, Cell } = Table;

const initialState = {
  page: 1,
  limit: 10,
  age: null,
  gender: null,
  serviceNumberId: null,
  gradeId: null,
  enrollmentStartDate: null,
  enrollmentEndDate: null,
  certificateGeneratedOptions: CERTIFICATE_ISSUED_OPTIONS,
  certificateGenerated: null,
  country: null,
  provinceIds: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setPage":
      return { ...state, page: action.payload };
    case "setLimit":
      return { ...state, limit: action.payload, page: 1 };
    case "setAge":
      return { ...state, age: action.payload, page: 1 };
    case "setGender":
      return { ...state, gender: action.payload, page: 1 };
    case "setServiceNumberId":
      return { ...state, serviceNumberId: action.payload, page: 1 };
    case "setEnrollmentStartDate":
      return { ...state, enrollmentStartDate: action.payload, page: 1 };
    case "setEnrollmentEndDate":
      return { ...state, enrollmentEndDate: action.payload, page: 1 };
    case "setGradeId":
      return { ...state, gradeId: action.payload, page: 1 };
    case "setCertificateGenerated":
      return { ...state, certificateGenerated: action.payload, page: 1 };
    case "setCountry":
      return { ...state, country: action.payload, page: 1 };
    case "setProvinceIds":
      return { ...state, provinceIds: action.payload, page: 1 };
    default:
      return state;
  }
};

const Students = () => {
  const { id: courseId } = useParams();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { page, limit } = state;

  const setPage = (page) => dispatch({ type: "setPage", payload: page });
  const setLimit = (limit) => dispatch({ type: "setLimit", payload: limit });

  const { data, isLoading } = useSWR(
    {
      url: `/api/v0/students`,
      params: payload({ courseId, ...state }),
    },
    fetcher,
  );
  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, data?.total);

  return (
    <Fragment>
      <Filters state={state} dispatch={dispatch} />
      <div className="mx-3">
        <Table
          data={data?.data || []}
          loading={isLoading}
          height={450}
          width={"100wv"}
          onRowClick={(data) => navigate(`/students/${data.id}/details`)}
        >
          <Column width={200} flexGrow={2}>
            <HeaderCell>Full Name</HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <span className="text-capitalize">{rowData.attributes.fullname}</span>
                );
              }}
            </Cell>
          </Column>
          <Column width={100} flexGrow={2} align="center">
            <HeaderCell>Age</HeaderCell>
            <Cell dataKey="attributes.age" />
          </Column>
          <Column width={100} flexGrow={2} align="center">
            <HeaderCell>Gender</HeaderCell>
            <Cell className="text-capitalize" dataKey="attributes.gender" />
          </Column>
          <Column width={100} flexGrow={2} align="center">
            <HeaderCell>Service Number</HeaderCell>
            <Cell dataKey="attributes.service_number" />
          </Column>
          <Column width={100} flexGrow={2} align="center" >
            <HeaderCell>Country</HeaderCell>
            <Cell dataKey="attributes.country" />
          </Column>
          <Column width={100} flexGrow={2} align="center">
            <HeaderCell>Province</HeaderCell>
            <Cell dataKey="attributes.country_state" />
          </Column>
          <Column width={100} flexGrow={2} align="center"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <HeaderCell>Actions</HeaderCell>
            <Cell style={{ padding: '6px' }}>
              {(rowData) => {
                return (
                  <div className="light-txt px-2 py-2">
                    <i className="fa-solid fa-eye me-2" onClick={() => navigate(`/students/${rowData.id}/details`)}></i>
                  </div>
                );
              }}
            </Cell>
          </Column>
        </Table>
        {((data?.data || []).length >= 10 || page > 1) && (
          <div className="d-flex px-3 justify-content-between align-items-center">
            <div className="grey-txt mt-2 f-14">
              {startIndex + 1} to {(endIndex && endIndex != 0) ? endIndex : ''} of {data?.total}
            </div>
            <div className="pagination-outer">
              <Pagination
                prev
                next={!isLoading}
                ellipsis
                layout={["limit", "|", "pager"]}
                size="sm"
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={setLimit}
                limit={limit}
                limitOptions={[10, 30, 50]}
                total={data?.total}
                maxButtons={10}
              />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const Filters = ({ state, dispatch }) => {
  const { data: serviceNumbers } = useSWR(
    { url: "/api/v0/service_numbers" },
    fetcher,
  );

  const renderValue = (value) => {
    if (isToday(value) || isYesterday(value) || isLastWeek(value)) {
      if (!isLastWeek(value)) {
        return [
          isToday(value) ? 'Today' : isYesterday(value) ? 'Yesterday' : ''
        ]
      }
    }
    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return `${formatter.format(value[0])} to ${formatter.format(value[1])}`;
  };

  const handleOk = (value) => {
    dispatch({ type: "setEnrollmentStartDate", payload: format_start_of_day_in_time_zone(value[0]) })
    dispatch({ type: "setEnrollmentEndDate", payload: format_end_of_day_in_time_zone(value[1]) })
  };

  const handleClean = () => {
    dispatch({ type: "setEnrollmentStartDate", payload: null })
    dispatch({ type: "setEnrollmentEndDate", payload: null })
  };

  useEffect(() => {
    state?.country !== "Afghanistan" && (dispatch({ type: "setProvinceIds", payload: [] }))
  }, [state?.country]);

  return (
    <>
      <Form layout="inline" className="mb-0">
        <Form.Group>
          <Form.Control
            name="age"
            accepter={InputPicker}
            data={STUDENT_AGE_GROUPS}
            value={state.age}
            onChange={(value) => dispatch({ type: "setAge", payload: value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            name="certificateGenerated"
            accepter={InputPicker}
            data={state?.certificateGeneratedOptions}
            value={state?.certificateGenerated}
            onChange={(value) => dispatch({ type: "setCertificateGenerated", payload: value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            name="gender"
            accepter={InputPicker}
            data={[
              {
                label: "All Genders",
                value: null,
              },
              ...GENDER_OPTIONS,
            ]}
            value={state.gender}
            onChange={(value) => dispatch({ type: "setGender", payload: value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            name="serviceNumberId"
            accepter={InputPicker}
            data={[
              {
                label: "All Service Numbers",
                value: null,
              },
              ...(serviceNumbers?.data || [])
                .filter((item) => item.attributes.number)
                .map((item) => ({
                  label: item.attributes.number,
                  value: item.id,
                })),
            ]}
            value={state.serviceNumberId}
            onChange={(value) =>
              dispatch({ type: "setServiceNumberId", payload: value })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            name="country"
            accepter={InputPicker}
            data={[
              {
                label: "All Locations",
                value: null,
              },
              ...COUNTRIES
            ]}
            value={state.country}
            onChange={(value) => dispatch({ type: "setCountry", payload: value })}
            placeholder="Select Location"
          />
        </Form.Group>
        {state?.country === "Afghanistan" && (
          <Form.Group>
            <Form.Control
              name="provinceIds"
              countable={false}
              accepter={CheckPicker}
              data={AFGSTATES}
              value={state.provinceIds}
              onChange={(value) => dispatch({ type: "setProvinceIds", payload: value })}
              placeholder="Select Province"
            />
          </Form.Group>
        )}
        <Form.Group>
          <Form.Control
            name="enrollmentDateRange"
            accepter={DateRangePicker}
            editable={false}
            placement="bottomEnd"
            showOneCalendar
            className="date-filter"
            renderValue={renderValue}
            placeholder="Select Enrollment Date"
            onOk={handleOk}
            onClean={handleClean}
            onChange={(date) => {
              if (date) {
                dispatch({ type: "setEnrollmentStartDate", payload: date[0] });
                dispatch({ type: "setEnrollmentEndDate", payload: date[1] })
              }
            }
            }
          />
        </Form.Group>
      </Form>
    </>
  );
};

const payload = ({
  courseId,
  age,
  gender,
  certificateGenerated,
  serviceNumberId,
  enrollmentStartDate,
  enrollmentEndDate,
  gradeId,
  country,
  provinceIds,
  page,
  limit,
}) => ({
  course_id: courseId,
  age,
  gender,
  certificate_generated: certificateGenerated,
  grade_id: gradeId,
  service_number_id: serviceNumberId,
  country,
  country_state: provinceIds,
  enrollment_start_date: format_start_of_day_in_time_zone(enrollmentStartDate),
  enrollment_end_date: format_end_of_day_in_time_zone(enrollmentEndDate),
  page,
  limit,
});

export default Students;
