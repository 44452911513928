import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../../utils/fetcher";
import Spinner from "../../../components/spinner";
import Table from "../../../components/table";
import { ordinalize } from "../../../utils/ordinalize";
import { format_date_in_time_zone } from "../../../utils/date_formatter";
import { Button } from "rsuite";
import EditForm from "../form";
import axios from "axios";
import { getAuthenticityToken } from "../../../utils/authenticity_token";

const Summary = () => {
  const { id } = useParams();
  const {
    data = {},
    error,
    isLoading,
  } = useSWR(`/api/v0/courses/${id}`, fetcher);
  const [formattedData, setFormattedData] = useState({});
  const [courseToEdit, setCourseToEdit] = useState(null);
  const [editErrors, setEditErrors] = useState({});
  useEffect(() => {
    if (isLoading) return;
    const body = [
      {
        values: [
          {
            className: "text-left",
            value: "Type",
          },
          {
            value: data["data"]["attributes"]["type"],
          },
        ],
      },
      {
        values: [
          {
            className: "text-left",
            value: "Title",
          },
          {
            value: data["data"]["attributes"]["title"],
          },
        ],
      },
      {
        values: [
          {
            className: "text-left",
            value: "Description",
          },
          {
            value: data["data"]["attributes"]["description"],
          },
        ],
      },
      {
        values: [
          {
            className: "text-left",
            value: "Course Grade",
          },
          {
            value: data["data"]["attributes"]["grades"]?.map(function(i) {return ordinalize(i)})?.join(', ')
          },
        ],
      },
      {
        values: [
          {
            className: "text-left",
            value: "Other Type",
          },
          {
            value: data["data"]["attributes"]["school_levels"]?.join(', '),
          },
        ],
      },
      {
        values: [
          {
            className: "text-left",
            value: "Language",
          },
          {
            value: data["data"]["attributes"]["language"],
          },
        ],
      },
      {
        values: [
          {
            className: "text-left",
            value: "Send certificate?",
          },
          {
            value: data["data"]["attributes"]["send_cert"] ? 'Yes' : 'No',
          },
        ],
      },
      {
        values: [
          {
            className: "text-left",
            value: "Lessons Per Day",
          },
          {
            value: data["data"]["attributes"]["lessons_per_day"],
          },
        ],
      },
      {
        values: [
          {
            className: "text-left",
            value: "Published",
          },
          {
            value: data["data"]["attributes"]["published"] ? "Yes" : "No",
          },
        ],
      },
      {
        values: [
          {
            className: "text-center fw-bolder",
            value: "Published At",
          },
          {
            value: format_date_in_time_zone(data["data"]["attributes"]["published_at"]),
          },
        ],
      },
    ];
    setFormattedData({ header: [], body });
  }, [data, isLoading]);

  if (isLoading) return <Spinner />;

  return <div className="summary-table">
    <>
      {!!courseToEdit ?
        (
          <EditForm
            open={!!courseToEdit}
            onClose={() => {
              setCourseToEdit(null);
              setEditErrors(null);
            }}
            onConfirm={(data) => {
              const { id } = courseToEdit;
              const url = id ? `/api/v0/courses/${id}` : "/api/v0/courses";
              const method = id ? "PUT" : "POST";
              axios({
                method,
                url,
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": getAuthenticityToken(),
                },
                data,
              })
                .then((res) => {
                  window.location.reload();
                })
                .catch((err) => {
                  setEditErrors(err.response.data.errors);
                });
            }}
            course={courseToEdit}
            errors={editErrors}
          />
        ) :
        <>
          <div className="px-4 mb-2">
            <Button
              onClick={() => setCourseToEdit(data?.data)}
              color="green"
              appearance="ghost"
              >
              <i className="fa-solid fa-pen me-2"></i> Edit
            </Button>
          </div>
          <Table data={formattedData} />
        </>
      }
    </>
  </div>;
};

export default Summary;
