import React, { useState } from "react";
import { Form, Button, Modal, Stack } from "rsuite";

const Verify2FAModal = ({
  open,
  onClose,
  two_factor_enabled,
  qr_code_as_svg,
  otp_secret,
  onSubmit,
  error,
}) => {
  const [otp_attempt, setOtpAttempt] = useState("");

  const handleOtpAttemptChange = (value) => {
    setOtpAttempt(value);
  };

  return (
    <Modal open={open} onClose={onClose} size="xs" backdrop="static">
      <Modal.Title>
        {two_factor_enabled || "Setup "}Two-Factor Authentication
      </Modal.Title>
      <Modal.Header />
      {two_factor_enabled || (
        <Modal.Dialog>
          <p>
            Scan this QR Code with the two-factor authentication app on your
            phone
          </p>
          <hr />
          <img
            src={`data:image/svg+xml;utf8,${encodeURIComponent(qr_code_as_svg)}`}
            alt="Two-Factor QR Code"
          />
          <br />
          <br />
          <p>Or use this text code instead</p>
          <strong>{otp_secret}</strong>
        </Modal.Dialog>
      )}
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form fluid>
          <Form.Group>
            <Form.ControlLabel>OTP</Form.ControlLabel>
            <Form.Control
              name="otp_attempt"
              type="number"
              value={otp_attempt}
              onChange={(value) => handleOtpAttemptChange(value)}
              required
            />
          </Form.Group>
          <Stack direction="row" justifyContent="space-between">
            <Button
              appearance="primary"
              color="green"
              onClick={() => onSubmit(otp_attempt)}
            >
              Verify OTP
            </Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Verify2FAModal;
