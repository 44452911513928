import React from "react";
import styled from "styled-components";

const Title = styled.h1``;
const PageTitle = ({ title }) => {
  return (
    <div>
      <Title className="head-lg">{title}</Title>
    </div>
  );
};

export default PageTitle;
