export const ordinalize = (num) => {
  let n = parseInt(num);
  let ord = "th";

  if (n % 10 == 1 && n % 100 != 11) {
    ord = "st";
  } else if (n % 10 == 2 && n % 100 != 12) {
    ord = "nd";
  } else if (n % 10 == 3 && n % 100 != 13) {
    ord = "rd";
  } else if (num == "Primary" || num == "Secondary" || num == "Upper") {
    ord = "";
  }

  return num ? num + ord : null;
};
