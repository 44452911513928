import React, { Fragment, useEffect, useState } from "react";
import SelectFilter from "../../../components/select";
import { isAdmin } from "../../../utils/state";
import { CheckPicker, Checkbox, DatePicker, SelectPicker } from "rsuite";
import {
  completionStatusOptions,
  COMPLETED,
  ATTEMPT_NUMBER,
  COUNTRIES,
  AFGSTATES,
} from "./reducer";

const Filter = ({ state, dispatch }) => {
  const {
    courseOptions,
    serviceNumberOptions,
    gradeOptions,
    schoolLevelOptions,
    certificateGeneratedOptions,
    startDate,
    endDate,
    country,
    countryState,
    courseId,
    teacherId,
    teacherOptions,
    serviceNumberId,
    gradeId,
    schoolLevelId,
    certificateGenerated,
    completionStatus,
    completionStartDate,
    completionEndDate,
    attemptNumber,
    validCertificateGeneratedOptions,
  } = state;

  const allStates = AFGSTATES.map((item) => item.value);
  const [states, setStates] = useState(countryState);

  useEffect(() => {
    const newOptions = certificateGeneratedOptions.map((option) => {
      option.disabled =
        option.value === "true" && completionStatus === "non_completed";
      return option;
    });
    dispatch({
      type: "setValidCertificateGeneratedOptions",
      payload: newOptions,
    });
  }, [completionStatus]);

  useEffect(() => {
    country !== "Afghanistan" &&
      (dispatch({ type: "setCountryState", payload: [] }), setStates([]));
  }, [country]);

  return (
    <div className="mb-">
      <fieldset>
        <div className="row">
          <div className="col-md-3">
            <SelectFilter
              wrapperClassnames={["select-outer"]}
              value={courseId || ""}
              onChange={(e) =>
                dispatch({ type: "setCourseId", payload: e.target.value })
              }
              options={courseOptions}
            />
          </div>
          <div className="col-md-3">
            <SelectFilter
              wrapperClassnames={["select-outer"]}
              value={gradeId || ""}
              onChange={(e) =>
                dispatch({ type: "setGradeId", payload: e.target.value })
              }
              options={gradeOptions}
            />
          </div>
          <div className="col-md-3">
            <SelectFilter
              wrapperClassnames={["select-outer"]}
              value={schoolLevelId || ""}
              onChange={(e) =>
                dispatch({ type: "setSchoolLevelId", payload: e.target.value })
              }
              options={schoolLevelOptions}
            />
          </div>
          {isAdmin() && (
            <Fragment>
              <div className="col-md-3">
                <SelectFilter
                  wrapperClassnames={["select-outer"]}
                  value={serviceNumberId || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "setServiceNumberId",
                      payload: e.target.value,
                    })
                  }
                  options={serviceNumberOptions}
                />
              </div>
              <div className="col-md-3">
                <SelectFilter
                  wrapperClassnames={["select-outer"]}
                  value={teacherId || ""}
                  onChange={(e) =>
                    dispatch({ type: "setTeacherId", payload: e.target.value })
                  }
                  options={teacherOptions}
                />
              </div>
            </Fragment>
          )}
          <div className="col-md-3">
            <SelectFilter
              wrapperClassnames={["select-outer"]}
              value={attemptNumber || ""}
              onChange={(e) =>
                dispatch({
                  type: "setAttemptNumber",
                  payload: e.target.value,
                })
              }
              options={ATTEMPT_NUMBER}
            />
          </div>
          <div className="col-md-3">
            <SelectFilter
              wrapperClassnames={["select-outer"]}
              value={completionStatus || ""}
              onChange={(e) =>
                dispatch({
                  type: "setCompletionStatus",
                  payload: e.target.value,
                })
              }
              options={completionStatusOptions}
            />
          </div>
          <div className="col-md-3">
            <SelectFilter
              wrapperClassnames={["select-outer"]}
              value={certificateGenerated || ""}
              onChange={(e) =>
                dispatch({
                  type: "setCertificateGenerated",
                  payload: e.target.value,
                })
              }
              options={validCertificateGeneratedOptions}
            />
          </div>
          {completionStatus === COMPLETED && (
            <div className="mb-2">
              <h3 className="head-sm">Completion date:</h3>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-3">
                    <DatePicker
                      size="lg"
                      block
                      placeholder="Start Date"
                      shouldDisableDate={(date) =>
                        date > (completionEndDate || new Date())
                      }
                      onChange={(date) =>
                        dispatch({
                          type: "setCompletionStartDate",
                          payload: date,
                        })
                      }
                      value={
                        completionStartDate ? new Date(completionStartDate) : ""
                      }
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <DatePicker
                      size="lg"
                      block
                      placeholder="End Date"
                      className="ms-2 me-2"
                      shouldDisableDate={(date) => date < completionStartDate}
                      onChange={(date) =>
                        dispatch({
                          type: "setCompletionEndDate",
                          payload: date,
                        })
                      }
                      value={
                        completionEndDate ? new Date(completionEndDate) : ""
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-3">
            <div className="form-group">
              <label className="f-med mb-1" htmlFor="startDate">
                Attempt Start Date
              </label>
              <DatePicker
                name="startDate"
                size="lg"
                block
                className=""
                placeholder="Start Date"
                format="MM/dd/yyyy"
                shouldDisableDate={(date) => date > (endDate || new Date())}
                onChange={(date) =>
                  dispatch({ type: "setStartDate", payload: date })
                }
                value={startDate ? new Date(startDate) : ""}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="f-med mb-1" htmlFor="endDate">
                Attempt End Date
              </label>
              <DatePicker
                name="endDate"
                size="lg"
                block
                className=""
                placeholder="End Date"
                format="MM/dd/yyyy"
                shouldDisableDate={(date) => date < (startDate || new Date())}
                onChange={(date) =>
                  dispatch({ type: "setEndDate", payload: date })
                }
                value={endDate ? new Date(endDate) : ""}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="f-med mb-1 d-block" htmlFor="location">
                Location
              </label>
              <SelectPicker
                size="lg"
                name="location"
                block
                className="w-full"
                placeholder="Select Country"
                value={country}
                onChange={(country) =>
                  dispatch({ type: "setCountry", payload: country })
                }
                data={COUNTRIES}
              ></SelectPicker>
            </div>
          </div>
          {country === "Afghanistan" && (
            <div className="col-md-3">
              <div className="form-group">
                <label className="f-med mb-1 d-block" htmlFor="province">
                  Province
                </label>
                <CheckPicker
                  className="w-100"
                  block
                  countable={false}
                  name="province"
                  value={states}
                  data={AFGSTATES}
                  style={{ width: 224 }}
                  placeholder="Select State/Province"
                  onChange={(stateName) => setStates(stateName)}
                  onExit={() =>
                    dispatch({ type: "setCountryState", payload: states })
                  }
                  onClean={() =>
                    dispatch({ type: "setCountryState", payload: [] })
                  }
                  renderExtraFooter={() => (
                    <div>
                      <Checkbox
                        checked={states?.length === allStates?.length}
                        onChange={(checked) =>
                          setStates(checked ? allStates : [])
                        }
                      >
                        Check all
                      </Checkbox>
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </fieldset>
    </div>
  );
};

export default Filter;
