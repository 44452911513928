import React, { useEffect, useState } from "react";
import Table from "../../../components/table";
import { useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import Spinner from "../../../components/spinner";
import { fetcher } from "../../../utils/fetcher";
import { ordinalize } from "../../../utils/ordinalize";

const header = [
  {
    value: "No",
  },
  {
    value: "Course Name",
  },
  {
    value: "Description",
  },
  {
    value: "Language",
  },
  {
    value: "Course Grade",
  },
  {
    value: "Actions",
  }
];

const Courses = () => {
  const { id } = useParams();
  const { data, isLoading } = useSWR(
    { url: `/api/v0/courses/`, params: { student_id: [id] } },
    fetcher,
  );
  const [formattedData, setFormattedData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoading) return;
    const body = data["data"].map((course, index) => ({
      key: course["id"],
      onClick: () => navigate(`/courses/${course["id"]}/details`),
      values: [
        {
          value: index + 1,
        },
        {
          value: course["attributes"]["title"],
        },
        {
          value: course["attributes"]["description"],
        },
        {
          value: course["attributes"]["language"],
        },
        {
          value: course["attributes"]["grades"]?.map(function (i) { return ordinalize(i) })?.join(', '),
        },
        {
          value:
            < div className="light-txt px-2 py-2" >
              <i className="fa-solid fa-eye me-2" onClick={() => navigate(`/courses/${course["id"]}/details`)}></i>
            </div >

        },
      ],
    }));
    setFormattedData({ header, body });
  }, [data]);
  if (isLoading) return <Spinner />;
  return <div className="summary-table mx-0"><Table data={formattedData} /></div>;
};

export default Courses;
