import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  DateRangePicker,
  InputNumber,
  Modal,
  Radio,
  RadioGroup,
  SelectPicker,
} from "rsuite";
const { combine, beforeToday, before } = DateRangePicker;

const RepeatForm = ({ open, onClose, onConfirm, onClear, state, dispatch }) => {
  useEffect(() => {
    if (state?.appliedSchedule?.ends == "never") {
      dispatch({ type: "setEndDate", payload: null });
    }
  }, [state?.appliedSchedule?.ends]);

  const [errors, setErrors] = useState({
    end_date: null,
  });

  const onSubmit = () => {
    if (
      state?.appliedSchedule?.ends != "never" &&
      !state?.appliedSchedule?.end_date
    ) {
      setErrors({
        ...errors,
        end_date: "Please select end date.",
      });
      return;
    }

    onConfirm();
  };

  return (
    <Modal
      open={open}
      onOpen={() => dispatch({ type: "setAppliedScheduleFilters" })}
      onClose={onClose}
      size="xs"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Repeated Occurance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"rs-form-control-wrapper mb-3"}>
          <div className={"rs-form-control-label"}>Repeat Every</div>
          <div className={"rs-form-control-input-wrapper every-row row"}>
            <div className="col-md-6 mb-md-0 mb-2">
              <InputNumber
                size="lg"
                defaultValue={1}
                value={state?.appliedSchedule?.frequency}
                min={1}
                max={365}
                scrollable={false}
                onChange={(value) =>
                  dispatch({
                    type: "setFrequency",
                    payload: Math.trunc(value),
                  })
                }
              />
            </div>
            <div className="col-md-6">
              <SelectPicker
                block
                className="w-100"
                data={[
                  { value: "day", label: "Day" },
                  { value: "week", label: "Week" },
                ]}
                onChange={(value) =>
                  dispatch({
                    type: "setDuration",
                    payload: value,
                  })
                }
                value={state?.appliedSchedule?.duration}
                name="duration"
              />
            </div>
          </div>
        </div>
        {state?.appliedSchedule?.duration !== "day" && (
          <div className={"rs-form-control-wrapper mb-3"}>
            <div className={"rs-form-control-label"}>Repeat On</div>
            <div className={"rs-form-control-input-wrapper days-row"}>
              <CheckboxGroup
                inline
                name="checkbox-group"
                appearance="picker"
                value={state?.appliedSchedule?.weekdays}
                onChange={(value) =>
                  dispatch({
                    type: "setWeekDays",
                    payload: value?.length ? value : ["1"],
                  })
                }
              >
                <Checkbox value="7">
                  <span className="day">S</span>
                </Checkbox>
                <Checkbox value="1">
                  <span className="day">M</span>
                </Checkbox>
                <Checkbox value="2">
                  <span className="day">T</span>
                </Checkbox>
                <Checkbox value="3">
                  <span className="day">W</span>
                </Checkbox>
                <Checkbox value="4">
                  <span className="day">T</span>
                </Checkbox>
                <Checkbox value="5">
                  <span className="day">F</span>
                </Checkbox>
                <Checkbox value="6">
                  <span className="day">S</span>
                </Checkbox>
              </CheckboxGroup>
            </div>
          </div>
        )}
        <div className={"rs-form-control-wrapper mb-3"}>
          <div className={"rs-form-control-label"}>Ends </div>
          <div className={"rs-form-control-input-wrapper repeat-modal"}>
            <RadioGroup
              name="radio-group-controlled"
              value={state?.appliedSchedule?.ends}
              onChange={(value) =>
                dispatch({ type: "setEnds", payload: value })
              }
            >
              <Radio value="never">Never</Radio>
              <Radio value="on" className="on-radio">
                <div className="d-flex align-items-center">
                  <div className="">On</div>
                  <div className="ms-2">
                    <DatePicker
                      format="MMMM dd, yyyy"
                      disabled={state?.appliedSchedule?.ends == "never"}
                      value={
                        state?.appliedSchedule?.end_date
                          ? new Date(state?.appliedSchedule?.end_date)
                          : null
                      }
                      onChange={(value) =>
                        dispatch(
                          { type: "setEndDate", payload: value },
                          setErrors({ ...errors, end_date: null }),
                        )
                      }
                      shouldDisableDate={combine(
                        beforeToday(),
                        before(state?.schedule?.start_date),
                      )}
                    />
                  </div>
                </div>
              </Radio>
            </RadioGroup>
            {errors?.end_date && (
              <div className="alert alert-danger">{errors?.end_date}</div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClear} appearance="subtle">
          Clear
        </Button>
        <Button onClick={() => onSubmit()} color="green" appearance="primary">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RepeatForm;
