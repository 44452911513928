import React, { useEffect, useReducer, useState } from "react";
import PageTitle from "../../components/page_title";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  Pagination,
  Form,
  SelectPicker,
  Popover,
  Dropdown,
} from "rsuite";
import axios from "axios";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import DeleteConfirmationModal from "../../components/delete_confirmation_modal";
import EditForm from "./form";
import { isAdmin, isTeacher } from "../../utils/state";
import { ordinalize } from "../../utils/ordinalize";
import "./list.css";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const newCourse = () => ({
  id: null,
  attributes: {
    title: "",
    description: "",
    published: false,
    grade_id: null,
  },
});

const initialState = {
  page: 1,
  limit: 10,
  languageId: null,
  gradeId: null,
  schoolLevelId: null,
  published: null,
  teacherId: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setPage":
      return { ...state, page: action.payload };
    case "setLimit":
      return { ...state, limit: action.payload, page: 1 };
    case "setTeacherId":
      return { ...state, teacherId: action.payload, page: 1 };
    case "setLanguageId":
      return { ...state, languageId: action.payload, page: 1 };
    case "setGradeId":
      return { ...state, gradeId: action.payload, schoolLevelId: "", page: 1 };
    case "setSchoolLevelId":
      return { ...state, schoolLevelId: action.payload, gradeId: "", page: 1 };
    case "setPublished":
      return { ...state, published: action.payload, page: 1 };
    default:
      return state;
  }
};
const List = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { page, limit } = state;
  const setPage = (page) => dispatch({ type: "setPage", payload: page });
  const setLimit = (limit) => dispatch({ type: "setLimit", payload: limit });
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [courseToEdit, setCourseToEdit] = useState(null);
  const [editErrors, setEditErrors] = useState({});

  const { data, isLoading, mutate } = useSWR(
    {
      url: "/api/v0/courses",
      params: payload({ ...state }),
    },
    fetcher,
  );


  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, data?.total);
  return (
    <div>
      {!!courseToEdit ?
        (
          <EditForm
            open={!!courseToEdit}
            onClose={() => {
              setCourseToEdit(null);
              setEditErrors(null);
            }}
            onConfirm={(data) => {
              const { id } = courseToEdit;
              const url = id ? `/api/v0/courses/${id}` : "/api/v0/courses";
              const method = id ? "PUT" : "POST";
              axios({
                method,
                url,
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": getAuthenticityToken(),
                },
                data,
              })
                .then((res) => {
                  setEditErrors({});
                  setCourseToEdit(null);
                  mutate();
                })
                .catch((err) => {
                  setEditErrors(err.response.data.errors);
                });
            }}
            course={courseToEdit}
            errors={editErrors}
          />
        ) :
        <>

          <div className="container">
            <div className='mb-3'>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <PageTitle title={"Courses"} />
                </div>
                <div className="col-md-8">
                  <div className="d-flex justify-content-end">
                    <Button
                      onClick={() => setCourseToEdit(newCourse())}
                      color="green"
                      appearance="primary"
                    >
                      <i className="fa fa-plus me-2"></i>New Course
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="shadow-sm bg-white radius-5">
              <div className="top bb px-3 pt-3 pb-2">
                <Filters {...{ state, dispatch }} />
              </div>
              <div className="inner">
                <Table
                  loading={isLoading}
                  height={500}
                  width={"100wv"}
                  data={data?.data || []}
                  onRowClick={(data) => navigate(`/courses/${data["id"]}/summary`)}
                >
                  <Column width={100}>
                    <HeaderCell>No</HeaderCell>
                    <Cell>
                      {(rowData, rowIndex) => (
                        <div>{rowIndex + 1 + (page - 1) * limit}</div>
                      )}
                    </Cell>
                  </Column>
                  <Column width={100} flexGrow={3}>
                    <HeaderCell>Title</HeaderCell>
                    <Cell dataKey="attributes.title" />
                  </Column>
                  <Column width={100} flexGrow={3}>
                    <HeaderCell>Description</HeaderCell>
                    <Cell dataKey="attributes.description" />
                  </Column>
                  <Column width={100} flexGrow={3}>
                    <HeaderCell className="text-center">Course Grade</HeaderCell>
                    <Cell className="text-center">
                      {(rowData) => {
                        return rowData["attributes"]["grades"]?.map(function (i) { return ordinalize(i) })?.join(', ');
                      }}
                    </Cell>
                  </Column>
                  <Column width={100} flexGrow={3}>
                    <HeaderCell>Other Type</HeaderCell>
                    <Cell>
                      {(rowData) => {
                        return rowData["attributes"]["school_levels"]?.join(', ');
                      }}
                    </Cell>
                  </Column>
                  <Column width={100} flexGrow={3}>
                    <HeaderCell>Language</HeaderCell>
                    <Cell dataKey="attributes.language" />
                  </Column>
                  <Column width={100} flexGrow={2}>
                    <HeaderCell>Enrolled</HeaderCell>
                    <Cell dataKey="attributes.enrolled_count" />
                  </Column>
                  <Column width={100} flexGrow={2}>
                    <HeaderCell>Certified</HeaderCell>
                    <Cell dataKey="attributes.certified_count" />
                  </Column>
                  <Column width={100} flexGrow={3}>
                    <HeaderCell>Published?</HeaderCell>
                    <Cell>
                      {(rowData) => (
                        <div>{rowData["attributes"]["published"] ? "Yes" : "No"}</div>
                      )}
                    </Cell>
                  </Column>
                  <Column width={150} fixed="right" onClick={(e) => {
                    e.stopPropagation();
                  }}>
                    <HeaderCell>Actions</HeaderCell>
                    <Cell style={{ padding: '6px' }}>
                      {(rowData) => {
                        return (
                          <div className="light-txt px-2 py-2">
                            <i className="fa-solid fa-eye me-2" onClick={() => navigate(`/courses/${rowData["id"]}/summary`)}></i>
                            <i className="fa-solid fa-pencil me-2" onClick={() => setCourseToEdit(rowData)}></i>
                            <i className="fa-solid fa-trash me-2" onClick={() => setCourseToDelete(rowData)}></i>
                          </div>
                        );
                      }}
                    </Cell>
                  </Column>
                </Table>
              </div>
            </div>

            {((data?.data || []).length >= 10 || page > 1) &&
              <div className="d-flex justify-content-between align-items-center">
                <div className="grey-txt mt-2 f-14">
                  {startIndex + 1} to {(endIndex && endIndex != 0) ? endIndex : ''} of {data?.total}
                </div>

                <div className="pagination-outer">
                  <Pagination
                    prev
                    next={!isLoading}
                    ellipsis
                    layout={["limit", "|", "pager"]}
                    size="sm"
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={setLimit}
                    limit={limit}
                    limitOptions={[10, 30, 50]}
                    total={data?.total}
                    maxButtons={10}
                  />
                </div>
              </div>
            }

            <DeleteConfirmationModal
              open={!!courseToDelete}
              onClose={() => setCourseToDelete(null)}
              onConfirm={() => {
                axios({
                  method: "DELETE",
                  url: `/api/v0/courses/${courseToDelete["id"]}`,
                  headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": getAuthenticityToken(),
                  },
                }).then((res) => {
                  mutate();
                  setCourseToDelete(null);
                });
              }}
            />
          </div>
        </>
      }
    </div>
  );
};

const Filters = ({ state, dispatch }) => {
  const { data: teachers } = useSWR("/api/v0/users", fetcher);
  const { data: languages } = useSWR("/api/v0/languages", fetcher);
  const { data: grades } = useSWR("/api/v0/grades?type=numbers", fetcher);
  const { data: school_levels } = useSWR(
    "/api/v0/grades?type=school_levels",
    fetcher,
  );
  return (
    <Form layout="inline" className="mb-0">
      {isAdmin() && (
        <Form.Group className="mb-2">
          <Form.Control
            name="teacher"
            accepter={SelectPicker}
            data={(teachers?.data || []).map((teacher) => ({
              label: teacher.attributes.email,
              value: teacher.id,
            }))}
            labelKey="label"
            valueKey="value"
            onChange={(value) =>
              dispatch({ type: "setTeacherId", payload: value })
            }
            value={state.teacherId}
            placeholder="Select Teacher"
          />
        </Form.Group>
      )}
      <Form.Group className="mb-2">
        <Form.Control
          name="language"
          accepter={SelectPicker}
          data={(languages?.data || []).map((language) => ({
            label: language.attributes.name,
            value: language.id,
          }))}
          labelKey="label"
          valueKey="value"
          onChange={(value) =>
            dispatch({ type: "setLanguageId", payload: value })
          }
          value={state.languageId}
          placeholder="Select Language"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Control
          name="grade"
          accepter={SelectPicker}
          data={(grades?.data || []).map((grade) => ({
            label: ordinalize(grade.attributes.name),
            value: grade.id,
          }))}
          labelKey="label"
          valueKey="value"
          onChange={(value) => dispatch({ type: "setGradeId", payload: value })}
          value={state.gradeId}
          placeholder="Select Course Grade"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Control
          name="school_level"
          accepter={SelectPicker}
          data={(school_levels?.data || []).map((school_level) => ({
            label: school_level.attributes.name,
            value: school_level.id,
          }))}
          labelKey="label"
          valueKey="value"
          onChange={(value) =>
            dispatch({ type: "setSchoolLevelId", payload: value })
          }
          value={state.schoolLevelId}
          placeholder="Select Other Type"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Control
          name="published"
          accepter={SelectPicker}
          data={[
            { label: "(Un)Published", value: null },
            { label: "Published", value: "true" },
            { label: "Unpublished", value: "false" },
          ]}
          labelKey="label"
          valueKey="value"
          onChange={(value) =>
            dispatch({ type: "setPublished", payload: value })
          }
          value={state.published}
          defaultValue={null}
        />
      </Form.Group>
    </Form>
  );
};

const payload = ({
  teacherId,
  languageId,
  gradeId,
  schoolLevelId,
  published,
  page,
  limit,
}) => ({
  teacher_id: teacherId,
  language_id: languageId,
  grade_id: gradeId || schoolLevelId,
  published,
  page,
  limit,
});

export default List;
