const element = document.getElementById("initial-state");

const initialState =
  element?.textContent && JSON.parse(element?.textContent || {});

export const isLoggedIn = () => !!initialState?.user?.id;
export const getUserEmail = () => initialState?.user?.email;
export const getUserName = () => initialState?.user?.name;
export const getUserId = () => initialState?.user?.id;
export const getUserRoles = () => initialState?.user?.roles || [];
export const googleClientId = () => initialState?.apis?.google_client_id;
export const googleApiKey = () => initialState?.apis?.google_api_key;
export const isAdmin = () => getUserRoles().includes("admin");
export const isTeacher = () => getUserRoles().includes("teacher");
export const isPhoneNumbersRemovingEnabled = () =>
  initialState?.features?.phone_numbers_removing_enabled === true;
export const websiteUrl = () => initialState?.env?.website_url;
