import React from "react";
import { Bar, BarChart, ResponsiveContainer } from "recharts";

export const dummyData = [
  {
    name: "Page A",
    bar1: 90,
    bar2: 24,
    bar3: 24,
  },
  {
    name: "Page B",
    bar1: 30,
    bar2: 18,
    bar3: 60,
  },
  {
    name: "Page C",
    bar1: 20,
    bar2: 45,
    bar3: 17,
  },
  {
    name: "Page D",
    bar1: 70,
    bar2: 28,
    bar3: 130,
  },
  {
    name: "Page E",
    bar1: 80,
    bar2: 48,
    bar3: 24,
  },
  {
    name: "Page F",
    bar1: 90,
    bar2: 38,
    bar3: 22,
  },
  {
    name: "Page G",
    bar1: 30,
    bar2: 43,
    bar3: 20,
  },
];

const NoDataFound = ({ height = "358" }) => {
  return (
    <>
      <ResponsiveContainer width="100%" height={358}>
        <BarChart data={dummyData}>
          <Bar dataKey="bar1" fill="#EDEDED" />
          <Bar dataKey="bar2 Issued" fill="#EDEDED" />
          <Bar dataKey="bar3" fill="#EDEDED" />
        </BarChart>
      </ResponsiveContainer>
      <p className="head-md text-center mt-3">Sorry, No data available !</p>
    </>
  );
};

export default NoDataFound;
