import React from "react";
import { Col } from "rsuite";

const Stats = ({
  statIcon,
  statCount,
  statHeading,
  statColor,
  noData,
  totalCourses,
}) => {
  noData ? (statColor = "grey") : statColor;
  return (
    <Col lg={6} sm={12} xs={24}>
      <div className="shadow-sm bg-white radius-5 p-3 d-flex align-items-center tile-h mb-md-4 mb-2">
        <div className={"icon-box me-3 " + statColor}>
          <i className={statIcon}></i>
        </div>
        <div>
          <h2 className="head-xl">
            {noData ? 0 : statCount}
            {statHeading == "Courses Published" && totalCourses != 0 ? `/` + totalCourses : ''}
          </h2>
          <p className="head-sm">{statHeading}</p>
        </div>
      </div>
    </Col>
  );
};

export default Stats;
