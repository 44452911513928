import React, { Fragment, useEffect, useReducer } from "react";
import useSWR from "swr";
import { isAdmin } from "../../../utils/state";
import { fetcher } from "../../../utils/fetcher";
import Spinner from "../../../components/spinner";
import BarChart from "../../../components/bar_chart";
import styled from "styled-components";
import SelectFilter from "../../../components/select";
import { DatePicker } from "rsuite";
import NoDataFound from "../../../components/bar_chart/noDataFound";
import { ordinalize } from "../../../utils/ordinalize";

const GROUP_BY_LIST = [
  {
    value: "phone_number_usage",
    label: "Number of Cases Vs Student per WhatsApp number",
  },
  { value: "service_number", label: "Service Numbers Usage" },
];
const ALL_GRADES = [{ value: "", label: "All Course Grades" }];
const ALL_SCHOOL_LEVELS = [{ value: "", label: "All Other Types" }];

const initialState = {
  startDate: null,
  endDate: null,
  groupBy: "phone_number_usage",
  gender: "",
  gradeId: "",
  gradeOptions: ALL_GRADES,
  schoolLevelId: "",
  schoolLevelOptions: ALL_SCHOOL_LEVELS,
  chartData: [],
  axisContent: [],
};

const genderOptions = [
  { value: "", label: "All Genders" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

const reducer = (state, action) => {
  switch (action.type) {
    case "setAxisContent":
      return {
        ...state,
        axisContent: action.payload,
      };
    case "setStartDate":
      return {
        ...state,
        startDate: action.payload,
      };
    case "setEndDate":
      return {
        ...state,
        endDate: action.payload,
      };
    case "setChartData":
      return {
        ...state,
        chartData: action.payload,
      };
    case "setGender":
      return {
        ...state,
        gender: action.payload,
      };
    case "setGradeId":
      return {
        ...state,
        gradeId: action.payload,
        schoolLevelId: "",
      };
    case "setGradeOptions":
      return {
        ...state,
        gradeOptions: action.payload,
      };
    case "setSchoolLevelId":
      return {
        ...state,
        schoolLevelId: action.payload,
        gradeId: "",
      };
    case "setSchoolLevelOptions":
      return {
        ...state,
        schoolLevelOptions: action.payload,
      };
    case "setGroupBy":
      return {
        ...state,
        groupBy: action.payload,
      };
    default:
      return state;
  }
};

const ChartContainer = styled.div`
  width: 100%;
  height: 500px;
`;

const Index = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    startDate,
    endDate,
    gender,
    gradeId,
    gradeOptions,
    schoolLevelId,
    schoolLevelOptions,
    groupBy,
    chartData,
    axisContent,
  } = state;

  const { data, isLoading } = useSWR(
    {
      url: "/api/v0/analytics/numbers_usage",
      params: payload({ ...state }),
    },
    fetcher,
  );

  const { data: grades } = useSWR(
    {
      url: "/api/v0/grades?type=numbers",
    },
    fetcher,
  );

  const { data: schoolLevels } = useSWR(
    {
      url: "/api/v0/grades?type=school_levels",
    },
    fetcher,
  );

  useEffect(() => {
    if (!data) return;
    const chartData = Object.keys(data).map((key) => ({
      name: key,
      value: data[key],
    }));
    dispatch({ type: "setChartData", payload: chartData });
  }, [data]);

  useEffect(() => {
    if (!grades) return;
    const gradeOptions = grades.data.map((grade) => ({
      value: grade.id,
      label: ordinalize(grade.attributes.name),
    }));
    dispatch({
      type: "setGradeOptions",
      payload: [...ALL_GRADES, ...gradeOptions],
    });
  }, [grades]);

  useEffect(() => {
    if (!schoolLevels) return;
    const schoolLevelOptions = schoolLevels.data.map((school_level) => ({
      value: school_level.id,
      label: school_level.attributes.name,
    }));
    dispatch({
      type: "setSchoolLevelOptions",
      payload: [...ALL_SCHOOL_LEVELS, ...schoolLevelOptions],
    });
  }, [schoolLevels]);

  useEffect(() => {
    if (!groupBy) return;
    let axisContentData = [];
    if (groupBy === "phone_number_usage") {
      axisContentData = [
        { value: "Number of Student(s) per WhatsApp number" },
        { value: "Number of cases" },
      ];
    } else {
      axisContentData = [
        { value: "Service numbers" },
        { value: "Number of Student(s)" },
      ];
    }
    dispatch({
      type: "setAxisContent",
      payload: axisContentData,
    });
  }, [groupBy]);

  if (isLoading) return <Spinner />;

  if (!isAdmin()) return null;
  return (
    <div className="">
      <Fragment>
        <fieldset className="d-flex justify-content-end align-items-center">
          <label className="grey-txt form-group me-2" htmlFor="groupBy">
            Group By
          </label>
          <SelectFilter
            name="groupBy"
            value={groupBy}
            options={GROUP_BY_LIST}
            onChange={(e) => {
              dispatch({
                type: "setGroupBy",
                payload: e.target.value,
              });
            }}
          />
        </fieldset>

        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <SelectFilter
                value={gender}
                options={genderOptions}
                onChange={(e) => {
                  dispatch({
                    type: "setGender",
                    payload: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <SelectFilter
                value={gradeId}
                options={gradeOptions}
                onChange={(e) => {
                  dispatch({
                    type: "setGradeId",
                    payload: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <SelectFilter
                value={schoolLevelId}
                options={schoolLevelOptions}
                onChange={(e) => {
                  dispatch({
                    type: "setSchoolLevelId",
                    payload: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <fieldset>
          <legend className="head-sm">Creation Date</legend>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label className="f-med mb-1" htmlFor="startDate">
                  Start Date
                </label>
                <DatePicker
                  name="startDate"
                  value={startDate}
                  block
                  shouldDisableDate={(date) => date > (endDate || new Date())}
                  onChange={(date) => {
                    dispatch({
                      type: "setStartDate",
                      payload: date,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="f-med mb-1" htmlFor="endDate">
                  End Date
                </label>
                <DatePicker
                  name="endDate"
                  value={endDate}
                  block
                  shouldDisableDate={(date) => date < startDate}
                  onChange={(date) => {
                    dispatch({
                      type: "setEndDate",
                      payload: date,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </fieldset>
        <ChartContainer>
          {chartData.length > 0 ? (
            <BarChart data={chartData} axisContent={axisContent} />
          ) : (
            <NoDataFound />
          )}
        </ChartContainer>
      </Fragment>
    </div>
  );
};

const payload = ({
  groupBy,
  startDate,
  endDate,
  gender,
  gradeId,
  schoolLevelId,
}) => ({
  group_by: groupBy,
  start_date: startDate,
  end_date: endDate,
  gender,
  grade_id: gradeId || schoolLevelId,
});
export default Index;
