import React, { Fragment, useState } from "react";
import { fetcher } from "../../utils/fetcher";
import useSWR from "swr";
import { Button, Table } from "rsuite";
import DeleteConfirmationModal from "../../components/delete_confirmation_modal";
import axios from "axios";
import { getAuthenticityToken } from "../../utils/authenticity_token";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;
const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 4 }} />
);
const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 4 }} />;

const PhoneNumbers = () => {
  const { data, isLoading, mutate } = useSWR("/api/v0/phone_numbers", fetcher);
  const [phoneNumberToDelete, setPhoneNumberToDelete] = useState(null);

  return (
    <Fragment>
      <Table
        loading={isLoading}
        height={600}
        width={"100wv"}
        data={data?.data || []}
      >
        <Column width={100} flexGrow={3}>
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="attributes.id" />
        </Column>
        <Column width={100} flexGrow={3}>
          <HeaderCell>Number</HeaderCell>
          <Cell dataKey="attributes.hidden_number" />
        </Column>
        <Column width={100} flexGrow={1}>
          <HeaderCell>Actions</HeaderCell>
          <Cell>
            {(rowData, rowIndex) => (
              <div>
                <Button
                  color="red"
                  appearance="primary"
                  onClick={() => setPhoneNumberToDelete(rowData)}
                  className="me-2"
                >
                  Delete
                </Button>
              </div>
            )}
          </Cell>
        </Column>
      </Table>
      {!!phoneNumberToDelete && (
        <DeleteConfirmationModal
          open={!!phoneNumberToDelete}
          onClose={() => setPhoneNumberToDelete(null)}
          onConfirm={() => {
            axios({
              method: "DELETE",
              url: `/api/v0/phone_numbers/${phoneNumberToDelete.id}`,
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
            }).then((e) => {
              setPhoneNumberToDelete(null);
              mutate();
            });
          }}
        />
      )}
    </Fragment>
  );
};

export default PhoneNumbers;
