import React from "react";
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BarChart = ({
  name = null,
  data = [{ name: "", value: 0 }],
  axisContent = [{}, {}],
}) => {
  return (
    <ResponsiveContainer width="100%" height={450}>
      <RechartsBarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          angle={-45} textAnchor="end" tick={{ fontSize: 12 }}
          label={{ position: "center", offset: "0", dy: 85, ...axisContent[0] }}
          interval={0}
        />
        <YAxis
          label={{ angle: -90, position: "left",dy: -85, ...axisContent[1] }}
          allowDecimals={false}
        />
        <Tooltip />
        <Legend verticalAlign="top" height={40} />
        <Bar dataKey="value" fill="#198754" name={name} />
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
