import React from "react";
import { Form } from "rsuite";

const UrlPreview = ({ url, media_type, onClear, readOnly = false }) => (
  <div className="form-group">
    <Form.ControlLabel>
      <div className="mb-1">Attached File</div>
      <div className="d-flex align-items-center">
        <i class="fa-solid fa-link"></i>
        <a href={url} target="_blank" rel="noopener noreferrer" className="mx-2">
          Link
        </a>{" "}
        ({media_type}){" "}
        {!readOnly && <span className="fa-solid fa-xmark text-danger ms-1 pt-1 cursor-pointer" onClick={onClear}></span>}
      </div>
    </Form.ControlLabel>
  </div>
);

export default UrlPreview;