import React, { Fragment, useState } from "react";
import { Loader, Placeholder } from "rsuite";

const LoaderSpinner = ({ paragraph = false }) => (
  <div>
    {paragraph && <Placeholder.Paragraph rows={8} />}
    <Loader center size="lg" content="loading" />
  </div>
);

export default LoaderSpinner;
