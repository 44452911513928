import React, { useState } from "react";
import {
  Button,
  Form,
  Toggle,
  InputPicker,
  Slider,
  Checkbox,
  Stack,
  TagPicker,
  InputNumber,
} from "rsuite";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import { ordinalize } from "../../utils/ordinalize";
import classNames from "classnames";
import "./form.css";
import PageTitle from "../../components/page_title";

const GRADED = "Graded";
const OTHER_TYPE = "OtherType";
const ESL = "ESL";
const TYPE_OPTIONS = [
  { value: GRADED, label: GRADED },
  { value: OTHER_TYPE, label: OTHER_TYPE },
  { value: ESL, label: ESL },
];

const FormModal = ({ onClose, onConfirm, course, errors }) => {
  const { id } = course;
  const isNew = !id;
  const [state, setState] = useState({
    type: course?.attributes?.type || "",
    title: course?.attributes?.title || "",
    description: course?.attributes?.description || "",
    published: course?.attributes?.published || false,
    send_cert: course?.attributes?.send_cert || false,
    lessons_per_day: course?.attributes?.lessons_per_day || null,
    grade_ids:
      course?.attributes?.grade_ids?.map(function (i) {
        return i.toString();
      }) || [],
    cert_grade_ids:
      course?.attributes?.cert_grade_ids?.map(function (i) {
        return i.toString();
      }) || [],
    language_id: course?.attributes?.language_id || "",
    content_count: course?.attributes?.content_count || 0,
    assessment: {
      enabled: course?.attributes?.assessment?.enabled,
      minimum_avg_score:
        course?.attributes?.assessment?.minimum_avg_score ||
        (course?.id !== null ? 0 : 60),
      formative_weightage:
        course?.attributes?.assessment?.formative_weightage ||
        (course?.id !== null ? 0 : 50),
      formative_validate:
        course?.attributes?.assessment?.formative_validate || false,
      formative_display_answer:
        course?.attributes?.assessment?.formative_display_answer || false,
      summative_weightage:
        course?.attributes?.assessment?.summative_weightage ||
        (course?.id !== null ? 0 : 50),
      summative_validate:
        course?.attributes?.assessment?.summative_validate || false,
      summative_display_answer:
        course?.attributes?.assessment?.summative_display_answer || false,
    },
  });
  const errorStyles = (baseErrors) => {
    return { display: baseErrors ? "block" : "none", color: "red" };
  };

  const { data: grades = { data: [] }, isLoading: isLoadingGrades } = useSWR(
    "/api/v0/grades?type=numbers",
    fetcher,
  );

  const {
    data: school_levels = { data: [] },
    isLoading: isLoadingSchoolLevels,
  } = useSWR("/api/v0/grades?type=school_levels", fetcher);

  const { data: languages = { data: [] }, isLoading: isLoadingLanguages } =
    useSWR("/api/v0/languages", fetcher);

  if (isLoadingGrades || isLoadingSchoolLevels || isLoadingLanguages)
    return null;
  return (
    <>
      <div className="container">
        <div className="mb-3">
          <div className="row align-items-center">
            <div className="col-md-4">
              <PageTitle
                title={!course?.id ? "Add New Course" : "Edit Course"}
              />
            </div>
            <div className="col-md-8">
              <div className="d-flex justify-content-end">
                <Button
                  appearance="ghost"
                  className="px-3 me-2"
                  color="green"
                  onClick={onClose}
                >
                  <i className="fa fa-angle-left"></i>
                </Button>
                <Button
                  onClick={() =>
                    setState({ ...state, published: !state.published })
                  }
                  color="green"
                  appearance="primary"
                  checkedChildren="Published"
                  unCheckedChildren="Unpublish"
                  disabled={isNew || state.content_count == 0}
                >
                  {state.published ? "Un-Publish" : "Publish"}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="shadow-sm bg-white radius-5 tab-sec min-h-50">
          <div className="inner">
            <div className="sm-container">
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>
                    Course Type<span className="text-danger">*</span>
                  </Form.ControlLabel>
                  <Form.Control
                    name="type"
                    value={state.type}
                    onChange={(value) =>
                      setState({
                        ...state,
                        type: value,
                        grade_ids: [],
                        cert_grade_ids: [],
                      })
                    }
                    errorMessage={errors?.type}
                    accepter={InputPicker}
                    data={TYPE_OPTIONS}
                    block
                    cleanable={false}
                  />
                </Form.Group>
                {state.type && (
                  <Form.Group>
                    {/* title */}
                    <Form.Group>
                      <Form.ControlLabel
                        className={classNames({
                          "text-danger": (state?.title?.length || 0) > 24,
                        })}
                      >
                        <div className="d-flex">
                          Title<span className="text-danger">*</span>
                          <span className="ms-auto light-txt small">
                            ( {state?.title?.length || 0} / 24 )
                          </span>
                        </div>
                      </Form.ControlLabel>

                      <Form.Control
                        name="title"
                        value={state.title}
                        onChange={(value) => {
                          if (value.length > 24) return;
                          setState({ ...state, title: value });
                        }}
                        errorMessage={errors?.title}
                      />
                    </Form.Group>
                    {/* description */}
                    <Form.Group>
                      <Form.ControlLabel>
                        Description<span className="text-danger">*</span>
                      </Form.ControlLabel>
                      <Form.Control
                        name="description"
                        value={state.description}
                        onChange={(value) =>
                          setState({ ...state, description: value })
                        }
                        errorMessage={errors?.description}
                      />
                    </Form.Group>

                    <div className="row mg-row">
                      {/* grade 1st -> 12th */}
                      {state.type != OTHER_TYPE && (
                        <div className="col-lg-6">
                          <Form.Group>
                            <Form.ControlLabel>
                              Course Grade<span className="text-danger">*</span>
                            </Form.ControlLabel>
                            <Form.Control
                              name="grade_ids"
                              accepter={TagPicker}
                              value={state.grade_ids}
                              onChange={(value) => {
                                if (
                                  value.length == 0 ||
                                  !state.cert_grade_ids.some((id) =>
                                    value.includes(id),
                                  )
                                ) {
                                  setState({
                                    ...state,
                                    grade_ids: value,
                                    cert_grade_ids: [],
                                  });
                                } else {
                                  setState({ ...state, grade_ids: value });
                                }
                              }}
                              errorMessage={errors?.grades}
                              labelKey="name"
                              valueKey="id"
                              data={grades.data
                                .filter((grade) => {
                                  return (
                                    state.type != ESL ||
                                    parseInt(grade.attributes.name) > 6
                                  );
                                })
                                .map((grade) => ({
                                  id: grade.id,
                                  name: `${ordinalize(grade.attributes.name)} Grade`,
                                }))}
                            />
                          </Form.Group>
                        </div>
                      )}
                      {/* grade School Levels */}
                      {state.type == OTHER_TYPE && (
                        <div className="col-lg-6">
                          <Form.Group>
                            <Form.ControlLabel>
                              Other Type<span className="text-danger">*</span>
                            </Form.ControlLabel>
                            <Form.Control
                              name="grade_ids"
                              accepter={TagPicker}
                              block
                              value={state.grade_ids}
                              onChange={(value) => {
                                if (
                                  value.length == 0 ||
                                  !state.cert_grade_ids.some((id) =>
                                    value.includes(id),
                                  )
                                ) {
                                  setState({
                                    ...state,
                                    grade_ids: value,
                                    cert_grade_ids: [],
                                  });
                                } else {
                                  setState({ ...state, grade_ids: value });
                                }
                              }}
                              errorMessage={errors?.grades}
                              labelKey="name"
                              valueKey="id"
                              data={school_levels.data.map((school_level) => ({
                                id: school_level.id,
                                name: school_level.attributes.name,
                              }))}
                            />
                          </Form.Group>
                        </div>
                      )}
                      {/* grade overwriting in cert */}
                      <div className="col-lg-6">
                        {state.grade_ids.length > 1 && (
                          <Form.Group>
                            <Form.ControlLabel>
                              Grade to display in certificate
                            </Form.ControlLabel>
                            <Form.Control
                              name="cert_grade_ids"
                              accepter={InputPicker}
                              placeholder="Display all grades"
                              value={state.cert_grade_ids[0]}
                              onChange={(value) => {
                                setState({ ...state, cert_grade_ids: [value] });
                              }}
                              errorMessage={errors?.cert_grade_ids}
                              labelKey="name"
                              valueKey="id"
                              data={(state.type != OTHER_TYPE
                                ? grades
                                : school_levels
                              ).data
                                .filter((grade) => {
                                  return state.grade_ids.includes(grade.id);
                                })
                                .map((grade) => ({
                                  id: grade.id,
                                  name:
                                    state.type != OTHER_TYPE
                                      ? `${ordinalize(grade.attributes.name)} Grade`
                                      : grade.attributes.name,
                                }))}
                            />
                          </Form.Group>
                        )}
                      </div>
                      {/* language */}
                      <div className="col-lg-6">
                        <Form.Group>
                          <Form.ControlLabel>
                            Language<span className="text-danger">*</span>
                          </Form.ControlLabel>
                          <Form.Control
                            name="language_id"
                            valueKey="id"
                            labelKey="name"
                            value={`${state.language_id}`}
                            accepter={InputPicker}
                            onChange={(value) =>
                              setState({ ...state, language_id: value })
                            }
                            errorMessage={errors?.language}
                            data={languages.data.map((language) => ({
                              id: language.id,
                              name: language.attributes.name,
                            }))}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    {/* send certificate toggle */}
                    <Form.Group>
                      <Form.ControlLabel>
                        <b>Send certificate?</b> to generate certificate when
                        last day`s content is completed by students
                      </Form.ControlLabel>
                      <Toggle
                        checked={state.send_cert}
                        onChange={(value) =>
                          setState({ ...state, send_cert: value })
                        }
                        checkedChildren="Enabled"
                        unCheckedChildren="Disabled"
                        disabled={isNew || state.content_count == 0}
                      />
                    </Form.Group>

                    {/* lessons per day */}
                    <Form.Group>
                      <Form.ControlLabel>
                        <b>Per Day Limit: Lesson Consumption by Student</b> This
                        setting controls the maximum number of lessons a student
                        can access in a single day
                      </Form.ControlLabel>
                      <div
                        className={
                          "rs-form-control-input-wrapper every-row row"
                        }
                      >
                        <div className="col-md-6 mb-md-0 mb-2">
                          <InputNumber
                            name="lessons_per_day"
                            postfix=" Lessons"
                            min={1}
                            max={100}
                            value={state.lessons_per_day}
                            scrollable={false}
                            onChange={(value) =>
                              setState({
                                ...state,
                                lessons_per_day: value
                                  ? Math.trunc(value)
                                  : null,
                              })
                            }
                          />
                        </div>
                      </div>
                    </Form.Group>

                    <hr />
                    {/* asssessment toggle */}
                    <Form.Group>
                      <Form.ControlLabel>
                        <b>Enable Assessment?</b> (if this course has
                        formative/summative assessment questions)
                      </Form.ControlLabel>
                      <Toggle
                        checked={state.assessment.enabled}
                        onChange={(value) =>
                          setState({
                            ...state,
                            assessment: {
                              ...state.assessment,
                              enabled: value,
                            },
                          })
                        }
                        checkedChildren="Enabled"
                        unCheckedChildren="Disabled"
                      />
                    </Form.Group>
                    {/* errors */}
                    <div style={errorStyles(errors?.base)}>{errors?.base}</div>
                    <br />
                    {state.assessment.enabled && (
                      <Form.Group>
                        {/* Min avg score */}
                        <Form.Group>
                          <Form.ControlLabel>
                            Minimum Average Score (
                            {state.assessment.minimum_avg_score}%)
                          </Form.ControlLabel>
                          <Slider
                            progress
                            style={{ marginBottom: 5 }}
                            value={state.assessment.minimum_avg_score}
                            onChange={(value) =>
                              setState({
                                ...state,
                                assessment: {
                                  ...state.assessment,
                                  minimum_avg_score: value,
                                },
                              })
                            }
                          />
                        </Form.Group>
                        {/* Formative Assessment */}
                        <Form.Group>
                          <Form.ControlLabel>
                            Formative assessment (
                            {state.assessment.formative_weightage}% weightage)
                          </Form.ControlLabel>
                          <Slider
                            progress
                            style={{ marginBottom: 5 }}
                            value={state.assessment.formative_weightage}
                            onChange={(value) =>
                              setState({
                                ...state,
                                assessment: {
                                  ...state.assessment,
                                  formative_weightage: value,
                                  summative_weightage: 100 - value,
                                },
                              })
                            }
                          />
                          <Stack direction="row" spacing={12}>
                            <Checkbox
                              checked={state.assessment.formative_validate}
                              onChange={(value, checked) =>
                                setState({
                                  ...state,
                                  assessment: {
                                    ...state.assessment,
                                    formative_validate: checked,
                                    formative_display_answer: false,
                                  },
                                })
                              }
                            >
                              Validate Answer?
                            </Checkbox>
                            {state.assessment.formative_validate && (
                              <Checkbox
                                checked={
                                  state.assessment.formative_display_answer
                                }
                                onChange={(value, checked) =>
                                  setState({
                                    ...state,
                                    assessment: {
                                      ...state.assessment,
                                      formative_display_answer: checked,
                                    },
                                  })
                                }
                              >
                                Display Answer?
                              </Checkbox>
                            )}
                          </Stack>
                        </Form.Group>
                        {/* Summative Assessment */}
                        <Form.Group>
                          <Form.ControlLabel>
                            Summative assessment (
                            {state.assessment.summative_weightage}% weightage)
                          </Form.ControlLabel>
                          <Slider
                            progress
                            style={{ marginBottom: 5 }}
                            value={state.assessment.summative_weightage}
                            onChange={(value) =>
                              setState({
                                ...state,
                                assessment: {
                                  ...state.assessment,
                                  summative_weightage: value,
                                  formative_weightage: 100 - value,
                                },
                              })
                            }
                          />
                          <Stack direction="row" spacing={12}>
                            <Checkbox
                              checked={state.assessment.summative_validate}
                              onChange={(value, checked) =>
                                setState({
                                  ...state,
                                  assessment: {
                                    ...state.assessment,
                                    summative_validate: checked,
                                    summative_display_answer: false,
                                  },
                                })
                              }
                            >
                              Validate Answer?
                            </Checkbox>
                            {state.assessment.summative_validate && (
                              <Checkbox
                                checked={
                                  state.assessment.summative_display_answer
                                }
                                onChange={(value, checked) =>
                                  setState({
                                    ...state,
                                    assessment: {
                                      ...state.assessment,
                                      summative_display_answer: checked,
                                    },
                                  })
                                }
                              >
                                Display Answer?
                              </Checkbox>
                            )}
                          </Stack>
                        </Form.Group>
                      </Form.Group>
                    )}
                  </Form.Group>
                )}
              </Form>

              <div className="row">
                <div className="col-sm-6">
                  <Button onClick={onClose} appearance="subtle" block>
                    Cancel
                  </Button>
                </div>
                <div className="col-sm-6">
                  <Button
                    onClick={() => {
                      onConfirm(state);
                    }}
                    color="green"
                    appearance="primary"
                    block
                    disabled={!state.type}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FormModal;
