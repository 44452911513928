const today = new Date();
const lastDay = new Date();
lastDay.setDate(lastDay.getDate() - 6);

export const initalState = {
  startDate: lastDay,
  endDate: today,
  age: null,
  gender: null,
  country: null,
  provinceIds: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "setStartDate":
      return { ...state, startDate: action.payload };
    case "setEndDate":
      return { ...state, endDate: action.payload };
    case "setAge":
      return { ...state, age: action.payload };
    case "setGender":
      return { ...state, gender: action.payload };
    case "setCountry":
      return { ...state, country: action.payload };
    case "setProvinceIds":
      return { ...state, provinceIds: action.payload };
    default:
      return state;
  }
};
