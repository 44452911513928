import React from "react";
import { Routes, Route } from "react-router-dom";
import Show from "./show";
import Details from "./show/details";
import Courses from "./show/courses";
import List from "./list";

const Index = () => (
  <Routes>
    <Route path=":id/*" element={<Show />}>
      <Route path="details" element={<Details />} />
      <Route path="courses" element={<Courses />} />
    </Route>
    <Route path="*" element={<List />} />
  </Routes>
);

export default Index;
