import React, { useEffect, useState } from "react";
import Table from "../../../components/table";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import Spinner from "../../../components/spinner";
import { fetcher } from "../../../utils/fetcher";

const Details = () => {
  const { id } = useParams();
  const { data, isLoading } = useSWR(`/api/v0/students/${id}`, fetcher);
  const [formattedData, setFormattedData] = useState({});

  useEffect(() => {
    if (isLoading) return;
    const body = [
      {
        values: [
          {
            className: "text-center fw-bold",
            value: "Fullname",
          },
          {
            value: data["data"]["attributes"]["fullname"],
          },
        ],
      },
    ];
    setFormattedData({
      body,
    });
  }, [data]);
  if (isLoading) return <Spinner />;
  return <div className="summary-table"><Table data={formattedData} /></div>;
};

export default Details;
