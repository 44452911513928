import React, { useEffect, useState } from "react";
import { Button, Modal, Form, InputPicker } from "rsuite";
import useSWR from "swr";
import Spinner from "../../components/spinner";
import { fetcher } from "../../utils/fetcher";

const DemoForm = ({ open, onClose, onSubmit }) => {
    const [languageId, setLanguageId] = useState(null);
    const [link, setLink] = useState(null);
    const { data: languages = { data: [] }, isLoading: isLoadingLanguages } =
        useSWR("/api/v0/languages", fetcher, {});

    useEffect(() => {
        if (languageId || !languages.data.length) return;
        setLanguageId(languages.data[0].id);
    }, [languages]);

    if (isLoadingLanguages) return <Spinner />;
    return (
        <Modal open={open} onClose={onClose} size="xs" backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    Add Demo Video
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form fluid>
                    <Form.Group>
                        <Form.Group>
                            <Form.ControlLabel> Link </Form.ControlLabel>
                            <Form.Control
                                name="link"
                                value={link}
                                onChange={(value) => setLink(value)}
                            />
                        </Form.Group>{" "}
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Language<span className="text-danger">*</span></Form.ControlLabel>
                        <Form.Control
                            name="language_id"
                            valueKey="id"
                            labelKey="name"
                            defaultValue={languageId}
                            block
                            style={{width: '100%'}}
                            accepter={InputPicker}
                            onChange={(value) => setLanguageId(value)}
                            data={languages.data.map((language) => ({
                                id: language.id,
                                name: language.attributes.name,
                            }))}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} appearance="subtle">
                    Cancel
                </Button>
                <Button
                    type="submit"
                    onClick={() => onSubmit(link, languageId)}
                    disabled={!link}
                    color="green"
                    appearance="primary"
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DemoForm;
