// Core
import React, { Fragment, useReducer } from "react";
import useSWR from "swr";

// Rsuite
import { Whisper, Tooltip as RTooltip } from "rsuite";

// ReCharts
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
} from "recharts";

// Components
import NoDataFound from "../../../components/bar_chart/noDataFound";
import NoDataPieChart from "../../../components/pie_chart/noDataPieChart";

// Helper
import { fetcher } from "../../../utils/fetcher";
import {
  format_end_of_day_in_time_zone,
  format_start_of_day_in_time_zone,
} from "../../../utils/date_formatter";

// Misc
import Filter from "./filter";
import { initalState, reducer } from "./reducer";

const COLORS = ["#FF8042", "#E2DED0"];

const StudentEngagement = () => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const { data } = useSWR(
    {
      url: "/api/v0/analytics/students_engagement",
      params: payload({ ...state }),
    },
    fetcher,
  );

  const { daily = {} } = data ?? {};
  const activeUsers = data?.summary?.new + data?.summary?.return;
  const inactiveUsers = data?.summary?.total - activeUsers;
  const userPercent = `${((activeUsers / data?.summary?.total) * 100).toFixed(2)} %`;

  const pieData = [
    {
      name: "Active Users",
      users: activeUsers ?? 0,
    },
    {
      name: "Inactive Users",
      users: inactiveUsers ?? 0,
    },
  ];

  const combineDates = (newDates, returnDates) => {
    const allDates = new Set([
      ...Object.keys(newDates ?? {}),
      ...Object.keys(returnDates ?? {}),
    ]);
    return Array.from(allDates);
  };

  const chartData = combineDates(data?.daily?.new, data?.daily?.return).map(
    (date) => {
      //Avoid relying on toJSON or toISOString
      // due to some issues related to time zone
      /*
        ->new Date("2024-07-05").toISOString()
        '2024-07-05T00:00:00.000Z'
        ->new Date("2024-07-5").toISOString()
        '2024-07-04T23:00:00.000Z'
      */
      const formattedDate = JSON.parse(date)
        .map((item, index) =>
          index == 0 ? item : String(item).padStart(2, "0"),
        )
        .join("-");
      return {
        date: formattedDate,
        new: data?.daily?.new?.[date] ?? 0,
        return: data?.daily?.return?.[date] ?? 0,
      };
    },
  );

  const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload) return null;
    const day = payload[0]?.payload;
    return (
      <div
        key={day?.date}
        style={{
          padding: "6px",
          backgroundColor: "white",
          border: "1px solid grey",
        }}
      >
        <b>{day?.date}</b>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "10px" }}>
            <p>New active users: {day?.new}</p>
            <p>Returning active users: {day?.return}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Filter {...{ state, dispatch }} />

      <div className="row">
        <div className="col-lg-8">
          <div className="mt-3">
            {Object.keys(daily?.new || {})?.length ||
            Object.keys(daily?.return || {})?.length ? (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={chartData} width={600} height={300}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                    dataKey="date"
                    label={{
                      value: "Dates",
                      position: "bottom",
                      offset: "60",
                    }}
                  />
                  <YAxis
                    allowDecimals={false}
                    label={{
                      value: "No. of active users",
                      angle: -90,
                      position: "left",
                      offset: "-3",
                    }}
                  />
                  <Tooltip content={CustomTooltip} />
                  <Legend
                    height={40}
                    verticalAlign="top"
                    iconType="square"
                    payload={[
                      {
                        value: "New active users",
                        id: "new",
                        color: "#198754",
                      },
                      {
                        value: "Returning active users",
                        id: "return",
                        color: "#fffff",
                      },
                    ]}
                  />
                  <Bar dataKey="new" stackId="a" fill="#198754" />
                  <Bar dataKey="return" stackId="a" fill="#fffff" />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>

        <div className="col-lg-4">
          {!!data?.summary?.new || !!data?.summary?.return ? (
            <div className="border rounded py-3 inactive-chart">
              <div className="d-flex align-items-center ps-3">
                <h3 className="head-sm text-dark">Students Engagement %</h3>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={
                    <RTooltip>
                      How it`s calculated ?
                      <br />
                      Total active users divided by total registrations for e.g.
                      70/100 which is 70%
                    </RTooltip>
                  }
                >
                  <i className="fa-solid fa-circle-info light-txt ms-2"></i>
                </Whisper>
              </div>

              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={pieData}
                    isAnimationActive={true}
                    label={true}
                    nameKey="name"
                    dataKey="users"
                    labelLine
                    cx="50%"
                    cy="50%"
                    innerRadius={95}
                    outerRadius={150}
                    fill="#8884d8"
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                    <Label
                      value={userPercent}
                      position="center"
                      className="label-top f-med total-label count"
                      fontSize="35px"
                    />
                  </Pie>
                  <Tooltip />
                  <Legend
                    iconType="square"
                    formatter={(value) => (
                      <span className="grey-txt f-14">{value}</span>
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div className="text-dark text-center f-16 mt-2 ">
                Total Registrations : {data?.summary?.total}
              </div>
            </div>
          ) : (
            <NoDataPieChart />
          )}
        </div>
      </div>
    </Fragment>
  );
};

const payload = ({
  startDate,
  endDate,
  age,
  gender,
  country,
  provinceIds,
}) => ({
  start_date: format_start_of_day_in_time_zone(startDate),
  end_date: format_end_of_day_in_time_zone(endDate),
  age,
  gender,
  country,
  country_state: country == "Afghanistan" ? provinceIds : "",
});
export default StudentEngagement;
