import React, { Fragment, useEffect, useState } from "react";
import { Table, Pagination, Toggle } from "rsuite";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import ConfirmModal from "../../components/confirm_modal";
import axios from "axios";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import PageTitle from "../../components/page_title";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const List = () => {
  const [accountToApprove, setAccountToApprove] = useState(null);
  const [accountToDisable, setAccountToDisable] = useState(null);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {
    data = [],
    isLoading,
    mutate,
  } = useSWR(
    {
      url: "/api/v0/users",
      params: {
        page: page,
        limit: limit,
      },
    },
    fetcher,
  );
  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, data?.total);

  useEffect(() => {
    //To set admin as the first record
    !!data && data?.data?.sort((a, b) => {
      const roleA = a.attributes.roles.includes("admin");
      const roleB = b.attributes.roles.includes("admin");

      return roleA === roleB ? 0 : roleA ? -1 : 1;
    });
  }, [data])


  return (
    <div className="container">
      <div className='mb-3'>
        <PageTitle title={"Content Creators"} />
      </div>

      <div className="shadow-sm bg-white radius-5 overflow-hidden">
        <div className="inner">
          <Fragment>
            <Table
              loading={isLoading}
              height={500}
              width={"100wv"}
              data={data?.data || []}
            >
              <Column width={100}>
                <HeaderCell>No</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => (
                    <div>{rowIndex + 1 + (page - 1) * limit}</div>
                  )}
                </Cell>
              </Column>
              <Column width={100} flexGrow={2}>
                <HeaderCell>Full Name</HeaderCell>
                <Cell dataKey="attributes.fullname" />
              </Column>
              <Column width={100} flexGrow={2}>
                <HeaderCell>Email</HeaderCell>
                <Cell dataKey="attributes.email" />
              </Column>
              <Column width={100} flexGrow={2}>
                <HeaderCell>Approved</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => (
                    <div>{rowData?.attributes?.approved ? "Yes" : "No"}</div>
                  )}
                </Cell>
              </Column>
              <Column width={100} flexGrow={1}>
                <HeaderCell>Disabled</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => (
                    <div>{rowData?.attributes?.disabled ? "Yes" : "No"}</div>
                  )}
                </Cell>
              </Column>

              <Column width={100} flexGrow={1}>
                <HeaderCell>Roles</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => (
                    <div>{rowData?.attributes?.roles?.join(", ")}</div>
                  )}
                </Cell>
              </Column>
              <Column width={100} flexGrow={1}>
                <HeaderCell>Status</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => {
                    return (
                      <div className="pt-1">
                        {rowData?.attributes?.approved && (
                          <Toggle size="sm"
                            color={rowData?.attributes?.disabled ? "green" : "red"}
                            className="me-2"
                            checked={!rowData?.attributes?.disabled}
                            appearance="ghost"
                            disabled={!!rowData?.attributes?.roles.includes("admin")}
                            onChange={() => setAccountToDisable(rowData)}
                          />
                        )}
                      </div>
                    )
                  }}
                </Cell>
              </Column>
              <Column width={100} flexGrow={2}>
                <HeaderCell>Actions</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => {
                    if (!rowData?.attributes?.approved) {
                      const rowElement = document.querySelector(`div[aria-rowindex="${rowIndex + 2}"]`);
                      rowElement?.classList.add('pending');
                    }
                    return (
                      <Fragment>
                        {!rowData?.attributes?.approved && (
                          <i className="light-txt py-2 fa-solid fa-circle-check" style={{ fontSize: '16' }}
                            onClick={() =>
                              setAccountToApprove(rowData)
                            }></i>
                        )}
                        {rowData?.attributes?.approved && !rowData?.attributes?.roles?.includes("admin") && (
                          <Fragment>
                            <i className="light-txt py-2 fa-solid fa-trash" onClick={() => setAccountToDelete(rowData)}></i>
                          </Fragment>
                        )}
                      </Fragment>
                    );
                  }}
                </Cell>
              </Column>
            </Table>
            {accountToApprove && (
              <ConfirmModal
                open={!!accountToApprove}
                onClose={() => setAccountToApprove(null)}
                onConfirm={() => {
                  axios({
                    method: "put",
                    url: `/api/v0/users/${accountToApprove.id}`,
                    headers: {
                      "Content-Type": "application/json",
                      "X-CSRF-Token": getAuthenticityToken(),
                    },
                    data: {
                      user: {
                        approved: true,
                      },
                    },
                  }).then((response) => {
                    setAccountToApprove(null);
                    window.location.reload();
                    mutate();
                  });
                }}
              />
            )}
            {accountToDisable && (
              <ConfirmModal
                open={!!accountToDisable}
                onClose={() => setAccountToDisable(null)}
                onConfirm={() => {
                  axios({
                    method: "put",
                    url: `/api/v0/users/${accountToDisable.id}`,
                    headers: {
                      "Content-Type": "application/json",
                      "X-CSRF-Token": getAuthenticityToken(),
                    },
                    data: {
                      user: {
                        disabled: !accountToDisable?.attributes?.disabled,
                      },
                    },
                  }).then((response) => {
                    setAccountToDisable(null);
                    mutate();
                  });
                }}
              />
            )}
            {accountToDelete && (
              <ConfirmModal
                open={!!accountToDelete}
                onClose={() => setAccountToDelete(null)}
                onConfirm={() => {
                  axios({
                    method: "delete",
                    url: `/api/v0/users/${accountToDelete.id}`,
                    headers: {
                      "Content-Type": "application/json",
                      "X-CSRF-Token": getAuthenticityToken(),
                    },
                  }).then((response) => {
                    setAccountToDelete(null);
                    mutate();
                  });
                }}
              />
            )}
          </Fragment>
        </div>
      </div>

      {((data?.data || []).length >= 10 || page > 1) && (
        <div className="d-flex justify-content-between align-items-center">
          <div className="grey-txt mt-2 f-14">
            {startIndex + 1} to {(endIndex && endIndex != 0) ? endIndex : ''} of {data?.total}
          </div>
          <div className="pagination-outer">
            <Pagination
              prev
              next={!isLoading}
              ellipsis
              layout={["limit", "|", "pager"]}
              size="sm"
              activePage={page}
              onChangePage={(page) => setPage(page)}
              onChangeLimit={(limit) => setLimit(limit)}
              limit={limit}
              limitOptions={[10, 30, 50]}
              total={data?.total}
              maxButtons={10}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default List;
