import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Form, Button, Input, ButtonToolbar, ButtonGroup } from "rsuite";
import { textLabels } from "./textLabels";
import useSWR from "swr";
import { fetcher } from "../../../utils/fetcher";
import AttachFileModal from "../../../components/attach_file";
import UrlPreview from "../../../components/attach_file/url_preview";
import DeleteConfirmationModal from "../../../components/delete_confirmation_modal";
import { flipper } from "../../../utils/flipper";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
Textarea.displayName = "Textarea";

const MAX_TITLE = 24;
const MAX_MESSAGE = 900;

const Content = ({
  state,
  errors,
  showErrors,
  dispatch,
  filteredLanguages,
  readOnly,
  reminders,
  id,
}) => {
  const { data: featuresData = { features: [] } } = useSWR(
    `/flipper/api/features`,
    fetcher,
  );
  const { data: languagesData } = useSWR({ url: "/api/v0/languages" }, fetcher);

  const formattedLanguages = languagesData
    ? languagesData["data"].map((language) => ({
        value: language.id,
        label: language.attributes.name,
        key: language.attributes.code,
      }))
    : [];
  const [contentObj, setContentObj] = useState({
    key: "Ps",
    title: "",
    message: "",
    state: "",
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const [language, setLanguage] = useState(
    filteredLanguages?.length ? filteredLanguages[0]?.label : "Pashto",
  );
  const [showAttachFile, setShowAttachFile] = useState(false);
  const [showDeleteAttachmentConfirm, setShowDeleteAttachmentConfirm] =
    useState(false);

  const errorStyles = (errors) => {
    return { display: errors ? "block" : "none", color: "red" };
  };

  useEffect(() => {
    setContentObj({
      ...textLabels[language],
      state: state?.content?.[textLabels[language]?.key.toLowerCase()],
    });
  }, [language]);

  useEffect(() => {
    if (!state.copied && id && reminders) {
      dispatch({ type: "setContent", payload: reminders?.content });
      dispatch({ type: "setMedia", payload: reminders?.media });
    }
  }, [reminders, id]);

  return (
    <>
      <div className="mb-3">
        <ButtonToolbar>
          <ButtonGroup justified className="d-flex justify-content-between bb">
            {(filteredLanguages?.length
              ? filteredLanguages
              : formattedLanguages
            )?.map((lang, index) => (
              <Button
                appearance="subtle"
                key={lang.label}
                active={activeIndex === index ? true : false}
                onClick={() => {
                  setLanguage(lang.label);
                  setActiveIndex(index);
                }}
              >
                {lang.label}
              </Button>
            ))}
          </ButtonGroup>
        </ButtonToolbar>
      </div>

      <Form fluid>
        {/* title */}
        <Form.Group>
          <Form.ControlLabel
            className={classNames({
              "text-danger":
                (state.content[contentObj?.key?.toLowerCase()]?.title?.length ||
                  0) > MAX_TITLE,
            })}
          >
            <div className="d-flex">
              <span>{contentObj.title}</span>
              {!readOnly ? (
                <>
                  <span className="text-danger">*</span>
                  <span className="ms-auto light-txt small">
                    (
                    {state.content[contentObj?.key?.toLowerCase()]?.title
                      ?.length || 0}{" "}
                    / {MAX_TITLE} )
                  </span>
                </>
              ) : (
                <span>
                  : {state.content[contentObj?.key?.toLowerCase()]?.title}
                </span>
              )}
            </div>
          </Form.ControlLabel>
          {!readOnly && (
            <Form.Control
              name="title"
              value={state.content[contentObj?.key?.toLowerCase()]?.title ?? ""}
              onChange={(value) => {
                dispatch({
                  type: `setTitle${contentObj.key}`,
                  payload: value?.slice(0, MAX_TITLE),
                });
              }}
            />
          )}
        </Form.Group>
        {/* message */}
        <Form.Group>
          <Form.ControlLabel className="d-flex">
            {contentObj?.message}
            {!readOnly && (
              <>
                <span className="text-danger">*</span>
                <span className="ms-auto light-txt small">
                  (
                  {state.content[contentObj?.key?.toLowerCase()]?.message
                    ?.length || 0}{" "}
                  / {MAX_MESSAGE})
                </span>
              </>
            )}
          </Form.ControlLabel>
          {readOnly ? (
            <span>
              {state.content[contentObj?.key?.toLowerCase()]?.message}
            </span>
          ) : (
            <Form.Control
              name="content"
              value={
                state.content[contentObj?.key?.toLowerCase()]?.message ?? ""
              }
              onChange={(value) => {
                dispatch({
                  type: `setMessage${contentObj.key}`,
                  payload: value?.slice(0, MAX_MESSAGE),
                });
              }}
              accepter={Textarea}
              rows={10}
              required
              dir="auto"
            />
          )}
        </Form.Group>
        {showErrors && (
          <>
            {errors?.map((error) => (
              <div style={errorStyles(error)} key={error}>
                {error}
              </div>
            ))}
            <br />
          </>
        )}
        {/* attachment */}
        {flipper(featuresData?.features, "reminders_attach_file") && (
          <>
            <Form.Group>
              {!readOnly && (
                <>
                  <Form.ControlLabel>Media file</Form.ControlLabel>
                  <Form.HelpText className="pt-0 mb-2">
                    You can attach only one file that can be an image, a video
                    or an audio.
                  </Form.HelpText>

                  {!showAttachFile ? (
                    <Button onClick={() => setShowAttachFile(true)}>
                      Attach
                    </Button>
                  ) : (
                    <AttachFileModal
                      onAttach={(url, media_type, filename) => {
                        var payload = {};
                        payload[contentObj?.key?.toLowerCase()] = {
                          url: url,
                          filename: filename,
                          media_type: media_type,
                        };
                        dispatch({ type: "setMedia", payload: payload });
                        setShowAttachFile(null);
                      }}
                      onClose={() => setShowAttachFile(null)}
                    />
                  )}
                </>
              )}
            </Form.Group>
            {/* attachment > preview */}
            {state.media[contentObj?.key?.toLowerCase()]?.["url"] && (
              <UrlPreview
                url={state.media[contentObj?.key?.toLowerCase()]?.["url"]}
                media_type={
                  state.media[contentObj?.key?.toLowerCase()]?.["media_type"]
                }
                onClear={() => setShowDeleteAttachmentConfirm(true)}
                readOnly={readOnly}
              />
            )}
            <DeleteConfirmationModal
              open={showDeleteAttachmentConfirm}
              onClose={() => setShowDeleteAttachmentConfirm(false)}
              onConfirm={() => {
                var payload = {};
                payload[contentObj?.key?.toLowerCase()] = {};
                dispatch({ type: "setMedia", payload: payload });
                setShowDeleteAttachmentConfirm(false);
              }}
            />
          </>
        )}
        {/* ENDS attachment */}
      </Form>
    </>
  );
};

export default Content;
