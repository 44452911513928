import React, { useEffect, useState } from "react";
import { Button, Form, CheckboxGroup, Checkbox, InputGroup } from "rsuite";

const mcqInitialState = () => ({
  answers: [
    {
      id: 1,
      value: "Answer 1",
      weight: 100,
    },
    {
      id: 2,
      value: "Answer 2",
      weight: 0,
    },
  ],
});

const newMcqOption = (state) => ({
  id: Math.ceil(Math.random() * 1000000) + 3,
  value: fieldName(state),
  weight: 0,
});

const fieldName = (state) => {
  let lastFieldValueNumber = 0;
  state["answers"].forEach(function (d) {
    const newFieldValue = d.value;
    if (newFieldValue != "" && newFieldValue != null) {
      const fieldValueNumber = newFieldValue.split("New Option")[1];
      if (fieldValueNumber != undefined) {
        lastFieldValueNumber = parseInt(fieldValueNumber);
      }
    }
  });
  return "New Option " + (lastFieldValueNumber + 1);
};

const MCQ = ({ data, onChange }) => {
  const [state, setState] = useState(data || mcqInitialState());
  useEffect(() => {
    onChange(state);
  }, [state]);

  const selected = state["answers"].filter((answer) => answer.weight === 100);
  return (
    <Form.Group>
      <div className="d-flex justify-content-between align-items-center">
        <Form.ControlLabel>
          Options <span className="text-danger">*</span>
        </Form.ControlLabel>
        <Button
          color="green"
          className="ms-2"
          appearance="link"
          size="xs"
          onClick={() => {
            const answers = [...state.answers, newMcqOption(state)];
            setState({ ...state, answers });
          }}
        >
          <i className="fa fa-plus me-2"></i>
          Add New Option
        </Button>
      </div>

      <CheckboxGroup
        value={selected?.map((item) => item?.value)}
        onChange={(value) => {
          if (value?.length === 0) return;
          const answers = state.answers.map((answer) => ({
            ...answer,
            weight: value.includes(answer?.value) ? 100 : 0,
          }));
          setState({ ...state, answers });
        }}
      >
        {state.answers.map((answer) => {
          return (
            <InputGroup className="mb-3" key={answer?.value}>
              <Checkbox value={answer.value} className="rst-radio w-100">
                <div style={{ position: "relative" }}>
                  <Form.Control
                    size="lg"
                    className="p-2"
                    name="answer"
                    value={answer.value}
                    onChange={(value) => {
                      if (value.length > 24) return;
                      const answers = state.answers.map((item) => ({
                        ...item,
                        value: item.id === answer.id ? value : item.value,
                      }));
                      setState({ ...state, answers });
                    }}
                  />

                  <div className="limit">
                    ({answer?.value?.length || 0}/ 24)
                  </div>
                </div>
              </Checkbox>
              {state.answers.length > 2 && answer.weight !== 100 && (
                <div
                  className="rst-radio"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    padding: "4px",
                  }}
                >
                  <i
                    className="fa-solid fa-xmark close"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      const answers = state.answers.filter(
                        (item) => item.id !== answer.id,
                      );
                      setState({ ...state, answers });
                    }}
                    onKeyDown={() => {
                      const answers = state.answers.filter(
                        (item) => item.id !== answer.id,
                      );
                      setState({ ...state, answers });
                    }}
                  ></i>
                </div>
              )}
            </InputGroup>
          );
        })}
      </CheckboxGroup>
    </Form.Group>
  );
};

export default MCQ;
