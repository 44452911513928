import React, { useState } from "react";
import PageTitle from "../../components/page_title";
import { Table, Button } from "rsuite";
import Spinner from "../../components/spinner";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import axios from "axios";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import DemoForm from "./form";
import DeleteConfirmationModal from "../../components/delete_confirmation_modal";
import { useNavigate } from "react-router-dom";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const DemoVideos = () => {
  const navigate = useNavigate();
  const [demoVideosToAdd, setDemoVideosToAdd] = useState(null);
  const [demoVideosToDelete, setDemoVideosToDelete] = useState(null);
  const {
    data = {},
    isLoading,
    mutate,
  } = useSWR("/api/v0/demo_videos", fetcher, {});

  const newDemoVideo = () => ({
    id: null,
    attributes: {
      link: "",
      languageId: "",
    },
  });
  const deleteDemoVideo = async (id) => {
    try {
      const url = `/api/v0/demo_videos/${id}`;
      await axios({
        method: "DELETE",
        url,
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getAuthenticityToken(),
        },
      });
      mutate();
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) return <Spinner />;
  return (
    <div>
      <div className="container">
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <PageTitle title={"Demo Videos"} />
          <Button
            color="green"
            appearance="primary"
            onClick={() => setDemoVideosToAdd(newDemoVideo())}
          >
            <i className="fa fa-plus me-2"></i>
            Add Demo Video
          </Button>
        </div>

        <div className="shadow-sm bg-white radius-5 overflow-hidden">
          <div className="inner">
            <Table
              loading={isLoading}
              height={450}
              width={"100wv"}
              data={data?.data || []}
            >
              <Column width={100}>
                <HeaderCell>No</HeaderCell>
                <Cell>{(rowData, rowIndex) => <div>{rowIndex + 1}</div>}</Cell>
              </Column>
              <Column width={100} flexGrow={3}>
                <HeaderCell>Link</HeaderCell>
                <Cell dataKey="attributes.link" />
              </Column>
              <Column width={100} flexGrow={3}>
                <HeaderCell>Language</HeaderCell>
                <Cell dataKey="attributes.language" />
              </Column>
              <Column width={100} flexGrow={3}>
                <HeaderCell>Requests</HeaderCell>
                <Cell dataKey="attributes.request_count" />
              </Column>
              <Column width={150} fixed="right">
                <HeaderCell>Actions</HeaderCell>
                <Cell style={{ padding: '6px' }}>
                  {(rowData) => {
                    return (
                      <div className="light-txt px-2 py-2">
                        <i className="fa-solid fa-eye me-2" onClick={() => {
                          localStorage.setItem("language", rowData.attributes.language),
                            localStorage.setItem("link", rowData.attributes.link),
                            navigate(`/demo_videos/${rowData.attributes.id}/requests`)
                        }}></i>
                        <i className="fa-solid fa-trash me-2" onClick={() => deleteDemoVideo(rowData.attributes.id)}></i>
                      </div>
                    );
                  }}
                </Cell>
              </Column>
            </Table>
            <DeleteConfirmationModal
              open={!!demoVideosToDelete}
              onClose={() => setDemoVideosToDelete(null)}
              onConfirm={() => deleteDemoVideo(rowData.attributes.id)}
            />
            {!!demoVideosToAdd && (
              <DemoForm
                open={!!demoVideosToAdd}
                onSubmit={async (link, languageId) => {
                  try {
                    await axios({
                      method: "POST",
                      url: `/api/v0/demo_videos`,
                      headers: {
                        "Content-Type": "application/json",
                        "X-CSRF-Token": getAuthenticityToken(),
                      },
                      data: {
                        demo_video: {
                          link,
                          language_id: languageId,
                        },
                      },
                    });
                    setDemoVideosToAdd(null);
                    mutate();
                  } catch (error) {
                    console.log(error);
                  }
                }}
                onClose={() => {
                  setDemoVideosToAdd(null);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoVideos;
