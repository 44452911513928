// Core
import React, { useEffect, useState } from "react";

// Rsuite
import { DateRangePicker, CheckPicker, Checkbox } from "rsuite";

// Components
import SelectFilter from "../../../components/select";

// Helper
import {
  GENDER_OPTIONS,
  STUDENT_AGE_GROUPS,
  COUNTRIES,
  AFGSTATES,
} from "../../../utils/constants";
import { isLast30Days, isLastWeek, isToday } from "../../../utils/helpers";
import {
  format_end_of_day_in_time_zone,
  format_start_of_day_in_time_zone,
} from "../../../utils/date_formatter";

const Filter = ({ state, dispatch }) => {
  const { afterToday } = DateRangePicker;
  const { startDate, endDate, age, gender, country } = state;

  const [dateSelected, setDateSelected] = useState([startDate, endDate]);

  const renderValue = (value) => {
    if (isToday(value) || isLast30Days(value) || isLastWeek(value)) {
      return [
        isToday(value)
          ? "Today"
          : isLastWeek(value)
            ? "Last 7 Days"
            : "Last 30 Days",
      ];
    }
    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return `${formatter.format(value[0])} to ${formatter.format(value[1])}`;
  };

  const handleOk = (value) => {
    dispatch({
      type: "setStartDate",
      payload: format_start_of_day_in_time_zone(value[0]),
    });
    dispatch({
      type: "setEndDate",
      payload: format_end_of_day_in_time_zone(value[1]),
    });
  };

  const handleClean = () => {
    dispatch({
      type: "setStartDate",
      payload: format_start_of_day_in_time_zone(new Date()),
    });
    dispatch({
      type: "setEndDate",
      payload: format_end_of_day_in_time_zone(new Date()),
    });
  };

  const picker = React.useRef();

  const today = new Date();
  const last7Days = new Date();
  const last30Days = new Date();
  last7Days.setDate(last7Days.getDate() - 6);
  last30Days.setDate(last30Days.getDate() - 30);

  useEffect(() => {
    if (country != "Afghanistan") {
      dispatch({
        type: "setProvinceIds",
        payload: [],
      });
    }
  }, [country]);

  return (
    <div className="row">
      <div className="col-md-3">
        <div className="form-group">
          <SelectFilter
            value={age}
            onChange={(e) => {
              dispatch({
                type: "setAge",
                payload:
                  e.target.value === "All age groups" ? "" : e.target.value,
              });
            }}
            options={STUDENT_AGE_GROUPS}
          />
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <SelectFilter
            value={gender}
            onChange={(e) =>
              dispatch({ type: "setGender", payload: e.target.value })
            }
            options={[
              {
                label: "All Genders",
                value: "",
              },
              ...GENDER_OPTIONS,
            ]}
          />
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <SelectFilter
            value={country}
            onChange={(e) =>
              dispatch({ type: "setCountry", payload: e.target.value })
            }
            options={[
              {
                label: "All Locations",
                value: "",
              },
              ...COUNTRIES,
            ]}
          />
        </div>
      </div>

      {state?.country === "Afghanistan" && (
        <div className="col-md-3">
          <div className="form-group">
            <CheckPicker
              className="w-100"
              placement="autoVerticalStart"
              menuMaxHeight={200}
              ref={picker}
              value={state?.provinceIds}
              data={AFGSTATES}
              countable={false}
              style={{ width: 224 }}
              searchable={false}
              placeholder="Select Province"
              onChange={(newValue) =>
                dispatch({
                  type: "setProvinceIds",
                  payload: Array.from(new Set([...newValue])),
                })
              }
              onClean={() =>
                dispatch({
                  type: "setProvinceIds",
                  payload: [],
                })
              }
              renderExtraFooter={() => (
                <Checkbox
                  checked={state.provinceIds.length === AFGSTATES?.length}
                  onChange={(value, checked) => {
                    let provinces = [];
                    if (checked) {
                      provinces = AFGSTATES?.map(
                        (provinceOption) => provinceOption.value,
                      );
                    } else {
                      provinces = [];
                    }
                    dispatch({
                      type: "setProvinceIds",
                      payload: provinces,
                    });
                  }}
                >
                  All provinces
                </Checkbox>
              )}
            />
          </div>
        </div>
      )}

      <div className="col-md-3">
        <div className="form-group">
          <DateRangePicker
            editable={false}
            shouldDisableDate={afterToday()}
            placement="bottomEnd"
            showOneCalendar
            ranges={[
              {
                label: "Today",
                value: [new Date(), new Date()],
              },
              {
                label: "Last 7 days",
                value: [last7Days, today],
              },
              {
                label: "Last 30 days",
                value: [last30Days, today],
              },
            ]}
            className="date-filter"
            defaultValue={dateSelected}
            renderValue={renderValue}
            placeholder="Select Range"
            onChange={(date) => {
              if (date) {
                dispatch({
                  type: "setStartDate",
                  payload: format_start_of_day_in_time_zone(
                    date[0].setHours(0, 0, 0, 0),
                  ),
                });
                dispatch({
                  type: "setEndDate",
                  payload: format_end_of_day_in_time_zone(date[1]),
                });
              }
            }}
            onOk={handleOk}
            onClean={handleClean}
            onSelect={(value) => setDateSelected(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
