import React from "react";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "../../utils/state";
import { isAdmin } from "../../utils/state";

const Protected = ({ adminProtected, children }) => {
  if (!isLoggedIn()) return <Navigate to="/guests" />;
  else if (adminProtected)
    return isAdmin() ? children : <Navigate to="/courses" />;
  else return children;
};

export default Protected;
