import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "rsuite";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import axios from "axios";
import { validateEmail } from "../../utils/validators";

const ForgotPassword = () => {
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [email, setEmail] = useState("");

  const onSubmit = () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    axios({
      method: "put",
      url: "/api/v0/guests/forgot_password",
      Accept: "*/*",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getAuthenticityToken(),
      },
      data: { email },
    })
      .then((response) => {
        setSuccessMessage(response?.data);
        if (response?.data?.success === true) {
          setSuccessMessage("We have e-mailed your password reset link!");
          setError("");
        } else if (response?.data?.success === false) {
          setError(
            "The email address you've entered doesn't appear to exist. " +
              "Please check your entry and try again.",
          );
          setSuccessMessage("");
        }
      })
      .catch((response) => {
        setError(
          "Something Went Wrong! Please check your entry and try again.",
        );
        setSuccessMessage("");
      });
  };

  return (
    <div className="">
      <h2 className="head-xl">Forgot Password?</h2>
      <p className="grey-txt mb-4">
        Please enter the email address linked to your account. We will send you
        instructions to reset your password.
      </p>
      {error && <div className="alert alert-danger">{error}</div>}
      {successMessage && (
        <div className="alert alert-success">{successMessage}</div>
      )}
      <Form onSubmit={onSubmit} fluid>
        <Form.Group>
          <Form.ControlLabel>
            Email<span className="text-danger">*</span>
          </Form.ControlLabel>
          <Form.Control
            name="email"
            type="email"
            value={email}
            onChange={(value) => setEmail(value)}
            required
            className="theme-ip"
          />
        </Form.Group>
        <Button appearance="primary" color="green" type="submit" block>
          Submit
        </Button>
        <p className="mt-4">
          <Link to="/guests/login" className="f-med">
            <i className="fa-solid fa-chevron-left me-2"></i>
            Back to Login
          </Link>
        </p>
      </Form>
    </div>
  );
};

export default ForgotPassword;
